.modal {
  z-index: 10001 !important;
  overflow: scroll !important;
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba($color: #000000, $alpha: 0.3);
  padding: 50px 20px;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .container { 
   
    // margin-top: 200px;
    
    z-index: 10001 !important;
    max-width: 1000px;
    width: 100%;
    // min-width: 800px;
    background-color: white;
    

    box-shadow: 0 0 15px 5px rgba($color: #000000, $alpha: 0.1);

    .body{
      padding: 50px 60px;
      font-size: 16px;
      margin-bottom: 30px;

      p{
        h3{
          font-weight:400;
          font-size: 22px;
          line-height: 30px;
          text-transform: uppercase;
        }
  
      }
   
    };

    .head {
      text-align: right;
      padding: 20px;

      button {
        outline: 0;
        border: 0;
        background-color: transparent;

        display: grid;
        justify-content: center;
        align-items: center;

        width: 30px;
        height: 30px;
        margin-left: auto;

        font-weight: 500;
      }
    }
  }
}

