#candidates-bg {
  background-image: url('../images/bg-image-9.jpg');
}

#jobListing-bg {
  background-image: url('../images/bg-image-6.jpg');
}

#postbg {
  background-image: url('../images/bg-image-8.jpg');
}

#bg-jobdetails {
  background-image: url('../images/bg-image-8.jpg');
}

#resumebg {
  background-image: url('../images/bg-image-14.jpg');
  margin-top: 6rem;
}

#contactbg {
  background-image: url('../images/bg-image-4.jpg');

}

#aboutbg {
  background-image: url('../images/bg-image-9.jpg');
}

#bg-numbers {
  background-image: url('../images/parallax-1-1920x780.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.margint {
  margin-top: 6rem;
}

#for-spacing {
  margin-bottom: 60px !important;
}

.contact-icon-size {
  fill: #5bbe71 !important;
  height: 50px;
  width: 50px;
}

.about-icon {
  height: 60px;
  width: 60px;
  transition: 0.2s;
}

.box-line:hover .about-icon {
  fill: #5bbe71 !important;
}

.socialIcon {
  height: 15px;
}

#bg-slider-img {
  background-image: url('../images/home-1.png');
  background-position: center !important;
  background-color: #4d71a8 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 550px;
}

#bg-slider-img2 {
  background-image: url('../images/home-2.png');
  background-position: center !important;
  background-color: #4d71a8 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 550px;
}

#bg-slider-img3 {
  background-image: url('../images/home-3.png');
  background-position: center !important;
  background-color: #4d71a8 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 550px;
}

.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}

.main-div {
  color: #ffffff !important;
  margin-top: 70px;
  margin-left: 40px;
  font-size: clamp(1rem, 5vw, 2.5rem);
  ;
}

/* .slider-mian{
	position: relative;
} */
.searchdiv {
  position: absolute !important;
  top: 60%;
  left: 30px;
}

@media only screen and (max-width: 991px) {
  .searchdiv {
    position: absolute !important;
    top: 80%;
    left: 0px !important;
  }

  .main-div {
    color: #ffffff !important;
    margin-top: 70px;
    margin-left: 50px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 425px) {
  .searchdiv {
    position: absolute !important;
    top: 60%;
    left: 0px !important;
  }

  .main-div {
    color: #ffffff !important;
    margin-top: 25px;
    margin-left: 0px;
    text-align: center;
    font-size: 24px;
    padding: 0 20px;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none !important;
}

.carousel-control-prev {
  left: -120px !important;
}

.carousel-control-next {
  right: -90px !important;
}

.banner-img {
  max-height: 500px !important;
}

.bg-img {
  margin-left: 1rem;
}

.navbar-toggler {
  margin-right: 1rem;
  margin-left: 1rem;
}

.navbar-nav .nav-link {
  padding-left: 1rem !important;
}

.navbar-text {
  padding-left: 1rem;
}

.navbar-styling {
  min-height: 120px;
  background-color: #4d71a8;
  color: #fff;
}

.navbar-light .navbar-text {
  color: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
  font: 500 13px/20px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  letter-spacing: 0.1em !important;
  text-transform: uppercase !important;
  transition: 0.25s !important;
  padding-left: 1.4rem !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #52be71 !important;
}

.navbar-light .navbar-nav .nav-link:active {
  color: #52be71 !important;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #52be71 !important;
}

.navbar-expand-xl .navbar-collapse {
  justify-content: space-evenly !important;
}

.navfixed {
  background-color: #2d394b !important;
  z-index: 1000;
  min-height: 100px;
  border-bottom: none !important;
}

.navbar {
  /* background-color: red !important; */
  position: fixed !important;
  width: 100%;
  top: 0;
  z-index: 999;
}

.iconimgs {
  max-width: 17px;
  padding-right: 5px;
  fill: #5bbe71;
}

.iconimgs3 {
  fill: #5bbe71;
  max-width: 30px;
}

.iconimgs4 {
  fill: #5bbe71;
  max-width: 14px;
  height: 14px;
}

.iconimgs2 {
  max-width: 17px;
  padding-right: 5px;
  fill: #5bbe71;
}

.button-primary:hover .iconimgs2 {
  fill: #ffffff;
}

.button-default-outline:hover .iconimgs {
  fill: #ffffff;
}

.newcataicon {
  height: 40px;
  width: 40px;
  fill: #5bbe71;
}

@media only screen and (max-width: 1200px) {
  .navbrand {
    display: block !important;
  }

  .navbrand2 {
    display: none;
  }
}

/* @media only screen and (max-width: 1023px) {
} */

.navbrand {
  display: none;
}

.newspaper {
  column-count: 2;
  margin: 10px;
  margin-top: 20px;
}

.atagsunderline {
  text-decoration: none !important;
}

.catasilder {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.textcata {
  margin-top: 40px;
  margin-bottom: 50px;
}

.InfiniteCarouselDots {
  bottom: -65px !important;
}

.wpicons {
  height: 40px;
  width: 50px;
  fill: #000000;
  transition: 0.22s;
}

.box-line-inner:hover .wpicons {
  fill: #5bbe71;
}

/* @charset "UTF-8"; */
/*
* Trunk version 2.0.1
*/
a:focus,
button:focus {
  outline: none !important;
}

button::-moz-focus-inner {
  border: 0;
}

*:focus {
  outline: none;
}

blockquote {
  padding: 0;
  margin: 0;
}

input,
button,
select,
textarea {
  outline: none;
}

label {
  margin-bottom: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none !important;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li,
ol li {
  display: block;
}

dl {
  margin: 0;
}

dt,
dd {
  line-height: inherit;
}

dt {
  font-weight: inherit;
}

dd {
  margin-bottom: 0;
}

cite {
  font-style: normal;
}

form {
  margin-bottom: 0;
}

blockquote {
  padding-left: 0;
  border-left: 0;
}

address {
  margin-top: 0;
  margin-bottom: 0;
}

figure {
  margin-bottom: 0;
}

html p a:hover {
  text-decoration: none;
}

/*
*
* Typography
*/
body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  font-weight: 400;
  color: #9a9a9a;
  background-color: #ffffff;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
[class*='heading-'] {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-weight: 400;
  color: #151515;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
[class*='heading-'] a {
  color: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
[class*='heading-'] a:hover {
  color: #52be71;
}

h1 [style*='max-width'],
h2 [style*='max-width'],
h3 [style*='max-width'],
h4 [style*='max-width'],
h5 [style*='max-width'],
h6 [style*='max-width'],
[class*='heading-'] [style*='max-width'] {
  display: inline-block;
}

h1,
.heading-1 {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 300;
}

@media (min-width: 768px) {

  h1,
  .heading-1 {
    font-size: 38px;
  }
}

@media (min-width: 1200px) {

  h1,
  .heading-1 {
    font-size: 70px;
    line-height: 1.08571;
  }
}

h2,
.heading-2 {
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: 0.025em;
}

@media (min-width: 768px) {

  h2,
  .heading-2 {
    font-size: 35px;
  }
}

@media (min-width: 1200px) {

  h2,
  .heading-2 {
    font-size: 48px;
    line-height: 1.20833;
  }

  h2.h2-smaller,
  .heading-2.h2-smaller {
    font-size: 40px;
  }
}

h3,
.heading-3 {
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 0.025em;
}

@media (min-width: 1200px) {

  h3,
  .heading-3 {
    font-size: 33px;
    line-height: 1.45455;
  }
}

h4,
.heading-4 {
  font-size: 18px;
  line-height: 1.45;
}

@media (min-width: 768px) {

  h4,
  .heading-4 {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {

  h4,
  .heading-4 {
    font-size: 24px;
    line-height: 1.41667;
  }
}

h5,
.heading-5 {
  font-size: 16px;
  line-height: 1.5;
}

@media (min-width: 768px) {

  h5,
  .heading-5 {
    font-size: 18px;
    line-height: 1.38889;
  }
}

h6,
.heading-6 {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.025em;
}

@media (min-width: 768px) {

  h6,
  .heading-6 {
    font-size: 16px;
    line-height: 1.5;
  }
}

.heading-7 {
  line-height: 1.5;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.heading-8 {
  font: 500 14px/24px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.heading-9 {
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.1em;
  font-weight: 600;
  text-transform: uppercase;
}

.heading-decorated_1::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  width: 24px;
  height: 3px;
  background: #52be71;
}

.text-style-1 {
  letter-spacing: 0.025em;
  color: #151515;
}

small,
.small {
  display: block;
  font-size: 12px;
  line-height: 1.5;
}

mark,
.mark {
  padding: 3px 5px;
  color: #ffffff;
  background: #52be71;
}

strong {
  color: #151515;
}

.context-dark strong,
.bg-gray-700 strong,
.bg-blue-13 strong,
.bg-blue-15 strong,
.bg-primary strong {
  color: #ffffff;
}

.big {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 16px;
  line-height: 1.625;
  letter-spacing: 0.025em;
  color: #ffffff;
}

.big a {
  color: #52be71;
}

.big a:hover {
  color: #52be71;
}

.big a[href*='callto'] {
  white-space: nowrap;
}

.lead {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
}

code {
  padding: 3px 10px;
  border-radius: 0.2rem;
  font-size: 90%;
  color: #151515;
  background: #f3f4f9;
}

p [data-toggle='tooltip'] {
  padding-left: 0.25em;
  padding-right: 0.25em;
  color: #52be71;
}

p [style*='max-width'] {
  display: inline-block;
}

/*
*
* Brand
*/
.brand {
  display: inline-block;
}

.brand .brand-logo-light {
  display: none;
}

.brand .brand-logo-dark {
  display: block;
}

/*
*
* Links
*/
a {
  color: inherit;
  letter-spacing: 0.025em;
  transition: all 0.3s ease-in-out;
}

a,
a:focus,
a:active,
a:hover {
  text-decoration: none;
}

a[href*='tel'],
a[href*='mailto'] {
  white-space: nowrap;
}

/* .dropdown-menu a{
  
  color:black;
} */

.dropdown-item a:active {
  color: white !important;
}

.dropdown-item a {
  color: black !important;
}

.dropdown-item:active,
.dropdown-item.active {
  background-color: #4d71a8 !important
}

.dropdown-item button:active {
  background-color: #4d71a8;
}

a:hover {
  color: #52be71;
}

p a {
  color: #52be71;
}

p a:active,
p a:focus {
  color: #358e4f;
}

p a:hover {
  color: #358e4f;
}

p .link-hover {
  color: #358e4f;
}

p .link-press {
  color: #358e4f;
}

.privacy-link {
  display: inline-block;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 14px;
  line-height: 1.7;
}

*+.privacy-link {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .privacy-link {
    font-size: 16px;
    line-height: 1.5;
  }

  *+.privacy-link {
    margin-top: 45px;
  }
}

.link-corporate {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 27px;
  border: 1px solid #d6d6d6;
  transition: 0.2s ease-in-out;
}

.link-corporate img {
  max-width: 90%;
}

.link-corporate::before,
.link-corporate::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #52be71;
  transition: 0.25s ease-out;
  contain: paint;
}

.link-corporate::before {
  transform: scale3d(0, 1, 1);
  border-width: 1px 0 1px 0;
}

.link-corporate::after {
  transform: scale3d(1, 0, 1);
  border-width: 0 1px 0 1px;
}

.link-corporate:hover {
  border-color: rgba(214, 214, 214, 0);
}

.link-corporate:hover::before,
.link-corporate:hover::after {
  transform: scale3d(1, 1, 1);
}

.context-dark .link-corporate,
.bg-gray-700 .link-corporate,
.bg-blue-13 .link-corporate,
.bg-blue-15 .link-corporate,
.bg-primary .link-corporate {
  border-color: rgba(255, 255, 255, 0.4);
}

.context-dark .link-corporate:hover,
.bg-gray-700 .link-corporate:hover,
.bg-blue-13 .link-corporate:hover,
.bg-blue-15 .link-corporate:hover,
.bg-primary .link-corporate:hover {
  border-color: transparent;
}

.context-dark .link-corporate:hover img,
.bg-gray-700 .link-corporate:hover img,
.bg-blue-13 .link-corporate:hover img,
.bg-blue-15 .link-corporate:hover img,
.bg-primary .link-corporate:hover img {
  filter: saturate(0.45378) hue-rotate(137.22222deg);
}

@media (min-width: 576px) {
  .owl-item .link-corporate {
    margin-left: 2px;
    margin-right: 2px;
  }
}

@media (min-width: 1200px) {
  .link-corporate {
    min-height: 145px;
  }
}

/*
*
* Blocks
*/
.block-1 {
  max-width: 512px;
  margin-left: auto;
  margin-right: auto;
}

.block-2 {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .block-3 {
    max-width: 400px;
  }
}

.block-4 {
  display: inline-block;
  max-width: 400px;
}

@media (min-width: 992px) {
  .block-4 {
    max-width: 800px;
  }
}

@media (min-width: 992px) {
  .block-5 {
    max-width: 540px;
  }
}

/*
*
* Boxes
*/
.box-minimal {
  display: flex;
  align-items: center;
  color: #9a9a9a;
}

.box-minimal>*+* {
  margin-left: 15px;
}

.box-minimal .box-minimal-icon {
  font-size: 40px;
}

.box-minimal:hover .box-minimal-title {
  color: #52be71;
}

.box-minimal-icon {
  flex-shrink: 0;
  line-height: 1;
  min-width: 40px;
  color: #52be71;
}

.box-minimal-main {
  flex-grow: 1;
}

.box-minimal-title {
  font-size: 16px;
  line-height: 1.4;
  transition: 0.22s;
  color: #151515;
}

.box-minimal-title+p {
  margin-top: 0;
}

.box-line {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  cursor: default;
}

.box-line:hover .box-line-title,
.box-line:hover .box-line-icon {
  color: #52be71;
}

.box-line:hover .box-line-divider {
  width: 75%;
  border-bottom-color: #52be71;
}

@media (max-width: 575px) {
  .owl-carousel-stretch .owl-item>.box-line {
    flex-shrink: 0;
    max-width: 400px;
  }
}

.box-line_corporate {
  max-width: 100%;
  padding: 30px 20px 40px;
  border-radius: 3px;
  background: #3f4d56;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

.box-line_corporate .box-line-inner {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.box-line_corporate .box-line-title,
.box-line_corporate .icon.box-line-icon {
  color: #ffffff;
}

.box-line_corporate .box-line-divider {
  border-bottom-color: rgba(255, 255, 255, 0.22);
}

.box-line_corporate .box-line-title {
  padding-left: 20px;
  padding-right: 20px;
}

.box-line_corporate:hover .box-line-icon {
  color: #52be71;
}

@media (min-width: 768px) {
  .box-line_corporate {
    padding: 65px 30px 80px;
  }
}

.box-line_creative {
  max-width: 100%;
  padding: 25px 17px 35px;
  background: #f3f4f9;
}

.box-line_creative .box-line-inner {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.box-line_creative .box-line-divider {
  border-bottom-color: rgba(21, 21, 21, 0.22);
}

.box-line_creative .box-line-title {
  padding-left: 20px;
  padding-right: 20px;
}

.box-line_creative:hover .box-line-icon {
  color: #52be71;
}

@media (min-width: 768px) and (max-width: 991px) {
  .box-line_creative .box-line-title {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  .box-line_creative {
    padding: 40px 30px 45px;
  }
}

.icon.box-line-icon {
  font-size: 48px;
  line-height: 1;
  color: #151515;
  transition: 0.22s;
}

.box-line-title {
  color: #151515;
  transition: 0.22s;
}

.box-line-divider {
  position: relative;
  transition: 0.22s;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #d6d6d6;
}

*+.box-line-divider {
  margin-top: 20px;
}

*+.box-line-title {
  margin-top: 10px;
}

.box-line-icon+.box-line-title {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .icon.box-line-icon {
    font-size: 74px;
  }

  .box-line_sm .icon.box-line-icon {
    font-size: 70px;
  }

  *+.box-line-title {
    margin-top: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .box-line_sm .box-line-title {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .icon.box-line-icon {
    font-size: 88px;
  }
}

.box-info {
  position: relative;
  padding: 20px;
  border: 1px solid #e0e0e0;
  color: #151515;
  letter-spacing: 0.025em;
}

@media (max-width: 991px) {
  .box-info {
    border-top: 0;
  }

  .box-info::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -1px;
    height: 20px;
    border-left: 1px solid #e0e0e0;
  }

  .box-info::after {
    content: '';
    position: absolute;
    right: 27px;
    left: -1px;
    top: -20px;
    height: 20px;
    border: 1px solid #e0e0e0;
    border-right-width: 0;
    border-top-width: 0;
    transform: skewX(55deg);
    transform-origin: 0 0;
  }
}

@media (min-width: 992px) {
  .box-info {
    margin-left: 25px;
    border-left-width: 0;
  }

  .box-info::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -25px;
    width: 25px;
    border-bottom: 1px solid #e0e0e0;
  }

  .box-info::after {
    content: '';
    position: absolute;
    bottom: 7px;
    left: -25px;
    top: 25px;
    width: 25px;
    border: 1px solid #e0e0e0;
    border-left-width: 0;
    border-bottom-width: 0;
    border-top-width: 2px;
    transform: skewY(55deg);
    transform-origin: 75% 0;
  }
}

@media (min-width: 1200px) {
  .box-info {
    padding: 35px 50px 50px 40px;
  }
}

.box-info-2 {
  position: relative;
  padding: 20px 20px 40px;
  color: #151515;
  background: #ffffff;
  letter-spacing: 0.025em;
}

@media (min-width: 768px) {
  .box-info-2 {
    padding: 35px 30px 40px;
  }
}

@media (min-width: 992px) {
  .box-info-2 {
    margin-left: 24px;
    border-left-width: 0;
  }

  .box-info-2::before {
    content: '';
    position: absolute;
    top: 0;
    left: -23px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 24px 40px 0;
    border-color: transparent #ffffff transparent transparent;
  }
}

@media (min-width: 1200px) {
  .box-info-2 {
    padding: 35px 50px 50px 40px;
  }
}

.box-info-3 {
  width: 100%;
  position: relative;
  padding: 20px 30px 25px 34px;
  background: #ffffff;
  letter-spacing: 0.025em;
  text-align: center;
}

.box-info-3-title-divider {
  display: block;
  font-size: 0;
  line-height: 0;
}

.box-info-3-title-divider::before {
  content: '';
  display: inline-block;
  width: 43px;
  height: 2px;
  background: #52be71;
}

*+.box-info-3-title-divider {
  margin-top: 8px;
}

@media (max-width: 767px) {
  .box-info-3 {
    max-width: 320px;
  }

  .height-fill .box-info-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 576px) {
  .box-info-3 {
    text-align: left;
  }

  .box-info-3-title {
    position: relative;
    text-indent: 55px;
  }

  .box-info-3-title-divider {
    position: absolute;
    top: -0.22em;
    left: 0;
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    font-size: inherit;
    line-height: inherit;
    text-indent: 0;
  }
}

@media (max-width: 1199px) {
  .box-info-3 {
    margin-bottom: 16px;
  }

  .box-info-3::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -9px;
    bottom: -18px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 18px 0 18px;
    border-color: #ffffff transparent transparent transparent;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .box-info-3 {
    margin-bottom: 0;
  }

  .box-info-3::before {
    display: none;
  }
}

@media (min-width: 768px) {
  .box-info-3::before {
    left: 10%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .box-info-3 {
    margin-right: 17px;
    margin-bottom: 0;
  }

  .box-info-3::before {
    content: '';
    position: absolute;
    top: 0;
    left: auto;
    right: -17px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 26px 17px;
    border-color: transparent transparent transparent #ffffff;
  }
}

.box-creative {
  position: relative;
  display: flex;
  padding: 25px 15px;
  text-align: center;
  border: 1px solid #d6d6d6;
  transition: 0.2s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.box-creative,
.box-creative:active,
.box-creative:focus,
.box-creative:hover {
  color: #9a9a9a;
}

.box-creative p+p {
  margin-top: 3px;
}

.box-creative::before,
.box-creative::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #52be71;
  transition: 0.25s ease-out;
  will-change: transform;
  contain: paint;
}

.box-creative::before {
  transform: scale3d(0, 1, 1);
  border-width: 1px 0 1px 0;
}

.box-creative::after {
  transform: scale3d(1, 0, 1);
  border-width: 0 1px 0 1px;
}

.box-creative:hover {
  border-color: rgba(214, 214, 214, 0);
  background: rgba(0, 0, 0, 0.01);
}

.box-creative:hover::before,
.box-creative:hover::after {
  transform: scale3d(0.9999, 0.9999, 0.9999);
}

.box-creative-inner,
.box-creative-dummy {
  width: 100%;
  flex-shrink: 0;
}

.box-creative-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-creative-dummy {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.box-creative-dummy::before {
  content: '';
  display: block;
  padding-top: 89.65517%;
}

.box-creative-icon {
  font-size: 59px;
  line-height: 1;
  color: #52be71;
}

.box-creative-title {
  font-size: 14px;
  line-height: 1.4;
  color: #151515;
}

*+.box-creative-title {
  margin-top: 6px;
}

@media (max-width: 575px) {
  .box-creative {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .owl-item .box-creative {
    margin-left: 2px;
    margin-right: 2px;
  }
}

@media (min-width: 992px) {
  .box-creative-title {
    font-size: 16px;
  }
}

/*
* Element groups
*/
html .group {
  margin-bottom: -20px;
  margin-left: -15px;
}

html .group:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html .group>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 15px;
}

html .group-sm {
  margin-bottom: -15px;
  margin-left: -20px;
}

html .group-sm:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html .group-sm>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 20px;
}

html .group-xl {
  margin-bottom: -20px;
  margin-left: -30px;
}

html .group-xl:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html .group-xl>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 30px;
}

@media (min-width: 992px) {
  html .group-xl {
    margin-bottom: -20px;
    margin-left: -45px;
  }

  html .group-xl>* {
    margin-bottom: 20px;
    margin-left: 45px;
  }
}

html .group-justify {
  margin-bottom: -15px;
  margin-left: -13px;
}

html .group-justify:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html .group-justify>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 13px;
}

html .group-justify .button {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  html .group-justify {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  html .group-justify>* {
    flex-grow: 1;
    flex-shrink: 0;
  }

  html .group-justify .button {
    min-width: 130px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

html .group-middle {
  display: inline-table;
  vertical-align: middle;
}

html .group-middle>* {
  vertical-align: middle;
}

*+.group-justify {
  margin-top: 30px;
}

*+.group-sm {
  margin-top: 30px;
}

*+.group-xl {
  margin-top: 20px;
}

@media (min-width: 992px) {
  *+.group-justify {
    margin-top: 40px;
  }
}

/*
*
* Responsive units
*/
.unit {
  display: flex;
  flex: 0 1 100%;
  margin-bottom: -30px;
  margin-left: -20px;
}

.unit>* {
  margin-bottom: 30px;
  margin-left: 20px;
}

.unit:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.unit-body {
  flex: 0 1 auto;
}

.unit-left,
.unit-right {
  flex: 0 0 auto;
  max-width: 100%;
}

.unit-spacing-xxs {
  margin-bottom: -10px;
  margin-left: -10px;
}

.unit-spacing-xxs>* {
  margin-bottom: 10px;
  margin-left: 10px;
}

.unit-spacing-xs {
  margin-bottom: -15px;
  margin-left: -7px;
}

.unit-spacing-xs>* {
  margin-bottom: 15px;
  margin-left: 7px;
}

.unit-spacing-sm {
  margin-bottom: -15px;
  margin-left: -15px;
}

.unit-spacing-sm>* {
  margin-bottom: 15px;
  margin-left: 15px;
}

/*
*
* Lists
*/
.list>li+li {
  margin-top: 10px;
}

.list-xs>li+li {
  margin-top: 5px;
}

.list-sm>li+li {
  margin-top: 7px;
}

.list-md>li+li {
  margin-top: 18px;
}

.list-lg>li+li {
  margin-top: 25px;
}

.list-xl>li+li {
  margin-top: 25px;
}

.list-1>li+li {
  margin-top: 14px;
}

@media (min-width: 768px) {
  .list-xl>li+li {
    margin-top: 60px;
  }
}

.list-inline>li {
  display: inline-block;
}

html .list-inline-xs {
  transform: translate3d(0, -5px, 0);
  margin-bottom: -5px;
  margin-left: -4px;
  margin-right: -4px;
}

html .list-inline-xs>* {
  margin-top: 5px;
  padding-left: 4px;
  padding-right: 4px;
}

html .list-inline-sm {
  transform: translate3d(0, -8px, 0);
  margin-bottom: -8px;
  margin-left: -8px;
  margin-right: -8px;
}

html .list-inline-sm>* {
  margin-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

html .list-inline-md {
  transform: translate3d(0, -8px, 0);
  margin-bottom: -8px;
  margin-left: -10px;
  margin-right: -10px;
}

html .list-inline-md>* {
  margin-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.list-terms dd {
  color: #686868;
}

.list-terms dt+dd {
  margin-top: 15px;
}

.list-terms dd+dt {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .list-terms dd+dt {
    margin-top: 35px;
  }
}

@media (min-width: 1200px) {
  .list-terms dd+dt {
    margin-top: 45px;
  }
}

*+.list-terms {
  margin-top: 25px;
}

.list-terms-inline dt,
.list-terms-inline dd {
  display: inline;
}

.list-terms-inline dt::after {
  content: ':';
  display: inline-block;
}

.list-terms-inline a {
  color: inherit;
}

.list-terms-inline a:hover {
  color: #52be71;
}

.index-list {
  counter-reset: li;
}

.index-list>li .list-index-counter:before {
  content: counter(li, decimal-leading-zero);
  counter-increment: li;
}

.list-marked {
  text-align: left;
  letter-spacing: 0.025em;
}

.list-marked>li {
  text-indent: -25px;
  padding-left: 40px;
}

.list-marked>li::before {
  position: relative;
  display: inline-block;
  left: 20px;
  top: -1px;
  min-width: 25px;
  content: '\e02d';
  font-family: 'fl-budicons-free';
  font-size: 11px;
  color: #52be71;
}

.list-marked>li+li {
  margin-top: 11px;
}

.context-dark .list-marked,
.bg-gray-700 .list-marked,
.bg-blue-13 .list-marked,
.bg-blue-15 .list-marked,
.bg-primary .list-marked {
  color: #ffffff;
}

*+.list-marked {
  margin-top: 20px;
}

.list-marked-1 {
  padding-left: 29px;
  text-align: left;
}

.list-marked-1>li {
  text-indent: -20px;
}

.list-marked-1>li::before {
  content: '';
  position: relative;
  top: -1px;
  display: inline-block;
  margin-right: 9px;
  vertical-align: middle;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-style: solid;
  border-width: 4px 0 4px 10px;
  border-color: transparent transparent transparent #52be71;
}

.list-marked-1 a {
  color: inherit;
}

.list-marked-1 a:hover {
  color: #52be71;
}

.list-marked-1>li+li {
  margin-top: 13px;
}

.list-marked-2 {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  color: #151515;
  text-align: left;
}

.list-marked-2>li {
  text-indent: -28px;
  padding-left: 40px;
}

.list-marked-2>li::before {
  position: relative;
  display: inline-block;
  left: 20px;
  top: -1px;
  min-width: 28px;
  content: '\e02d';
  font-family: 'fl-budicons-free';
  color: #52be71;
}

.list-marked-2>li+li {
  margin-top: 10px;
}

*+.list-marked-2 {
  margin-top: 20px;
}

.list-marked-2+p {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .list-marked-2 {
    font-size: 16px;
  }

  .list-marked-2>li+li {
    margin-top: 17px;
  }

  *+.list-marked-2 {
    margin-top: 38px;
  }

  .list-marked-2+p {
    margin-top: 38px;
  }
}

.list-marked-3 {
  color: #151515;
}

.list-marked-3>li {
  position: relative;
  padding-left: 30px;
  letter-spacing: 0.025em;
}

.list-marked-3>li::before {
  content: '';
  position: absolute;
  top: 0.65em;
  left: 0;
  display: inline-block;
  width: 18px;
  height: 3px;
  vertical-align: middle;
  background: #52be71;
}

.list-marked-3>li+li {
  margin-top: 10px;
}

*+.list-marked-3 {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .list-marked-3 {
    max-width: 340px;
  }

  .list-marked-3>li+li {
    margin-top: 18px;
  }
}

.list-ordered {
  counter-reset: li;
  padding-left: 25px;
  text-align: left;
  color: #151515;
}

.list-ordered>li {
  position: relative;
  padding-left: 25px;
}

.list-ordered>li:before {
  content: counter(li, decimal) '.';
  counter-increment: li;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 15px;
  color: #b7b7b7;
}

.list-ordered>li+li {
  margin-top: 10px;
}

*+.list-ordered {
  margin-top: 15px;
}

.list-steps {
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}

.list-steps p {
  letter-spacing: 0.05em;
}

.list-steps-item {
  display: flex;
  align-items: center;
  padding: 24px 0;
  /* background: url(../images/divider-line.png) no-repeat; */
  background-position: 100% 50%;
}

.list-steps-item+.list-steps-item {
  border-top: 1px solid #d6d6d6;
}

.list-steps-item-icon {
  position: relative;
  padding: 10px;
  min-width: 55px;
  flex-shrink: 0;
  font-size: 30px;
  text-align: left;
  line-height: 1;
  color: #52be71;
}

.list-steps-item-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(0, -50%, 0);
  height: 80%;
  border-right: 1px solid #d6d6d6;
}

.list-steps-item-icon-sm {
  font-size: 26px;
}

.list-steps-item-main {
  flex-grow: 1;
  padding-left: 15px;
}

.list-steps-item-main *+p {
  margin-top: 5px;
}

@media (min-width: 768px) {
  .list-steps-item-icon {
    min-width: 50px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .list-steps-item-main {
    padding-left: 33px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .list-steps-item {
    background: transparent;
  }
}

@media (min-width: 992px) {
  .list-steps-item-icon {
    min-width: 70px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 54px;
  }

  .list-steps-item-icon-sm {
    font-size: 48px;
  }
}

.list-progress {
  counter-reset: list-progress-counter;
}

.lp-item {
  padding: 20px 22px 25px 34px;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.19);
  transition: 0.22s;
}

.lp-item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.22);
}

.lp-item p {
  letter-spacing: 0.05em;
}

.icon.lp-item-icon {
  font-size: 40px;
  line-height: 40px;
  color: #52be71;
}

.lp-item-icon-sm {
  font-size: 32px;
}

.lp-item-header {
  display: flex;
  justify-content: space-between;
}

.lp-item-counter {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 33px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.025em;
  color: #e7e9f0;
}

.lp-item-counter::before {
  content: counter(list-progress-counter, decimal-leading-zero);
  counter-increment: list-progress-counter;
}

.lp-item-title {
  font-weight: 500;
}

.bg-gray-100 .lp-item {
  box-shadow: none;
}

*+.lp-item-title {
  margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .lp-item {
    padding: 20px 22px 25px 25px;
  }
}

@media (min-width: 768px) {
  .icon.lp-item-icon {
    font-size: 60px;
    line-height: 60px;
  }

  .icon.lp-item-icon-sm {
    font-size: 48px;
  }

  *+.lp-item-title {
    margin-top: 5px;
  }
}

.list-categories {
  padding: 25px 30px;
  border: 1px solid #e0e0e0;
}

.list-categories>li>a {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.list-categories>li.active {
  color: #52be71;
}

.list-categories>li+li {
  margin-top: 11px;
}

.timeline-classic p {
  letter-spacing: 0.025em;
}

.timeline-classic-period {
  padding-right: 20px;
  white-space: nowrap;
  letter-spacing: 0.025em;
}

.timeline-classic-title {
  position: relative;
  padding-left: 35px;
}

.timeline-classic-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.65em;
  display: inline-block;
  width: 25px;
  height: 2px;
  vertical-align: middle;
  background: #52be71;
}

.timeline-classic-main {
  position: relative;
  padding: 10px 0 30px 20px;
}

.timeline-classic-main::before,
.timeline-classic-main::after {
  content: '';
  position: absolute;
  pointer-events: none;
}

.timeline-classic-main::before {
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e0e0e0;
}

.timeline-classic-main *+p {
  margin-top: 10px;
}

.timeline-classic-item:last-child .timeline-classic-main {
  padding-bottom: 0;
}

.timeline-classic-item:last-child .timeline-classic-main::before {
  bottom: 20px;
}

*+.timeline-classic {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .timeline-classic-period {
    margin-left: -2px;
  }
}

@media (min-width: 768px) {
  .timeline-classic {
    display: table;
  }

  .timeline-classic-item {
    display: table-row;
  }

  .timeline-classic-item>* {
    display: table-cell;
  }

  .timeline-classic-main {
    padding-bottom: 20px;
  }

  .timeline-classic-main::after {
    top: 18px;
    left: -4px;
    width: 9px;
    height: 9px;
    background: #e0e0e0;
    border-radius: 50%;
  }
}

.list-block {
  position: relative;
  overflow: hidden;
  background: #f3f4f9;
  color: #151515;
  padding: 0 15px;
}

.list-block>li {
  position: relative;
}

.list-block>li::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.list-block>li>a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  background: #f3f4f9;
  margin-bottom: -3px;
  margin-left: -10px;
}

.list-block>li>a:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.list-block>li>a>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 3px;
  margin-left: 10px;
}

.list-block>li>a:hover {
  color: #52be71;
}

.list-block>li+li::before {
  content: '';
  border-top: 1px solid #d6d6d6;
}

.list-block-meta {
  letter-spacing: 0.025em;
  color: #52be71;
}

@media (min-width: 768px) {
  .list-block {
    padding: 0 12px;
  }

  .list-block>li>a {
    padding: 18px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .list-block {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .list-block {
    padding: 0 30px;
  }
}

@media (min-width: 1200px) {
  .list-block>li>a {
    padding: 30px 0;
  }
}

@media (min-width: 992px) {
  html:not(.tablet):not(.mobile) .list-block-title {
    position: relative;
    display: block;
    padding-right: 20px;
    transition: padding 0.15s ease-in-out;
  }

  html:not(.tablet):not(.mobile) .list-block-title::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-style: solid;
    border-width: 4px 0 4px 10px;
    border-color: transparent transparent transparent #52be71;
    opacity: 0;
    transition: 0.15s ease-in-out;
  }

  html:not(.tablet):not(.mobile) .list-block>li>a:hover .list-block-title {
    padding-left: 20px;
    padding-right: 0;
  }

  html:not(.tablet):not(.mobile) .list-block>li>a:hover .list-block-title::before {
    opacity: 1;
  }
}

.list-linked {
  display: flex;
  flex-wrap: wrap;
  counter-reset: list-linked-counter;
  text-align: center;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px;
}

.list-linked>* {
  margin-bottom: 30px;
}

.ll-item {
  padding: 0 15px;
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
}

.ll-item *+p {
  margin-top: 5px;
}

.ll-item:hover .ll-item-icon-inner {
  stroke-dashoffset: 0;
  opacity: 1;
}

.ll-item-icon {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  font-size: 40px;
  line-height: 1;
  background: #ffffff;
  color: #52be71;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.06);
}

.ll-item-icon::after {
  content: '';
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  border-radius: inherit;
  border: 2px solid #5bbe71;
}

.ll-item-icon.icon {
  display: flex;
}

.ll-item-icon-inner {
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  pointer-events: none;
  stroke: #69e08c;
  stroke-width: 2px;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  opacity: 0;
  transition: opacity 0.22s, stroke-dashoffset 0.8s ease-in-out;
}

.ie-10 .ll-item-icon-inner,
.ie-11 .ll-item-icon-inner {
  display: none;
}

.ll-item-icon-sm {
  font-size: 26.66667;
}

.ll-item-title::before {
  display: inline-block;
  margin-right: 10px;
  content: counter(list-linked-counter) '.';
  counter-increment: list-linked-counter;
}

*+.list-linked {
  margin-top: 35px;
}

*+.ll-item-main {
  margin-top: 20px;
}

@media (min-width: 400px) {
  .ll-item {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .ll-item {
    position: relative;
    width: 25%;
    max-width: 100%;
  }

  .ll-item::after {
    content: '';
    position: absolute;
    top: 50px;
    left: 50%;
    width: calc(100% - 100px);
    margin-top: -15px;
    margin-left: 50px;
    height: 30px;
    z-index: 3;
    background: url(../images/divider-line.png) no-repeat;
    background-position: center center;
    pointer-events: none;
  }

  .ll-item:nth-child(4n)::after {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ll-item::after {
    top: 80px;
    margin-left: 80px;
    width: calc(100% - 160px);
  }

  .ll-item-icon {
    width: 160px;
    height: 160px;
    font-size: 66px;
  }

  .ll-item-icon::after {
    position: absolute;
    top: 13px;
    right: 13px;
    bottom: 13px;
    left: 13px;
  }

  .ll-item-icon-sm {
    font-size: 55px;
  }

  .ll-item-icon-inner {
    top: 13px;
    right: 13px;
    bottom: 13px;
    left: 13px;
    width: calc(100% - 26px);
    height: calc(100% - 26px);
  }
}

.list-icons>li,
.list-icons>li>a {
  white-space: nowrap;
}

.list-icons>li *,
.list-icons>li>a * {
  white-space: normal;
}

.list-icons>li>*,
.list-icons>li>a>* {
  display: inline-block;
  max-width: 92%;
  vertical-align: top;
}

.list-icons>li>*+*,
.list-icons>li>a>*+* {
  margin-left: 5px;
}

/*
*
* Images
*/
img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-responsive {
  width: 100%;
}

/*
*
* Icons
*/
.icon {
  display: inline-block;
  line-height: 1.1;
}

.icon::before {
  position: relative;
  display: inline-block;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-transform: none;
}

.icon-style-brand[class*='facebook']:hover {
  color: #4d70a8;
}

.icon-style-brand[class*='twitter']:hover {
  color: #02bcf3;
}

.icon-style-brand[class*='google']:hover {
  color: #e2411e;
}

.icon-style-brand[class*='pinterest']:hover {
  color: #c96867;
}

.icon-filled {
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;
  text-align: center;
}

.icon-filled::before {
  top: 1px;
  left: 1px;
}

.icon-filled-brand {
  position: relative;
}

.icon-filled-brand,
.icon-filled-brand:active,
.icon-filled-brand:focus,
.icon-filled-brand:hover {
  color: #ffffff;
}

.icon-filled-brand[class*='facebook'] {
  background: #5776b7;
}

.icon-filled-brand[class*='facebook']:hover {
  background: #6585b9;
}

.icon-filled-brand[class*='twitter'] {
  background: #88bdee;
}

.icon-filled-brand[class*='twitter']:hover {
  background: #21cbfd;
}

.icon-filled-brand[class*='google'] {
  background: #d46975;
}

.icon-filled-brand[class*='google']:hover {
  background: #e76042;
}

.icon-filled-brand[class*='pinterest'] {
  background: #c96867;
}

.icon-filled-brand[class*='pinterest']:hover {
  background: #d48685;
}

html a.icon-filled-brand:active,
html a.icon-filled-brand:focus,
html a.icon-filled-brand:hover {
  color: #ffffff;
}

.icon-circle {
  border-radius: 50%;
  padding: 5px;
}

.icon-xxs {
  font-size: 13px;
  fill: #fff !important;
}

.icon-xs {
  font-size: 15px;
}

.icon-sm {
  font-size: 19px;
}

.icon-lg {
  font-size: 30px;
}

.icon-xlg {
  font-size: 30px;
  line-height: 42px;
}

.icon-xl {
  font-size: 42px;
}

.icon-xxl {
  font-size: 60px;
}

@media (min-width: 768px) {
  .icon-xxl {
    font-size: 78px;
  }
}

.icon-default {
  font-size: 14px;
}

.icon-1 {
  font-size: 16px;
}

.icon-2 {
  font-size: 22px;
}

.icon-3 {
  font-size: 26px;
}

.icon-4 {
  font-size: 33px;
}

/*
*
* Tables custom
*/
.table {
  text-align: left;
  background: #ffffff;
  border-collapse: collapse;
}

.table tr {
  transition: 0.5s;
}

.table th,
.table td {
  color: #2c343b;
  background: #ffffff;
}

.table th {
  padding: 17px 24px;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: #f5f5f5;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .table th {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.table td {
  padding: 17px 24px;
  background-color: transparent;
}

.table tbody tr:first-child td {
  border-top: 0;
}

.table tr td {
  border-bottom: 1px solid #f5f5f5;
}

.table tr td:first-child {
  border-left: 1px solid #f5f5f5;
}

.table tr td:last-child {
  border-right: 1px solid #f5f5f5;
}

.table tfoot td {
  color: #34495e;
}

*+[class*='table-responsive'] {
  margin-top: 30px;
}

@media (min-width: 768px) {
  *+[class*='table-responsive'] {
    margin-top: 40px;
  }
}

.table.table-primary thead th {
  color: #ffffff;
  background: #52be71;
  border: 0;
}

.table.table-primary tbody tr:hover {
  background: #f5f5f5;
}

.table.table-gray-700 thead th {
  color: #ffffff;
  background: #2c343b;
  border: 0;
}

.table.table-gray-700 tbody tr:hover {
  background: #f5f5f5;
}

.table.table-bordered tr td:first-child {
  border-left: 0;
}

.table.table-bordered tr td:last-child {
  border-right: 0;
}

.table.table-bordered td {
  border: 1px solid #f5f5f5;
}

.table.table-bordered tbody>tr:first-of-type>td {
  border-top: 0;
}

.table.table-striped {
  border-bottom: 1px solid #f5f5f5;
}

.table.table-striped tbody tr:nth-of-type(odd) {
  background: transparent;
}

.table.table-striped tbody tr:nth-of-type(even) {
  background: #f5f5f5;
}

.table.table-striped tbody td {
  border: 0;
}

.table.table-striped tfoot td:not(:first-child) {
  border-left: 0;
}

.table-job-listing {
  width: 100%;
}

.table-job-listing tbody {
  width: 100%;
}

.table-job-listing tr {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  transition: 0.22s;
}

.table-job-listing tr:hover {
  background: #f3f4f9;
}

.table-job-listing td {
  padding: 16px 20px;
}

.bg-gray-100 tr:hover {
  background: #ebedf3;
}

.table-job-listing_lg td {
  padding: 16px 30px;
}

.table-job-listing-main {
  min-width: 360px;
  width: 71.5%;
}

.table-job-listing-date {
  white-space: nowrap;
}

.table-job-listing-badge {
  text-align: right;
  width: 10%;
}

.heading-decorated_1+.table-job-listing {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .table-job-listing tr {
    height: 110px;
  }
}

.table-job-offers {
  letter-spacing: 0.025em;
  color: #151515;
  position: relative;
  z-index: 0;
}

.table-job-offers tbody {
  width: 100%;
}

.table-job-offers tr {
  border-top: 1px solid #d6d6d6;
}

.table-job-offers tr:last-child {
  border-bottom: 1px solid #d6d6d6;
}

.table-job-offers td {
  padding: 14px 20px;
}

.table-job-offers.table-responsive {
  overflow-y: hidden;
}

.table-job-offers-main {
  min-width: 360px;
  width: 71.5%;
}

.table-job-offers-date:first-child {
  width: 16%;
  padding: 0;
}

.table-job-offers-date:first-child>* {
  display: block;
  padding: 16px 18px 16px 30px;
  border-right: 1px solid #d6d6d6;
}

.table-job-offers-date,
.table-job-offers-meta .object-inline>* {
  white-space: nowrap;
}

.table-job-offers-badge {
  text-align: right;
  width: 10%;
}

*+.table-job-offers-outer,
*+.table-job-offers {
  margin-top: 30px;
}

.table-job-offers-outer+.pagination-outer,
.table-job-offers+.pagination-outer {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .table-job-offers tr {
    height: 102px;
  }
}

@media (min-width: 992px) {
  .table-job-offers-outer {
    position: relative;
    overflow: hidden;
    padding: 6px;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -6px;
  }

  .table-job-offers {
    padding: 6px;
    width: calc(100% + 6px);
  }

  .table-job-offers tr {
    transition: 0.35s ease-in-out;
  }

  .table-job-offers tr:hover {
    background: #f3f4f9;
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.15);
    border-color: #f3f4f9;
  }

  .table-job-offers tr:hover+tr {
    border-color: #f3f4f9;
  }

  .table-job-offers tr:last-child:hover {
    border-color: #f3f4f9;
  }

  .ie-10 .table-job-offers tr:hover,
  .ie-10 .table-job-offers tr:hover+tr,
  .ie-11 .table-job-offers tr:hover,
  .ie-11 .table-job-offers tr:hover+tr {
    border-color: #d6d6d6;
  }
}

@media (min-width: 1200px) {
  .table-job-offers-badge {
    width: 15%;
  }
}

.table-job-listing-2 {
  width: 100%;
  border-collapse: separate;
  margin: -10px 0;
  border-spacing: 0 10px;
}

.table-job-listing-2 tbody {
  width: 100%;
}

.table-job-listing-2 tr {
  background: #ffffff;
  transition: 0.22s;
}

.table-job-listing-2 td {
  padding: 16px 25px 16px 20px;
}

.table-job-listing-2-main {
  min-width: 360px;
  width: 50%;
}

.table-job-listing-2-location {
  width: 20%;
  white-space: nowrap;
}

.table-job-listing-2-badge {
  text-align: right;
  width: 10%;
}

.heading-decorated_1+.table-job-listing-2 {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .table-job-listing-2 {
    margin: -20px 0;
    border-spacing: 0 20px;
  }

  .table-job-listing-2 tr {
    height: 110px;
  }

  .table-job-listing-2 .company-minimal-figure {
    min-width: 100px;
  }

  .table-job-listing-2-location {
    width: 30%;
  }
}

.table-responsive {
  position: relative;
  z-index: 0;
}

/*
*
* Dividers
*/
hr {
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid #e0e0e0;
}

.divider {
  font-size: 0;
  line-height: 0;
}

.divider::after {
  content: '';
  display: inline-block;
  width: 60px;
  height: 2px;
  background-color: #52be71;
}

.divider-responsive {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .col-lg-1 .divider-responsive {
    width: 0;
    border-bottom: 0;
    border-right: 1px solid #e0e0e0;
  }
}

.divider-1 {
  height: 20px;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  background: #d6d6d6;
}

.context-dark .divider-1,
.bg-gray-700 .divider-1,
.bg-blue-13 .divider-1,
.bg-blue-15 .divider-1,
.bg-primary .divider-1 {
  background: rgba(255, 255, 255, 0.46);
}

*+.divider-1 {
  margin-top: 15px;
}

.divider-1+.button {
  margin-top: 25px;
}

@media (min-width: 768px) {
  .divider-1 {
    height: 45px;
  }
}

/*
*
* Buttons
*/
.button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 14px 35px;
  font-size: 14px;
  line-height: 1.3;
  border: 2px solid;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
}

.button-block {
  display: flow-root;
  padding-right: 2rem !important;

}


@media (max-width : 992px) {

  .newspaper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

  }

  .button-anorak {
    min-width: 150px;
    margin-top: 0.5rem;
  }

}

@media (max-width: 1200px) {
  .newspaper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .button-anorak {
    min-width: 150px;
    margin-top: 0.5rem;
  }
}

.button-default,
.button-default:focus {
  color: #d6d6d6;
  background-color: #2c343b;
  border-color: #2c343b;
}

.button-default:hover,
.button-default:active {
  color: #ffffff;
  background-color: #52be71;
  border-color: #52be71;
}

.button-default.button-anorak::before {
  background: #52be71;
}

.button-primary,
.button-primary:focus {
  color: #ffffff;
  background-color: #3d5e8e;
  border-color: #3d5e8e;
}

.button-primary:hover,
.button-primary:active {
  color: #ffffff;
  background-color: #e69564;
  border-color: #e69564;
}



.button-primary.button-anorak::before {
  background: #e69564;
}


.delete-job-button,
.delete-job-button:focus {
  color: #ed5858;
  background-color: white;
  border-color: #ed5858;
}

.delete-job-button:hover,
.delete-job-button:active {
  color: white;
  background-color: #ed5858;
}

.button-gray-100,
.button-gray-100:focus {
  color: #151515;
  background-color: #f3f4f9;
  border-color: #f3f4f9;
}

.button-gray-100:hover,
.button-gray-100:active {
  color: #ffffff;
  background-color: #52be71;
  border-color: #52be71;
}

.button-gray-100.button-anorak::before {
  background: #52be71;
}

.button-secondary,
.button-secondary:focus {
  color: #ffffff;
  background-color: #e69564;
  border-color: #e69564;
}

.button-secondary:hover,
.button-secondary:active {
  color: #ffffff;
  background-color: #52be71;
  border-color: #52be71;
}

.button-secondary.button-anorak::before {
  background: #52be71;
}

.button-blue-9,
.button-blue-9:focus {
  color: #ffffff;
  background-color: #49555e;
  border-color: #49555e;
}

.button-blue-9:hover,
.button-blue-9:active {
  color: #ffffff;
  background-color: #52be71;
  border-color: #52be71;
}

.button-blue-9.button-anorak::before {
  background: #52be71;
}

.button-blue-9 .icon,
.button-blue-9:active .icon,
.button-blue-9:focus .icon {
  color: #52be71;
}

.button-blue-9:hover .icon {
  color: #ffffff;
}

.button-blue-10,
.button-blue-10:focus {
  color: #ffffff;
  background-color: #2f353f;
  border-color: #2f353f;
}

.button-blue-10:hover,
.button-blue-10:active {
  color: #ffffff;
  background-color: #34495e;
  border-color: #34495e;
}

.button-blue-10.button-anorak::before {
  background: #34495e;
}

.button-default-outline,
.button-default-outline:focus {
  color: #ffffff;
  background-color: transparent;
  border-color: #7293c3;
}

.button-default-outline:hover,
.button-default-outline:active {
  color: #ffffff;
  background-color: #52be71;
  border-color: #52be71;
}

.button-default-outline.button-anorak::before {
  background: #52be71;
}

.button-primary-outline,
.button-primary-outline:focus {
  color: #52be71;
  background-color: transparent;
  border-color: #52be71;
}

.button-primary-outline:hover,
.button-primary-outline:active {
  color: #ffffff;
  background-color: #52be71;
  border-color: #52be71;
}

.button-primary-outline.button-anorak::before {
  background: #52be71;
}

.context-dark .button-primary-outline,
.bg-gray-700 .button-primary-outline,
.bg-blue-13 .button-primary-outline,
.bg-blue-15 .button-primary-outline,
.bg-primary .button-primary-outline,
.context-dark .button-primary-outline:active,
.bg-gray-700 .button-primary-outline:active,
.bg-blue-13 .button-primary-outline:active,
.bg-blue-15 .button-primary-outline:active,
.bg-primary .button-primary-outline:active,
.context-dark .button-primary-outline:focus,
.bg-gray-700 .button-primary-outline:focus,
.bg-blue-13 .button-primary-outline:focus,
.bg-blue-15 .button-primary-outline:focus,
.bg-primary .button-primary-outline:focus {
  color: #ffffff;
}

.button-gray-800-outline,
.button-gray-800-outline:focus {
  color: #151515;
  background-color: transparent;
  border-color: #151515;
}

.button-gray-800-outline:hover,
.button-gray-800-outline:active {
  color: #ffffff;
  background-color: #151515;
  border-color: #151515;
}

.button-gray-800-outline.button-anorak::before {
  background: #151515;
}

.button-ghost {
  border: 0;
  background-color: transparent;
}

.button-ghost:hover {
  color: #ffffff;
  background: #52be71;
}

.button-facebook,
.button-facebook:focus {
  color: #ffffff;
  background-color: #4d70a8;
  border-color: #4d70a8;
}

.button-facebook:hover,
.button-facebook:active {
  color: #ffffff;
  background-color: #6585b9;
  border-color: #6585b9;
}

.button-facebook.button-anorak::before {
  background: #6585b9;
}

.button-twitter,
.button-twitter:focus {
  color: #ffffff;
  background-color: #02bcf3;
  border-color: #02bcf3;
}

.button-twitter:hover,
.button-twitter:active {
  color: #ffffff;
  background-color: #21cbfd;
  border-color: #21cbfd;
}

.button-twitter.button-anorak::before {
  background: #21cbfd;
}

.button-google,
.button-google:focus {
  color: #ffffff;
  background-color: #e2411e;
  border-color: #e2411e;
}

.button-google:hover,
.button-google:active {
  color: #ffffff;
  background-color: #e76042;
  border-color: #e76042;
}

.button-google.button-anorak::before {
  background: #e76042;
}

.button-shadow {
  box-shadow: 0 9px 21px 0 rgba(214, 214, 214, 0.15);
}

.button-shadow:hover {
  box-shadow: 0 9px 10px 0 rgba(214, 214, 214, 0.15);
}

.button-shadow:focus,
.button-shadow:active {
  box-shadow: none;
}

.button-shadow-1 {
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.18);
}

.button-anorak {
  position: relative;
  z-index: 0;
  transition: background 0.4s, border-color 0.4s, color 0.4s;
}

.button-anorak::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #eb6a6a;
  opacity: 0;
  transform: scale(0.8, 0.5);
  transition: 0s all ease-in-out;
}

.button-anorak,
.button-anorak::before {
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button-anorak:hover::before {
  transform: scale(1, 1);
  opacity: 1;
  transition: 0.2s all ease-in-out;
}

.button-xs {
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.button-sm {
  font-weight: 500;
  letter-spacing: 0.1em;
  padding: 13px 25px;
  font-size: 13px;
  line-height: 1.5;
}

.button-lg {
  letter-spacing: 0.05em;
  padding: 17px 40px;
  font-size: 14px;
  line-height: 1.5;
}

.button-xl {
  letter-spacing: 0.075em;
  padding: 16px 50px;
  font-size: 16px;
  line-height: 28px;
}

@media (min-width: 992px) {
  .button-xl {
    padding: 20px 80px;
  }
}

.button-circle {
  border-radius: 30px;
}

.button-round-1 {
  border-radius: 5px;
}

.button-round-2 {
  border-radius: 10px;
}

.button.button-icon,
.button-icon .button-inner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.button-icon-right,
.button-icon-right .button-inner {
  flex-direction: row-reverse;
}

.button-icon .icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: inherit;
  font-size: 1.55em;
  line-height: 1em;
  transition: color inherit;
}

.button-icon-left .icon {
  padding-right: 11px;
}

.button-icon-right .icon {
  padding-left: 11px;
}

.button-icon.button-xs .icon {
  padding-right: 5px;
}

.button-icon.button-xs .button-icon-right .icon {
  padding-left: 5px;
}

.button-icon.button-sm .icon {
  top: 0.05em;
  font-size: 1.2em;
  padding-right: 8px;
}

.button-icon.button-sm .button-icon-right .icon {
  padding-left: 8px;
}

.button.button-icon-only {
  padding: 5px;
}

.btn-primary {
  border-radius: 3px;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition: 0.33s;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus {
  color: #ffffff;
  background: #52be71;
  border-color: #52be71;
}

.btn-primary:hover {
  color: #ffffff;
  background: #000000;
  border-color: #000000;
}

.button-group {
  display: flex;
}

.button-group .button {
  margin: 0 2px;
  flex-grow: 1;
}

.button-group .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button-group .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.group .button-fixed-size {
  display: inline-flex;
}

.button-fixed-size {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 5px;
}

.button-fixed-size img {
  width: auto;
  height: auto;
  max-width: 90%;
}

@media (min-width: 576px) {
  .button-fixed-size {
    width: 177px;
  }
}

label.button input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

/*
*
* Form styles
*/
.rd-form {
  position: relative;
  text-align: left;
}

.rd-form .button {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 50px;
}

*+.rd-form {
  margin-top: 20px;
}

input:-webkit-autofill~label,
input:-webkit-autofill~.form-validation {
  color: #151515 !important;
}

.form-wrap {
  position: relative;
}

.form-wrap-inner {
  position: relative;
}

.form-wrap-icon .icon {
  position: absolute;
  z-index: 10;
  top: 13px;
  left: 24px;
  color: #151515;
  line-height: 24px;
}

.form-wrap-icon .icon:not(.icon-custom-sizing) {
  font-size: 14px;
}

.form-wrap-icon .form-label,
.form-wrap-icon .form-input,
.form-wrap-icon .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 57px;
}

.has-error .form-input,
.has-error .select2-container--default .select2-selection--single {
  border-color: #eb6a6a;
}

.has-focus .form-input,
.has-focus .select2-container--default .select2-selection--single {
  border-color: #52be71;
}

.form-wrap+* {
  margin-top: 30px;
}

.rd-form .form-wrap+.row {
  margin-top: 30px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 50px;
  padding: 12px 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #151515;
  background-color: #ffffff;
  background-image: none;
  border-radius: 0;
  /* -webkit-appearance: none; */
  transition: 0.3s ease-in-out;
  border: 1px solid #e0e0e0;
}

.form-input:focus {
  outline: 0;
}

textarea.form-input {
  height: 175px;
  min-height: 50px;
  max-height: 298px;
  resize: vertical;
}

.form-label,
.form-label-outside {
  margin-bottom: 0;
  color: #9a9a9a;
  font-weight: 400;
}

.form-label {
  position: absolute;
  top: 13px;
  left: 0;
  right: 0;
  padding-left: 26px;
  padding-right: 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #151515;
  pointer-events: none;
  text-align: left;
  z-index: 9;
  transition: 0.25s;
  will-change: transform;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-label.focus {
  opacity: 0;
}

.form-label.auto-fill {
  color: #151515;
}

.form-label-outside {
  display: inline-block;
  margin-bottom: 6px;
  letter-spacing: 0.025em;
  color: #151515;
  cursor: pointer;
}

@media (min-width: 768px) {
  .form-label-outside {
    position: static;
  }

  .form-label-outside,
  .form-label-outside.focus,
  .form-label-outside.auto-fill {
    transform: none;
  }
}

.form-label-outside+.form-wrap-inner .form-label,
.form-label-outside+.form-wrap-inner .form-input,
.form-label-outside+.form-wrap-inner .select2-selection--single .select2-selection__rendered {
  color: #9a9a9a;
}

[data-x-mode='true'] .form-label {
  pointer-events: auto;
}

.form-validation {
  position: absolute;
  right: 8px;
  top: 0;
  z-index: 11;
  margin-top: 2px;
  font-size: 9px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  color: #eb6a6a;
  transition: 0.3s;
}

.form-validation-left .form-validation {
  top: 100%;
  right: auto;
  left: 0;
}

#form-output-global {
  position: fixed;
  bottom: 30px;
  left: 15px;
  z-index: 2000;
  visibility: hidden;
  transform: translate3d(-500px, 0, 0);
  transition: 0.3s all ease;
}

#form-output-global.active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 576px) {
  #form-output-global {
    left: 30px;
  }
}

.form-output {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 2px;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.form-output.active {
  opacity: 1;
  visibility: visible;
}

.form-output.error {
  color: #eb6a6a;
}

.form-output.success {
  color: #98bf44;
}

.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
  opacity: 0;
}

.radio .radio-custom,
.radio .radio-custom-dummy,
.radio-inline .radio-custom,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom,
.checkbox-inline .checkbox-custom-dummy {
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  outline: none;
  cursor: pointer;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
  pointer-events: none !important;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after,
.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
  position: absolute;
  opacity: 0;
  transition: 0.22s;
}

.radio .radio-custom:focus,
.radio-inline .radio-custom:focus,
.checkbox .checkbox-custom:focus,
.checkbox-inline .checkbox-custom:focus {
  outline: none;
}

.radio,
.radio-inline,
.checkbox,
.checkbox-inline {
  position: relative;
  cursor: pointer;
  user-select: none;
}

.radio input,
.radio-inline input,
.checkbox input,
.checkbox-inline input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.radio-custom:checked+.radio-custom-dummy:after,
.checkbox-custom:checked+.checkbox-custom-dummy:after {
  opacity: 1;
}

.radio,
.radio-inline {
  padding-left: 28px;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy {
  top: 1px;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #b7b7b7;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after {
  content: '';
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  background: #2c343b;
  border-radius: inherit;
}

.checkbox {
  display: block;
}

.checkbox,
.checkbox-inline {
  padding-left: 30px;
  color: #151515;
  letter-spacing: 0.025em;
}

.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
  top: 1px;
  left: 0;
  width: 18px;
  height: 18px;
  margin: 0;
  border: 2px solid #e0e0e0;
}

.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
  content: '\f222';
  font-family: 'Material Design Icons';
  position: absolute;
  top: -5px;
  left: -2px;
  font-size: 21px;
  line-height: 1;
  color: #151515;
}

.checkbox-inline {
  padding-left: 25px;
}

.checkbox_info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.checkbox_info>*+* {
  margin-left: 4px;
}

.checkbox_info .checkbox-meta {
  color: #9a9a9a;
}

.checkbox_info .checkbox-meta::before {
  content: '\0028';
}

.checkbox_info .checkbox-meta::after {
  content: '\0029';
}

.toggle-custom {
  padding-left: 60px;
  /* -webkit-appearance: none; */
}

.toggle-custom:checked~.checkbox-custom-dummy::after {
  background: #52be71;
  transform: translate(20px, -50%);
}

.toggle-custom~.checkbox-custom-dummy {
  position: relative;
  display: inline-block;
  margin-top: -1px;
  width: 44px;
  height: 20px;
  background: #ffffff;
  cursor: pointer;
}

.toggle-custom~.checkbox-custom-dummy::after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
  left: 0;
  top: 50%;
  background: #b7b7b7;
  transform: translate(4px, -50%);
  opacity: 1;
  transition: 0.22s;
}

.form-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  text-align: center;
  margin-bottom: -8px;
  margin-left: -30px;
}

.form-inline:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.form-inline>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 8px;
  margin-left: 30px;
}

.form-inline>* {
  margin-top: 0;
}

.form-inline .form-wrap {
  flex: 30 0;
  min-width: 220px;
}

.form-inline .form-label {
  display: block;
}

.form-inline .form-button {
  flex-grow: 1;
  min-height: 50px;
}

.form-inline .form-button .button {
  width: 100%;
  min-height: inherit;
}

@media (min-width: 576px) {
  .form-inline .button {
    display: block;
  }
}

.context-dark .form-inline .form-input,
.bg-gray-700 .form-inline .form-input,
.bg-blue-13 .form-inline .form-input,
.bg-blue-15 .form-inline .form-input,
.bg-primary .form-inline .form-input {
  border-color: #ffffff;
}

.form-inline.form-inline_condensed {
  margin-left: 0;
}

.form-inline.form-inline_condensed>* {
  margin-left: 0;
}

.form-sm .form-input,
.form-sm .button {
  padding-top: 9px;
  padding-bottom: 9px;
  min-height: 40px;
}

.form-sm .form-validation {
  top: -16px;
}

.form-sm .form-label {
  top: 20px;
}

.form-sm *+.button {
  margin-top: 10px;
}

.form-lg .form-input,
.form-lg .select2-selection.select2-selection--single .select2-selection__rendered {
  padding-top: 17px;
  padding-bottom: 17px;
}

.form-lg .form-input {
  min-height: 60px;
}

.form-lg .select2-selection.select2-selection--single .select2-selection__rendered {
  min-height: 58px;
}

.form-lg .form-button {
  min-height: 60px;
}

.form-lg .form-label {
  top: 18px;
}

.form-lg .form-wrap-icon .icon {
  top: 18px;
}

.form-centered .form-label,
.form-centered .form-input {
  text-align: center;
}

.form-centered .form-wrap+* {
  margin-top: 15px;
}

.form-light .form-input {
  box-shadow: 0 9px 21px 0 rgba(30, 30, 30, 0.38);
}

.context-dark .form-light .form-label,
.bg-gray-700 .form-light .form-label,
.bg-blue-13 .form-light .form-label,
.bg-blue-15 .form-light .form-label,
.bg-primary .form-light .form-label,
.context-dark .form-light .form-input,
.bg-gray-700 .form-light .form-input,
.bg-blue-13 .form-light .form-input,
.bg-blue-15 .form-light .form-input,
.bg-primary .form-light .form-input,
.context-dark .form-light .form-validation,
.bg-gray-700 .form-light .form-validation,
.bg-blue-13 .form-light .form-validation,
.bg-blue-15 .form-light .form-validation,
.bg-primary .form-light .form-validation {
  color: #ffffff;
}

.context-dark .form-light .form-input,
.bg-gray-700 .form-light .form-input,
.bg-blue-13 .form-light .form-input,
.bg-blue-15 .form-light .form-input,
.bg-primary .form-light .form-input {
  background: rgba(255, 255, 255, 0.47);
  border: 0;
}

.form-filled .form-input,
.form-filled .select2-container--default .select2-selection--single {
  background: #f3f4f9;
  border-color: #f3f4f9;
}

.select2-dropdown-filled.select2-dropdown {
  color: #2c343b;
  background: #f3f4f9;
  border-color: #f3f4f9;
}

.context-dark .form-filled .form-label,
.bg-gray-700 .form-filled .form-label,
.bg-blue-13 .form-filled .form-label,
.bg-blue-15 .form-filled .form-label,
.bg-primary .form-filled .form-label,
.context-dark .form-filled .form-input,
.bg-gray-700 .form-filled .form-input,
.bg-blue-13 .form-filled .form-input,
.bg-blue-15 .form-filled .form-input,
.bg-primary .form-filled .form-input,
.context-dark .form-filled .form-validation,
.bg-gray-700 .form-filled .form-validation,
.bg-blue-13 .form-filled .form-validation,
.bg-blue-15 .form-filled .form-validation,
.bg-primary .form-filled .form-validation {
  color: #ffffff;
}

.context-dark .form-filled .form-input,
.bg-gray-700 .form-filled .form-input,
.bg-blue-13 .form-filled .form-input,
.bg-blue-15 .form-filled .form-input,
.bg-primary .form-filled .form-input {
  background: rgba(255, 255, 255, 0.25);
  border-color: transparent;
}

.form-corporate {
  padding: 30px 20px;
  background: #f3f4f9;
}

.form-corporate textarea.form-input {
  height: 125px;
}

@media (min-width: 768px) {
  .form-corporate {
    padding: 30px;
  }
}

@media (min-width: 768px) {
  .form-corporate_sm {
    padding: 20px 15px;
  }
}

@media (min-width: 1200px) {
  .form-corporate {
    padding: 35px 30px 50px;
  }

  .form-corporate h4+.form-wrap {
    margin-top: 30px;
  }

  .form-corporate_sm {
    padding: 35px 35px 50px;
  }
}

.rd-form .button-form-indicator {
  position: relative;
  min-width: 60px;
  padding: 8px;
  min-height: 50px;
}

.rd-form .button-form-indicator .icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rd-form .button-form-indicator .form-icon-default {
  transition: 0.3s;
}

.rd-form .button-form-indicator .form-icon-success {
  transform: scale(0, 0);
  transition: 0.15s;
}

.rd-form.success .button-form-indicator .form-icon-default {
  transform: scale(0, 0);
  transition: 0.15s;
}

.rd-form.success .button-form-indicator .form-icon-success {
  transform: scale(1, 1);
  transition: 0.3s;
}

.rd-form.form-lg .button-form-indicator {
  min-width: 70px;
  min-height: 60px;
}

.form-compact .form-wrap+* {
  margin-top: 14px;
}

@media (max-height: 400px) {

  .form-compact .form-label,
  .form-compact .form-input {
    padding: 8px 15px;
    min-height: 36px;
  }

  .form-compact .form-label {
    top: 0;
    left: 0;
  }

  .form-compact .button {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 36px;
  }

  .form-compact .form-wrap+* {
    margin-top: 8px;
  }

  .form-compact *+.form-wrap-ally {
    margin-top: 14px;
  }
}

.form-layout-search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
  margin-left: -15px;
}

.form-layout-search:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.form-layout-search>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 15px;
}

.form-layout-search .form-wrap {
  flex: 20 0;
  min-width: 250px;
}

.form-layout-search .form-wrap-select {
  max-width: 100%;
}

.form-layout-search .form-wrap-button {
  display: inline-flex;
  flex-grow: 1;
}

.form-layout-search .form-wrap-button .button {
  width: 100%;
  min-height: inherit;
}

.form-layout-search .form-wrap-icon .icon:not(.icon-custom-sizing) {
  font-size: 20px;
}

.form-layout-search .select2-container--default .select2-selection--single .select2-selection__rendered {
  position: relative;
  top: 1px;
}

.form-layout-search .select2-container {
  display: block;
  min-width: 100%;
}

.form-layout-search.form-lg .form-label,
.form-layout-search.form-lg .form-input,
.form-layout-search.form-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 16px;
  letter-spacing: 0.05em;
}

@media (min-width: 400px) {
  .form-layout-search .form-wrap {
    min-width: 270px;
  }
}

@media (min-width: 768px) {
  .form-layout-search .form-wrap-select {
    max-width: 310px;
  }
}

@media (min-width: 1200px) {
  .form-layout-search .form-wrap-button {
    min-width: 168px;
  }

  .form-layout-search .form-wrap-button-icon-only {
    min-width: 75px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .form-layout-search .form-wrap-button-search-lg-lg {
    font-size: 0;
    flex-grow: 0;
    min-width: 50px;
  }

  .form-layout-search .form-wrap-button-search-lg-lg .button {
    padding: 10px;
    font-size: 0;
  }

  .form-layout-search .form-wrap-button-search-lg-lg .button::before {
    content: '\e01c';
    font: 400 20px 'fl-budicons-launch';
  }

  .form-layout-search.form-lg .form-wrap-button-search-lg-lg {
    min-width: 60px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .form-layout-search .form-wrap-button-search-md-lg {
    font-size: 0;
    flex-grow: 0;
    min-width: 50px;
  }

  .form-layout-search .form-wrap-button-search-md-lg .button {
    padding: 10px;
    font-size: 0;
  }

  .form-layout-search .form-wrap-button-search-md-lg .button::before {
    content: '\e01c';
    font: 400 20px 'fl-budicons-launch';
  }

  .form-layout-search.form-lg .form-wrap-button-search-md-lg {
    min-width: 60px;
  }
}

.form-layout-search+.row {
  margin-top: 40px;
}

.form-spacing-small *+.form-wrap {
  margin-top: 15px;
}

.form-creative-outer {
  padding: 24px 20px 20px;
  background: #f3f4f9;
}

@media (min-width: 768px) {
  .form-creative-outer .form-wrap-select {
    max-width: 280px;
  }
}

.context-dark .form-style-1 .form-label,
.bg-gray-700 .form-style-1 .form-label,
.bg-blue-13 .form-style-1 .form-label,
.bg-blue-15 .form-style-1 .form-label,
.bg-primary .form-style-1 .form-label,
.context-dark .form-style-1 .form-input,
.bg-gray-700 .form-style-1 .form-input,
.bg-blue-13 .form-style-1 .form-input,
.bg-blue-15 .form-style-1 .form-input,
.bg-primary .form-style-1 .form-input,
.context-dark .form-style-1 .form-validation,
.bg-gray-700 .form-style-1 .form-validation,
.bg-blue-13 .form-style-1 .form-validation,
.bg-blue-15 .form-style-1 .form-validation,
.bg-primary .form-style-1 .form-validation {
  color: #ffffff;
}

.context-dark .form-style-1 .form-validation,
.bg-gray-700 .form-style-1 .form-validation,
.bg-blue-13 .form-style-1 .form-validation,
.bg-blue-15 .form-style-1 .form-validation,
.bg-primary .form-style-1 .form-validation {
  opacity: 0.7;
}

.context-dark .form-style-1 .form-label,
.bg-gray-700 .form-style-1 .form-label,
.bg-blue-13 .form-style-1 .form-label,
.bg-blue-15 .form-style-1 .form-label,
.bg-primary .form-style-1 .form-label,
.context-dark .form-style-1 .form-input,
.bg-gray-700 .form-style-1 .form-input,
.bg-blue-13 .form-style-1 .form-input,
.bg-blue-15 .form-style-1 .form-input,
.bg-primary .form-style-1 .form-input {
  padding-left: 20px;
  padding-right: 20px;
}

.context-dark .form-style-1 .form-input,
.bg-gray-700 .form-style-1 .form-input,
.bg-blue-13 .form-style-1 .form-input,
.bg-blue-15 .form-style-1 .form-input,
.bg-primary .form-style-1 .form-input {
  background: #404851;
  border-color: #404851;
}

/*
*
* Posts
*/
.post-classic {
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  transition: 0.22s ease-in;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.post-classic:hover {
  transform: translate3d(0, -12px, 0);
}

.post-classic-media {
  position: relative;
  display: block;
}

.post-classic-media::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(54, 67, 82, 0.2);
  transition: 0.22s ease-in;
  opacity: 0;
  visibility: hidden;
}

.post-classic-media:hover::after {
  opacity: 1;
  visibility: visible;
}

.post-classic-image {
  width: 100%;
}

.post-classic-time {
  display: block;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #52be71;
}

.post-classic-text {
  letter-spacing: 0.025em;
}

.post-classic-meta {
  color: #151515;
  transform: translate3d(0, -5px, 0);
  margin-bottom: -5px;
  margin-left: -20px;
  margin-right: -20px;
}

.post-classic-meta>* {
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.post-classic-meta .icon {
  color: #52be71;
}

.post-classic-meta>li {
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle;
}

.post-classic-meta>li:not(:last-child) {
  border-right: 1px solid #e0e0e0;
}

.post-classic-meta>li a {
  display: block;
}

.post-classic-meta>li>a *+* {
  margin-left: 4px;
}

.post-classic-meta span {
  display: inline-block;
}

*+.post-classic-time {
  margin-top: 10px;
}

*+.post-classic-title {
  margin-top: 15px;
}

*+.post-classic-meta {
  margin-top: 15px;
}

*+.post-classic-text {
  margin-top: 16px;
}

@media (max-width: 767px) {
  .post-classic {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  *+.post-classic-time {
    margin-top: 12px;
  }

  *+.post-classic-title {
    margin-top: 20px;
  }

  *+.post-classic-text {
    margin-top: 24px;
  }
}

.post-creative p {
  letter-spacing: 0.05em;
}

.post-creative-image {
  width: 100%;
}

.post-creative-meta {
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;
}

.post-creative-meta p a {
  color: inherit;
}

.post-creative-meta p a:hover {
  color: #52be71;
}

.post-creative-meta-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -20px;
  margin-left: -20px;
}

.post-creative-meta-inner:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.post-creative-meta-inner>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 20px;
}

.post-creative-author {
  display: flex;
  align-items: center;
}

.post-creative-author>*+* {
  margin-top: 0;
  margin-left: 10px;
}

.post-creative-author-image {
  display: block;
  max-width: 36px;
  border-radius: 50%;
}

.post-creative-meta-list {
  transform: translate3d(0, -5px, 0);
  margin-bottom: -5px;
  margin-left: -19px;
  margin-right: -19px;
  letter-spacing: 0.05em;
}

.post-creative-meta-list>* {
  margin-top: 5px;
  padding-left: 19px;
  padding-right: 19px;
}

.post-creative-meta-list>li {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
}

.post-creative-meta-list>li>* {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
}

.post-creative-meta-list>li>*+* {
  margin-left: 5px;
}

.post-creative-meta-list>li:not(:last-child) {
  border-right: 1px solid #e0e0e0;
}

.post-creative-meta-list .icon {
  position: relative;
  top: -1px;
  color: #151515;
}

.post-creative-meta-list .mdi-clock {
  font-size: 16px;
}

.post-creative-meta-list .fl-justicons-visible6 {
  font-size: 13px;
}

.post-creative-meta-list .mdi-message-outline {
  font-size: 15px;
}

.post-creative-footer {
  padding: 20px 25px;
  color: #151515;
  background: #f3f4f9;
}

.post-creative-footer-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -10px;
  margin-left: -10px;
}

.post-creative-footer-inner:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.post-creative-footer-inner>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

.post-creative-footer-inner>* {
  margin-top: 0;
}

.post-creative-meta+* {
  margin-top: 20px;
}

*+.post-creative-footer {
  margin-top: 25px;
}

@media (min-width: 992px) {
  .post-creative-meta+* {
    margin-top: 32px;
  }
}

.post-light {
  display: flex;
  background: #f3f4f9;
  transition: 0.22s ease-in;
}

.post-light,
.post-light:active,
.post-light:focus,
.post-light:hover {
  color: #9a9a9a;
}

.post-light:hover {
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.post-light-media {
  position: relative;
  overflow: hidden;
  display: block;
  width: 106px;
  flex-shrink: 0;
}

.post-light-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
  max-width: none;
}

@supports (object-fit: cover) {
  .post-light-image {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    object-fit: cover;
    object-position: center center;
  }
}

.post-light-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 18px;
}

.post-light-title {
  max-width: 170px;
  color: #151515;
}

.post-light-title a {
  color: inherit;
}

.post-light-time {
  display: block;
}

*+.post-light {
  margin-top: 16px;
}

*+.post-light-time {
  margin-top: 1px;
}

.post-info {
  background: #ffffff;
}

.post-info *+hr {
  margin-top: 16px;
}

.post-info *+.badge {
  margin-top: 10px;
}

.post-info *+.button {
  margin-top: 22px;
}

.post-info-media {
  position: relative;
  overflow: hidden;
  display: flex;
  background-position: center center;
  background-size: cover;
}

.post-info-media::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(57, 71, 84, 0.52);
}

.post-info-media>* {
  width: 100%;
  flex-shrink: 0;
}

.post-info-media-caption {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.post-info-media-dummy {
  opacity: 0;
  visibility: hidden;
}

.post-info-media-dummy::before {
  content: '';
  display: block;
  padding-bottom: 49.59128%;
}

.post-info-main {
  padding: 30px 15px;
}

.post-info-title {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  color: #151515;
}

.post-info-title a {
  color: inherit;
}

.post-info-meta {
  color: #151515;
  letter-spacing: 0.025em;
}

.post-info-meta>li {
  white-space: nowrap;
}

.post-info-meta>li>* {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.post-info-meta>li>*+* {
  margin-left: 10px;
}

.post-info-meta .icon {
  position: relative;
  top: 3px;
  color: #52be71;
}

.post-info-meta>li+li {
  margin-top: 5px;
}

*+.post-info-meta {
  margin-top: 15px;
}

*+.post-info-text {
  margin-top: 18px;
}

@media (max-width: 575px) {
  .post-info {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .post-info-title {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .post-info-main {
    padding: 30px 30px 35px;
  }
}

@media (min-width: 1200px) {
  .post-info-main {
    padding: 33px 40px 40px;
  }
}

.post-modern {
  padding: 30px 21px 40px;
  background: #ffffff;
  box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.13);
  transition: 0.3s;
}

.post-modern *+hr {
  margin-top: 16px;
}

.post-modern:hover {
  transform: translateY(-8px);
  box-shadow: 0 14px 24px 2px rgba(0, 0, 0, 0.16);
}

.post-modern-media {
  position: relative;
  display: block;
}

.post-modern-media::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.22s ease-in;
  opacity: 0;
  visibility: hidden;
}

.post-modern-media:hover::after {
  opacity: 1;
  visibility: visible;
}

.post-modern-image {
  width: 100%;
}

.post-modern-title {
  max-width: 300px;
  letter-spacing: 0.025em;
}

.post-modern-meta {
  font: 600 12px/16px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform: translate3d(0, -5px, 0);
  margin-bottom: -5px;
  margin-left: -17px;
  margin-right: -17px;
}

.post-modern-meta>* {
  margin-top: 5px;
  padding-left: 17px;
  padding-right: 17px;
}

.post-modern-meta a {
  letter-spacing: inherit;
}

.post-modern-meta>li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.post-modern-meta>li:not(:last-child)::before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  height: 15px;
  border-right: 1px solid #d6d6d6;
}

@media (min-width: 576px) and (max-width: 991px) {
  .col-12.col-lg-4 .post-modern {
    display: flex;
    align-items: center;
    max-width: 100%;
    padding: 30px 50px 30px 25px;
  }

  .col-12.col-lg-4 .post-modern>*+* {
    margin-left: 30px;
  }

  .col-12.col-lg-4 .post-modern-media {
    flex-shrink: 0;
    max-width: 48%;
  }

  .col-12.col-lg-4 .post-modern-main {
    flex-grow: 1;
    margin-top: 0;
  }
}

*+.post-modern-main {
  margin-top: 14px;
}

*+.post-modern-title {
  margin-top: 14px;
}

*+.post-modern-meta {
  margin-top: 14px;
}

@media (max-width: 575px) {
  .post-modern {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .post-modern *+hr {
    margin-top: 25px;
  }

  *+.post-modern-main {
    margin-top: 25px;
  }

  *+.post-modern-title {
    margin-top: 25px;
  }

  *+.post-modern-meta {
    margin-top: 25px;
  }
}

.post-minimal {
  background: #ffffff;
  transition: 0.3s;
}

.post-minimal p {
  letter-spacing: 0.025em;
}

.post-minimal:hover {
  transform: translateY(-8px);
  box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.13);
}

.post-minimal-media {
  position: relative;
  display: block;
}

.post-minimal-media::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.22s ease-in;
  opacity: 0;
  visibility: hidden;
}

.post-minimal-media:hover::after {
  opacity: 1;
  visibility: visible;
}

.post-minimal-image {
  width: 100%;
}

.post-minimal-main {
  padding: 25px 15px;
}

.post-minimal-title {
  max-width: 300px;
}

.post-minimal-title a {
  letter-spacing: 0;
}

.post-minimal-time {
  display: block;
  font: 600 12px/16px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  color: #52be71;
  letter-spacing: 0.1em;
}

@media (min-width: 576px) and (max-width: 991px) {
  .col-12.col-lg-4 .post-minimal {
    display: flex;
    max-width: 100%;
  }

  .col-12.col-lg-4 .post-minimal-media {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    width: 100%;
    max-width: 44%;
  }

  .col-12.col-lg-4 .post-minimal-media img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
    max-width: none;
  }

  @supports (object-fit: cover) {
    .col-12.col-lg-4 .post-minimal-media img {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: none;
      object-fit: cover;
      object-position: center center;
    }
  }

  .col-12.col-lg-4 .post-minimal-main {
    flex-grow: 1;
    align-self: center;
    padding: 40px 35px;
  }
}

*+.post-minimal-title {
  margin-top: 14px;
}

*+.post-minimal-time {
  margin-top: 14px;
}

@media (max-width: 575px) {
  .post-minimal {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .post-minimal *+hr {
    margin-top: 25px;
  }

  .post-minimal-main {
    padding: 20px 35px 35px 40px;
  }

  *+.post-minimal-title {
    margin-top: 25px;
  }

  *+.post-minimal-time {
    margin-top: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .post-minimal-main {
    padding: 20px 20px 30px 26px;
  }
}

.post-line {
  display: flex;
  max-width: 320px;
  align-items: flex-start;
  text-align: left;
  color: #9a9a9a;
}

.post-line-time {
  position: relative;
  display: block;
  min-width: 60px;
  padding: 3px 10px 3px 0;
  text-align: center;
}

.post-line-time::after {
  content: '';
  position: absolute;
  top: 2px;
  bottom: 8px;
  right: 0;
  width: 1px;
  background: #d6d6d6;
  pointer-events: none;
}

.post-line-time *+* {
  margin-top: 3px;
}

.post-line-time-day,
.post-line-time-month {
  display: block;
  line-height: 1;
}

.post-line-time-day {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 33px;
  color: #151515;
}

.post-line-time-month {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.post-line-text {
  padding: 3px 0 3px 20px;
  margin: 0;
  line-height: 1.71429;
  color: #151515;
  transition: 0.25s;
}

.post-line-text a {
  display: block;
  color: inherit;
}

.post-line-text a:hover {
  color: #52be71;
}

*+.post-line {
  margin-top: 20px;
}

.context-dark .post-line:hover .post-line-text,
.bg-gray-700 .post-line:hover .post-line-text,
.bg-blue-13 .post-line:hover .post-line-text,
.bg-blue-15 .post-line:hover .post-line-text,
.bg-primary .post-line:hover .post-line-text {
  text-shadow: 1px 1px 10px rgba(255, 255, 255, 0.7);
}

.context-dark .post-line-text,
.bg-gray-700 .post-line-text,
.bg-blue-13 .post-line-text,
.bg-blue-15 .post-line-text,
.bg-primary .post-line-text {
  color: #ffffff;
}

.context-dark .post-line-time,
.bg-gray-700 .post-line-time,
.bg-blue-13 .post-line-time,
.bg-blue-15 .post-line-time,
.bg-primary .post-line-time {
  color: rgba(255, 255, 255, 0.5);
}

.context-dark .post-line-time::after,
.bg-gray-700 .post-line-time::after,
.bg-blue-13 .post-line-time::after,
.bg-blue-15 .post-line-time::after,
.bg-primary .post-line-time::after {
  background: rgba(255, 255, 255, 0.12);
}

.context-dark .post-line-time-day,
.bg-gray-700 .post-line-time-day,
.bg-blue-13 .post-line-time-day,
.bg-blue-15 .post-line-time-day,
.bg-primary .post-line-time-day {
  color: rgba(255, 255, 255, 0.7);
}

/*
*
* Quotes
*/
.quote-light {
  padding: 20px 15px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: #151515;
  text-align: left;
}

.quote-light p {
  display: inline;
  margin: 0;
}

.quote-light-inner {
  position: relative;
  padding-left: 20px;
}

.quote-light-mark svg {
  fill: #e7e8ee;
}

.quote-light-mark-left {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
}

.quote-light-mark-right {
  display: inline;
  vertical-align: middle;
  margin-left: 5px;
}

.quote-light-mark-right svg {
  transform: scale(-1, -1);
}

*+.quote-light {
  margin-top: 30px;
}

.quote-light+p {
  margin-top: 20px;
}

@media (max-width: 575px) {
  .quote-light-mark svg {
    width: 14px;
    height: 13px;
  }
}

@media (min-width: 576px) {
  .quote-light {
    font-size: 20px;
  }

  .quote-light-inner {
    padding-left: 40px;
  }

  .quote-light+p {
    margin-top: 26px;
  }
}

@media (min-width: 768px) {
  .quote-light {
    padding: 20px 30px;
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .quote-light {
    padding: 20px 45px;
    font-size: 24px;
  }
}

.quote-classic {
  position: relative;
  text-align: left;
  padding: 30px 20px;
  margin-bottom: 15px;
  box-shadow: 0 24px 21px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
}

.quote-classic::after {
  content: '';
  position: absolute;
  left: 20px;
  bottom: -17px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px 26px 0 0;
  border-color: #ffffff transparent transparent transparent;
  pointer-events: none;
}

.quote-classic-mark {
  fill: #d2d4df;
}

.quote-classic-text {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  color: #151515;
  letter-spacing: 0.01em;
}

.quote-classic-meta {
  display: flex;
  align-items: center;
}

.quote-classic-meta>*+* {
  margin-left: 20px;
}

.quote-classic-avatar {
  position: relative;
  display: block;
  border-radius: 50%;
  max-width: 75px;
  flex-shrink: 0;
}

.quote-classic-cite {
  display: block;
  font-weight: 500;
}

.quote-classic-info {
  flex-grow: 1;
}

.quote-classic-info>*+* {
  margin-top: 0;
}

.quote-classic-subtitle {
  letter-spacing: 0.025em;
  color: #52be71;
}

*+.quote-classic-text {
  margin-top: 11px;
}

*+.quote-classic-meta {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .quote-classic {
    padding: 30px 40px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .quote-classic::after {
    left: 40px;
  }
}

@media (min-width: 768px) {
  .quote-classic-text {
    font-size: 15px;
    line-height: 1.625;
  }
}

@media (min-width: 992px) {
  .quote-classic {
    padding-left: 30px;
    padding-right: 35px;
  }

  .quote-classic::after {
    left: 30px;
  }

  .quote-classic-text {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .quote-classic-avatar {
    max-width: 60px;
  }
}

@media (min-width: 1200px) {
  .quote-classic {
    padding: 40px 15px 40px 40px;
  }

  .quote-classic::after {
    left: 40px;
  }

  .quote-classic-text {
    font-size: 16px;
  }
}

.quote-minimal {
  max-width: 250px;
  text-align: left;
}

.quote-minimal-mark {
  fill: #e0e0e0;
}

.quote-minimal-divider {
  width: 80px;
  border-bottom: 1px solid #f3f4f9;
}

.quote-minimal-text,
.quote-minimal-cite,
.quote-minimal-position {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 15px;
  line-height: 1.5;
}

.quote-minimal-text {
  font-weight: 300;
}

.quote-minimal-cite {
  font-weight: 500;
}

.quote-minimal-position {
  font-weight: 300;
  color: #52be71;
}

*+.quote-minimal-text {
  margin-top: 10px;
}

*+.quote-minimal-divider {
  margin-top: 20px;
}

*+.quote-minimal-cite {
  margin-top: 15px;
}

*+.quote-minimal-position {
  margin-top: 3px;
}

.context-dark .quote-minimal,
.bg-gray-700 .quote-minimal,
.bg-blue-13 .quote-minimal,
.bg-blue-15 .quote-minimal,
.bg-primary .quote-minimal {
  color: #ffffff;
}

.context-dark .quote-minimal-mark,
.bg-gray-700 .quote-minimal-mark,
.bg-blue-13 .quote-minimal-mark,
.bg-blue-15 .quote-minimal-mark,
.bg-primary .quote-minimal-mark {
  fill: rgba(255, 255, 255, 0.21);
}

.context-dark .quote-minimal-divider,
.bg-gray-700 .quote-minimal-divider,
.bg-blue-13 .quote-minimal-divider,
.bg-blue-15 .quote-minimal-divider,
.bg-primary .quote-minimal-divider {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}

@media (min-width: 768px) {

  .quote-minimal-text,
  .quote-minimal-cite,
  .quote-minimal-position {
    font-size: 16px;
  }

  *+.quote-minimal-text {
    margin-top: 20px;
  }

  *+.quote-minimal-divider {
    margin-top: 25px;
  }

  *+.quote-minimal-cite {
    margin-top: 25px;
  }
}

.quote-modern {
  max-width: 560px;
  text-align: left;
}

.quote-modern-header {
  margin-bottom: -5px;
  margin-left: -15px;
}

.quote-modern-header:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.quote-modern-header>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 5px;
  margin-left: 15px;
}

.quote-modern-header>* {
  display: inline-block;
  vertical-align: middle;
}

.quote-modern-mark {
  position: absolute;
  top: -10px;
  left: 0;
  z-index: -1;
  fill: #d2d4df;
  opacity: 0.4;
}

.quote-modern-text {
  position: relative;
  z-index: 1;
  padding-left: 20px;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  letter-spacing: 0.025em;
  line-height: 1.625;
  color: #151515;
}

*+.quote-modern-text {
  margin-top: 22px;
}

@media (min-width: 992px) {
  .quote-modern-mark {
    top: 8px;
    opacity: 1;
  }

  .quote-modern-text {
    padding-left: 70px;
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .quote-modern-text {
    font-size: 16px;
  }
}

.quote-mary {
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.quote-mary-mark {
  fill: #d6d6d6;
}

.quote-mary-text {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  letter-spacing: 0.025em;
  line-height: 1.625;
}

.quote-mary-position {
  line-height: 1.2;
  letter-spacing: 0.025em;
  color: #52be71;
}

.context-dark .quote-mary-mark,
.bg-gray-700 .quote-mary-mark,
.bg-blue-13 .quote-mary-mark,
.bg-blue-15 .quote-mary-mark,
.bg-primary .quote-mary-mark {
  fill: #6f8193;
}

*+.quote-mary-text {
  margin-top: 10px;
}

*+.quote-mary-cite {
  margin-top: 15px;
}

*+.quote-mary-position {
  margin-top: 6px;
}

@media (min-width: 576px) {
  .quote-mary-text {
    font-size: 16px;
  }

  *+.quote-mary-text {
    margin-top: 15px;
  }

  *+.quote-mary-cite {
    margin-top: 20px;
  }
}

/*
*
* Thumbnails
*/
.figure-light figcaption {
  padding-top: 10px;
  color: #b7b7b7;
}

.figure-responsive img {
  min-width: 100%;
}

@media (min-width: 992px) {
  .col-lg-6.height-fill .figure-responsive {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .col-lg-6.height-fill .figure-responsive img {
    height: auto;
    width: auto;
    flex-shrink: 0;
    max-width: none;
  }
}

.thumbnail-classic {
  position: relative;
  overflow: hidden;
  display: flex;
}

.thumbnail-classic-dummy,
.thumbnail-classic-caption {
  width: 100%;
  flex-shrink: 0;
}

.thumbnail-classic-dummy {
  visibility: hidden;
  opacity: 0;
}

.thumbnail-classic-dummy::before {
  content: '';
  display: block;
  padding-bottom: 72.01087%;
}

.thumbnail-classic-figure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.thumbnail-classic-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
  max-width: none;
}

@supports (object-fit: cover) {
  .thumbnail-classic-image {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    object-fit: cover;
    object-position: center center;
  }
}

.thumbnail-classic-caption {
  position: relative;
  z-index: 1;
  align-self: flex-end;
  padding: 20px;
  text-align: center;
  background: rgba(74, 87, 103, 0.53);
}

.thumbnail-classic-title {
  color: #ffffff;
}

@media (max-width: 575px) {
  .thumbnail-classic {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

html:not(.tablet):not(.mobile) .thumbnail-classic-figure {
  transition: 0.44s;
}

html:not(.tablet):not(.mobile) .thumbnail-classic-caption {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.22s;
}

html:not(.tablet):not(.mobile) .thumbnail-classic:hover .thumbnail-classic-figure {
  transform: scale3d(1.05, 1.05, 1.05);
}

html:not(.tablet):not(.mobile) .thumbnail-classic:hover .thumbnail-classic-caption {
  opacity: 1;
  visibility: visible;
}

.thumbnail-chico {
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #ffffff;
}

.thumbnail-chico-image {
  width: 100%;
}

.thumbnail-chico-caption {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  pointer-events: none;
  background: rgba(37, 45, 47, 0.3);
}

.thumbnail-chico-caption>* {
  max-width: 100%;
}

.thumbnail-chico-caption::before {
  content: '';
  position: absolute;
  top: 18px;
  right: 18px;
  bottom: 18px;
  left: 18px;
  border: 1px solid rgba(255, 255, 255, 0.62);
}

.thumbnail-chico-title {
  color: #ffffff;
  pointer-events: auto;
}

@media (min-width: 992px) {
  html:not(.tablet):not(.mobile) .thumbnail-chico {
    will-change: transform;
  }

  html:not(.tablet):not(.mobile) .thumbnail-chico-image {
    transition: 0.32s;
    will-change: transform;
    transform: scale3d(1, 1, 1);
  }

  html:not(.tablet):not(.mobile) .thumbnail-chico-caption {
    transition: 0.25s;
    opacity: 0;
    visibility: hidden;
    will-change: opacity;
  }

  html:not(.tablet):not(.mobile) .thumbnail-chico-caption .thumbnail-chico-title {
    transition: 0.3s ease;
    transform: scale3d(0.9, 0.9, 0.9);
  }

  html:not(.tablet):not(.mobile) .thumbnail-chico-caption::before {
    transition: 0.33s;
    transform: scale3d(1.05, 1.05, 1.05);
  }

  html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-caption {
    opacity: 1;
    visibility: visible;
  }

  html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-caption .thumbnail-chico-title,
  html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-caption::before {
    transform: scale3d(1, 1, 1);
  }

  html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-image {
    transform: scale3d(1.03, 1.03, 1.03);
  }
}

.thumbnail-indigo {
  position: relative;
  display: block;
}

.thumbnail-indigo:hover .thumbnail-indigo-figure {
  transform: translate3d(0, -10px, 0);
  box-shadow: -1px 5px 22px 4px rgba(0, 0, 0, 0.2);
}

.thumbnail-indigo-figure {
  position: relative;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: #2c343b;
  background-position: center top;
  background-size: cover;
  box-shadow: -1px 1px 21px 2px rgba(0, 0, 0, 0.14);
  transition: 0.2s ease-out;
}

.thumbnail-indigo-figure::before {
  content: '';
  display: block;
  opacity: 0;
  visibility: hidden;
  padding-bottom: 70.65463%;
}

*+.thumbnail-indigo-title {
  margin-top: 16px;
}

@media (min-width: 1200px) {
  *+.thumbnail-indigo-title {
    margin-top: 22px;
  }
}

/*
*
* Breadcrumbs
*/
.breadcrumbs-custom {
  background-color: #2c343b;
}

.breadcrumbs-custom+.bg-gray-100 {
  border-top: 1px solid #d6d6d6;
}

.breadcrumbs-custom-main {
  position: relative;
  padding: 40px 0;
  background-position: center center;
  background-size: cover;
}

.breadcrumbs-custom-main::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  background: rgba(54, 67, 82, 0.45);
}

.breadcrumbs-custom-main>* {
  position: relative;
  z-index: 2;
}

.breadcrumbs-custom-overlay-1 .breadcrumbs-custom-main::before {
  background: rgba(54, 67, 82, 0.29);
}

.breadcrumbs-custom-overlay-2 .breadcrumbs-custom-main::before {
  background: rgba(54, 67, 82, 0.53);
}

.breadcrumbs-custom-overlay-3 .breadcrumbs-custom-main::before {
  background: rgba(54, 67, 82, 0.4);
}

.breadcrumbs-custom-overlay-4 .breadcrumbs-custom-main::before {
  background: rgba(54, 67, 82, 0.63);
}

.breadcrumbs-custom-aside {
  padding: 15px 0;
  background: #f3f4f9;
}

.breadcrumbs-custom-path {
  margin-left: -15px;
  margin-right: -15px;
}

.breadcrumbs-custom-path>* {
  padding-left: 15px;
  padding-right: 15px;
}

.breadcrumbs-custom-path a {
  display: inline;
  vertical-align: middle;
}

.breadcrumbs-custom-path a,
.breadcrumbs-custom-path a:active,
.breadcrumbs-custom-path a:focus {
  color: #52be71;
}

.breadcrumbs-custom-path li {
  position: relative;
  display: inline-block;
  line-height: 1.2;
  letter-spacing: 0.05em;
  vertical-align: middle;
}

.breadcrumbs-custom-path li::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  right: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  background: #cbcbcb;
}

.breadcrumbs-custom-path li:last-child:after {
  display: none;
}

.breadcrumbs-custom-path a:hover,
.breadcrumbs-custom-path li.active {
  color: #9a9a9a;
}

@media (min-width: 576px) {
  .breadcrumbs-custom-aside {
    padding: 23px 0;
  }
}

@media (min-width: 768px) {
  .breadcrumbs-custom-main {
    padding: 65px 0 70px;
  }
}

/*
*
* Pagination custom
*/
.pagination {
  display: inline-flex;
  flex-wrap: wrap;
  font-size: 0;
  line-height: 0;
  margin-bottom: -6px;
  margin-left: -6px;
}

.pagination:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.pagination>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 6px;
  margin-left: 6px;
}

.page-item {
  display: inline-flex !important;
  vertical-align: middle !important;
  text-align: center !important;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0.05em !important;
  text-transform: uppercase !important;
}

.page-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 36px !important;
  padding: 5px 12px !important;
  border: 1px solid #e0e0e0 !important;
  line-height: 2 !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  transition: 0.15s !important;
}

.page-link:hover,
.page-link:focus,
.page-link:active {
  color: #ffffff !important;
  background-color: #52be71 !important;
  border-color: #52be71 !important;
}

.page-item.active>.page-link,
.page-item.active>.page-link:hover,
.page-item.active>.page-link:focus,
.page-item.active>.page-link:active {
  color: #ffffff !important;
  background-color: #52be71 !important;
  border-color: #52be71 !important;
}

.page-item.disabled>.page-link,
.page-item.disabled>.page-link:hover,
.page-item.disabled>.page-link:focus,
.page-item.disabled>.page-link:active {
  color: #b7b7b7 !important;
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}

.page-item-control .page-link {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

*+.pagination-outer {
  margin-top: 30px !important;
}

@media (max-width: 767px) {
  .page-item-control {
    font-size: 0 !important;
  }

  .page-item-control .page-link {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .page-item-control .page-link::before {
    font-family: 'Material Design Icons' !important;
    font-size: 18px !important;
    line-height: 1 !important;
  }

  .page-item-control:first-child .page-link::before {
    content: '\f237';
  }

  .page-item-control:last-child .page-link::before {
    content: '\f238';
  }
}

@media (min-width: 768px) {
  *+.pagination-outer {
    margin-top: 50px;
  }
}

/*
*
* Snackbars
*/
.snackbars {
  padding: 9px 16px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  text-align: left;
  background-color: #e69564;
  border-radius: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
}

.snackbars .icon-xxs {
  font-size: 18px;
}

.snackbars p span:last-child {
  padding-left: 14px;
}

.snackbars-left {
  display: inline-block;
  margin-bottom: 0;
}

.snackbars-right {
  display: inline-block;
  float: right;
  text-transform: uppercase;
}

.snackbars-right:hover {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .snackbars {
    max-width: 540px;
    padding: 12px 15px;
    font-size: 15px;
  }
}

/*
*
* Footers
*/
.footer-classic p {
  color: #56616c;
}

.footer-classic .list-marked-1 {
  color: #ffffff;
}

.footer-classic .list-marked-1>li::before {
  border-color: transparent transparent transparent #4c5762;
}

.footer-classic-main {
  padding: 35px 0;
  background: #303841;
}

.footer-classic-main a {
  color: #ffffff;
}

.footer-classic-main a:hover {
  color: #52be71;
}

.footer-classic-aside {
  padding: 25px 0;
  background: #29323a;
  text-align: center;
}

.footer-classic-aside * {
  color: #56616c;
}

.footer-classic-aside .rights a:hover {
  color: #52be71;
}

.footer-classic-title {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.025em;
}

p.footer-classic-title {
  color: #ffffff;
}

.footer-classic-divider {
  font-size: 0;
  line-height: 0;
  padding: 0 3px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-classic-divider::before {
  content: '';
  display: inline-block;
  height: 2px;
  width: 40px;
  margin-bottom: -1px;
  background: #52be71;
}

*+.footer-classic-divider {
  margin-top: 16px;
}

.footer-classic .footer-classic-divider+* {
  margin-top: 16px;
}

@media (min-width: 768px) {
  .footer-classic-main {
    padding: 60px 0 70px;
  }

  .footer-classic-title {
    font-size: 20px;
  }

  *+.footer-classic-divider {
    margin-top: 20px;
  }

  .footer-classic .footer-classic-divider+* {
    margin-top: 24px;
  }
}

.footer-modern p {
  color: #56616c;
}

.footer-modern .list-marked-1 {
  color: #ffffff;
}

.footer-modern .list-marked-1>li::before {
  border-color: transparent transparent transparent #4c5762;
}

.footer-modern-main {
  padding: 35px 0;
  background: #303841;
}

.footer-modern-main a {
  color: #ffffff;
}

.footer-modern-main a:hover {
  color: #52be71;
}

.footer-modern-title {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.025em;
}

p.footer-modern-title {
  color: #ffffff;
}

.footer-modern-divider {
  font-size: 0;
  line-height: 0;
  padding: 0 3px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-modern-aside {
  padding: 25px 0;
  background: #29323a;
}

.footer-modern-aside * {
  color: #56616c;
}

.footer-modern-aside .rights a:hover {
  color: #52be71;
}

.footer-modern-aside-inner {
  margin-bottom: -10px;
  text-align: center;
}

.footer-modern-aside-inner>* {
  margin-bottom: 10px;
}

*+.footer-modern-divider {
  margin-top: 16px;
}

.footer-modern .footer-modern-divider+* {
  margin-top: 16px;
}

@media (min-width: 576px) {
  .footer-modern-aside-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-left: -20px;
  }

  .footer-modern-aside-inner>* {
    margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .footer-modern-main {
    padding: 70px 0 95px;
  }

  *+.footer-modern-divider {
    margin-top: 20px;
  }

  .footer-modern .footer-modern-divider+* {
    margin-top: 24px;
  }
}

@media (min-width: 1200px) {
  .footer-modern-title {
    font-size: 20px;
  }
}

.footer-creative p {
  color: #56616c;
}

.footer-creative .list-marked-1 {
  color: #ffffff;
}

.footer-creative .list-marked-1>li::before {
  border-color: transparent transparent transparent #4c5762;
}

.footer-creative .list-marked-1 a:hover,
.footer-creative .list-icons a:hover {
  color: #ffffff;
  text-shadow: 1px 1px 10px rgba(255, 255, 255, 0.7);
}

.footer-creative .list-marked-1 .icon,
.footer-creative .list-icons .icon {
  text-shadow: none;
}

.footer-creative .post-line-time {
  color: #4a5767;
}

.footer-creative .post-line-time-day {
  color: #4a5767;
}

.footer-creative .list-icons .icon {
  color: #4c5762;
}

.footer-creative-main {
  padding: 35px 0;
  background: #303841;
}

.footer-creative-main a {
  color: #ffffff;
}

.footer-creative-main a:hover {
  color: #52be71;
}

.footer-creative-title {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

p.footer-creative-title {
  color: #ffffff;
}

.footer-creative-divider {
  font-size: 0;
  line-height: 0;
  padding: 0 3px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-creative-aside {
  padding: 25px 0;
  background: #29323a;
  text-align: center;
}

.footer-creative-aside * {
  color: #56616c;
}

.footer-creative-aside .rights a:hover {
  color: #52be71;
}

*+.footer-creative-divider {
  margin-top: 16px;
}

.footer-creative .footer-creative-divider+* {
  margin-top: 16px;
}

@media (min-width: 768px) {
  .footer-creative-main {
    padding: 70px 0 95px;
  }

  *+.footer-creative-divider {
    margin-top: 20px;
  }

  .footer-creative .footer-creative-divider+* {
    margin-top: 24px;
  }
}

@media (min-width: 1200px) {
  .footer-creative-title {
    font-size: 16px;
  }
}

/*
*
* Layouts
*/
.grid-layout {
  display: flex;
  flex-wrap: wrap;
}

.grid-layout-item {
  position: relative;
  display: block;
  width: 100%;
  padding: 25px 20px;
  text-align: left;
  border: 1px solid #d6d6d6;
  margin: -1px -1px 0 0;
}

.grid-layout-item::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.07);
  transition: 0.22s;
  opacity: 0;
  visibility: hidden;
  backface-visibility: hidden;
}

.grid-layout-item:hover {
  z-index: 2;
}

.grid-layout-item:hover::before {
  opacity: 1;
  visibility: visible;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  transition: top 0.3s ease-out, right 0.3s ease-out, bottom 0.3s ease-out, left 0.3s ease-out;
}

.grid-layout-item>* {
  position: relative;
  z-index: 1;
}

*+.grid-layout {
  margin-top: 40px;
}

@media (max-width: 575px) {
  .grid-layout-item {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .grid-layout-item {
    width: 50%;
    padding: 43px 20px 43px 30px;
  }
}

@media (min-width: 992px) {
  .grid-layout-item {
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  .grid-layout-item {
    width: 25%;
  }
}

.layout-bordered {
  text-align: center;
}

.layout-bordered-item {
  padding: 20px 0;
  border: 0 solid #e0e0e0;
}

.layout-bordered-item-inner {
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.layout-bordered-item-inner>*+* {
  margin-top: 10px;
}

*+.layout-bordered {
  margin-top: 40px;
}

.layout-bordered+.row {
  margin-top: 40px;
}

@media (max-width: 767px) {
  .layout-bordered-item {
    padding: 30px 0;
  }

  .layout-bordered-item:first-child {
    padding-top: 0;
  }

  .layout-bordered-item:last-child {
    padding-bottom: 0;
  }

  .layout-bordered-item .icon.unit-left {
    min-width: 30px;
  }

  .layout-bordered-item .unit-left.icon-lg {
    font-size: 20px;
  }

  .layout-bordered-item .unit-left.icon-xl {
    font-size: 25px;
  }

  .layout-bordered-item+.layout-bordered-item {
    border-top-width: 1px;
  }
}

@media (min-width: 768px) {
  .layout-bordered {
    display: flex;
  }

  .layout-bordered-item {
    padding: 0;
    flex-grow: 1;
  }

  .layout-bordered-item-inner>*+* {
    margin-top: 15px;
  }

  .layout-bordered-item+.layout-bordered-item {
    border-top-width: 0;
  }

  *+.layout-bordered {
    margin-top: 50px;
  }

  .layout-bordered+.row {
    margin-top: 50px;
  }
}

@media (min-width: 768px) {
  .layout-bordered-item {
    flex-basis: 33.333%;
  }

  .layout-bordered-item:last-child {
    border-right-width: 0;
  }

  .layout-bordered+.row {
    margin-top: 65px;
  }
}

@media (min-width: 992px) {
  .layout-bordered-item {
    border-right-width: 1px;
  }

  .layout-bordered-item-inner {
    max-width: 250px;
  }
}

.blog-layout-aside {
  margin-bottom: -35px;
}

.blog-layout-aside>* {
  margin-bottom: 35px;
}

.blog-layout-aside *+.rd-form {
  margin-top: 20px;
}

.blog-layout-aside-item .blog-layout-title+* {
  margin-top: 15px;
}

.blog-layout-aside-item .blog-layout-title+p {
  margin-top: 10px;
}

.blog-layout-title {
  font: 600 13px/18px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 1.4;
  color: #151515;
}

*+.blog-layout-aside {
  margin-top: 55px;
}

@media (min-width: 768px) {
  *+.blog-layout-aside {
    margin-top: 70px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog-layout-aside {
    columns: 2;
    column-gap: 30px;
    break-inside: avoid;
  }

  .blog-layout-aside-item {
    display: inline-block;
    width: 100%;
  }

  *+.blog-layout-aside-item {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .blog-layout {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: -30px;
  }

  .blog-layout>* {
    margin-left: 30px;
  }

  .blog-layout-main {
    flex-grow: 1;
  }

  .blog-layout-aside {
    flex-shrink: 0;
    width: 26.5%;
    margin-bottom: -45px;
  }

  .blog-layout-aside>* {
    margin-bottom: 45px;
  }

  *+.blog-layout-aside {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .blog-layout {
    margin-left: -70px;
  }

  .blog-layout>* {
    margin-left: 70px;
  }
}

.layout-info {
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.layout-info>*+* {
  margin-top: 25px;
}

.layout-info-aside {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -8px;
  margin-left: -20px;
}

.layout-info-aside:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.layout-info-aside>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 8px;
  margin-left: 20px;
}

.layout-info-aside>*+* {
  margin-top: 0;
}

.layout-info-aside-item .link {
  letter-spacing: 0.05em;
}

@media (min-width: 576px) {
  .layout-info {
    display: flex;
  }

  .layout-info>*+* {
    position: relative;
    margin-top: 0;
  }

  .layout-info>*+*::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    height: 50%;
    border-right: 1px solid #e0e0e0;
  }

  .layout-info-main,
  .layout-info-aside {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .layout-info-main {
    min-width: 64%;
  }

  .layout-info-aside {
    min-width: 36%;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .layout-info-main {
    min-width: 52%;
  }

  .layout-info-aside {
    min-width: 36%;
  }
}

.layout-details {
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.layout-details>*+* {
  margin-top: 25px;
}

.layout-1 {
  padding: 18px 25px;
  background: #f3f4f9;
}

.layout-1-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -15px;
  margin-left: -15px;
}

.layout-1-inner:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.layout-1-inner>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 15px;
}

.layout-1-inner>*+* {
  margin-top: 0;
}

*+.layout-1 {
  margin-top: 25px;
}

@media (min-width: 768px) {
  *+.layout-1 {
    margin-top: 30px;
  }
}

.layout-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: -35px;
  text-align: center;
}

.layout-2>* {
  margin-bottom: 35px;
}

.layout-2>* {
  flex-shrink: 0;
  max-width: 100%;
}

@media (min-width: 768px) {
  .layout-2 {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-left: -35px;
    text-align: left;
  }

  .layout-2>* {
    margin-left: 35px;
  }

  .layout-2>* {
    flex-shrink: 1;
  }

  .layout-2-item-main {
    min-width: 425px;
  }
}

.layout-3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -10px;
  margin-left: -10px;
}

.layout-3:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.layout-3>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

.grid-1-outer {
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
}

.grid-1 {
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
  width: calc(100% + 6px);
}

.grid-1-item {
  display: flex;
  width: 50%;
  border: 1px solid #d6d6d6;
  margin: -1px -1px 0 0;
}

.grid-1-item-link {
  display: flex;
  padding: 23px 20px;
  min-height: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 0.6s;
}

.grid-1-item-link:hover {
  background: rgba(0, 0, 0, 0.03);
}

.grid-1-item-link img {
  max-width: 90%;
}

*+.grid-1-outer {
  margin-top: 30px;
}

@media (min-width: 576px) {
  .grid-1-item {
    width: 33.33333%;
  }
}

@media (min-width: 768px) {
  .grid-1-item-link {
    padding: 40px 20px 40px 30px;
  }
}

@media (min-width: 992px) {
  .grid-1-item {
    width: 25%;
  }

  *+.grid-1-outer {
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .grid-1-item-link {
    min-height: 170px;
  }
}

/*
*
* Pricing Tables
*/
.pt-title {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #151515;
}

.pt-price-per-annum {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 300;
  color: #151515;

}

.pt-price {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 36px;
  line-height: 1;
  font-weight: 300;
  color: #151515;
}

.pt-price-small {
  position: relative;
  top: 0.25em;
  vertical-align: center;
  font-size: 0.47143em;
}

.context-dark .pt-title,
.bg-gray-700 .pt-title,
.bg-blue-13 .pt-title,
.bg-blue-15 .pt-title,
.bg-primary .pt-title,
.context-dark .pt-price,
.bg-gray-700 .pt-price,
.bg-blue-13 .pt-price,
.bg-blue-15 .pt-price,
.bg-primary .pt-price {
  color: #ffffff;
}

*+.pt-price {
  margin-top: 16px;
}

@media (max-width: 767px) {
  .pt {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .pt-price {
    font-size: 56px;
  }
}

@media (min-width: 1200px) {
  .pt-price {
    font-size: 70px;
  }
}

.pt-classic {
  border: 1px solid #e0e0e0;
  text-align: center;
}

.pt-classic-header {
  padding: 30px 20px;
  background: #f3f4f9;
}

.pt-classic-item {
  margin-bottom: -15px;
  margin-left: -15px;
}

.pt-classic-item:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.pt-classic-item>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 15px;
}

.pt-classic-item>* {
  display: inline-block;
  vertical-align: middle;
}

.pt-classic-main {
  padding: 20px;
}

.pt-classic-main .button {
  padding-top: 18px;
  padding-bottom: 18px;
}

.pt-classic-features>li+li {
  margin-top: 12px;
}

@media (min-width: 1200px) {
  .pt-classic-header {
    padding: 38px;
  }

  .pt-classic-main {
    padding: 30px 40px 50px;
  }
}

.pt-creative {
  padding: 30px 20px;
  text-align: center;
  border: 4px solid rgba(82, 190, 113, 0.33);
}

.pt-creative .button {
  padding-top: 18px;
  padding-bottom: 18px;
}

.pt-creative .button-primary-outline:hover {
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.18);
}

.pt-creative_preferred {
  border-color: #52be71;
}

.pt-creative-features {
  text-align: center;
}

.pt-creative-features .list {
  display: inline-block;
}

*+.pt-creative-group {
  margin-top: 40px;
}

*+.pt-creative {
  margin-top: 40px;
}

*+.pt-creative-features {
  margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .pt-creative-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -30px;
    margin-left: -30px;
  }

  .pt-creative-group:empty {
    margin-bottom: 0;
    margin-left: 0;
  }

  .pt-creative-group>* {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 30px;
    margin-left: 30px;
  }

  .pt-creative-group .pt-creative {
    width: calc(50% - 30px);
  }
}

@media (min-width: 992px) {
  .pt-creative-group {
    display: flex;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }

  .pt-creative-group .pt-creative {
    width: calc(33.3% + 4px);
  }

  .pt-creative-group .pt.pt-creative {
    margin-top: 10px;
  }

  .pt-creative-group .pt.pt-creative_preferred {
    width: 34%;
    border-width: 6px;
    margin: 0 -6px -10px;
  }

  .pt-creative-group *+.pt-creative {
    margin-top: 0;
  }

  .pt-creative {
    padding: 30px 25px 40px;
  }

  .pt-creative *+.button {
    margin-top: 30px;
  }

  .pt-creative .pt-title+.pt-price {
    margin-top: 23px;
  }

  .pt-creative_preferred {
    padding: 40px 25px 30px;
  }

  *+.pt-creative-features {
    margin-top: 35px;
  }
}

@media (min-width: 1200px) {
  .pt-creative {
    padding: 40px 25px 50px;
  }

  .pt-creative_preferred {
    padding: 50px 25px 35px;
  }
}

.pt-modern {
  padding: 30px 20px;
  text-align: center;
  border: 1px solid #e0e0e0;
}

.pt-modern .button {
  padding-top: 18px;
  padding-bottom: 18px;
}

.pt-modern-item {
  margin-bottom: -15px;
  margin-left: -15px;
}

.pt-modern-item:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.pt-modern-item>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 15px;
}

.pt-modern-item>* {
  display: inline-block;
  vertical-align: middle;
}

.pt-modern-features {
  text-align: center;
}

.pt-modern-features .list {
  position: relative;
  left: -10px;
  display: inline-block;
}

*+.pt-modern-group {
  margin-top: 40px;
}

*+.pt-modern {
  margin-top: 40px;
}

*+.pt-modern-features {
  margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .pt-modern-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -30px;
    margin-left: -30px;
  }

  .pt-modern-group:empty {
    margin-bottom: 0;
    margin-left: 0;
  }

  .pt-modern-group>* {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 30px;
    margin-left: 30px;
  }

  .pt-modern-group .pt-modern {
    width: calc(50% - 30px);
  }
}

@media (min-width: 992px) {
  .pt-modern-group {
    display: flex;
    padding: 30px 0;
    border: 1px solid #e0e0e0;
  }

  .pt-modern-group .pt-modern {
    width: 33.3%;
    padding-top: 0;
    padding-bottom: 0;
    border-width: 0 1px 0 0;
  }

  .pt-modern-group .pt-modern:last-child {
    border-width: 0;
  }

  .pt-modern-group *+.pt-modern {
    margin-top: 0;
  }

  .pt-modern-group *+.pt-price {
    margin-top: 22px;
  }

  .pt-modern {
    padding: 30px 25px 40px;
  }

  .pt-modern *+.button {
    margin-top: 30px;
  }

  .pt-modern .pt-title+.pt-price {
    margin-top: 23px;
  }

  *+.pt-modern-features {
    margin-top: 35px;
  }
}

@media (min-width: 1200px) {
  .pt-modern-group {
    padding: 55px 0;
  }

  .pt-modern {
    padding: 40px 25px 50px;
  }
}

/*
*
* Badge Custom
*/
.badge {
  display: inline-block;
  padding: 4px 6px 3px;
  font: 600 12px/12px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #ffffff;
  background: #52be71;
  border-radius: 0;
}

.badge-secondary {
  background: #e69564;
}

.badge-tertiary {
  background: #6c9dca;
}

.badge-blue-11 {
  background: #537586;
}

.badge-modern {
  position: relative;
  z-index: 1;
  padding-left: 15px;
  overflow: hidden;
  background-color: transparent;
}

.badge-modern::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 10px;
  z-index: -1;
  background: #52be71;
  pointer-events: none;
}

.badge-modern span {
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 0;
  left: 0;
  transform-origin: 0 50%;
  transform: scale(1, 1.3);
  z-index: -1;
  pointer-events: none;
}

.badge-modern span::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -2px;
  width: 100px;
  height: 100px;
  transform-origin: 12px 100%;
  transform: rotate(45deg);
  background: #52be71;
  z-index: -2;
}

.badge-modern.badge-secondary::before,
.badge-modern.badge-secondary span:after {
  background: #e69564;
}

.badge-modern.badge-tertiary::before,
.badge-modern.badge-tertiary span:after {
  background: #6c9dca;
}

/*
*
* Alert Custom
*/
.alert {
  background: #e4eee3;
  border: 1px solid #e0e0e0;
  border-radius: 0;
}

.alert-type-1 {
  padding: 20px 20px 20px 30px;
}

.alert-type-1 .alert-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -20px;
  margin-left: -20px;
}

.alert-type-1 .alert-inner:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.alert-type-1 .alert-inner>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 20px;
}

.alert-type-1 .alert-main {
  max-width: 595px;
  letter-spacing: 0.025em;
  color: #151515;
}

@media (min-width: 768px) {
  .alert-type-1 .alert-main {
    font-size: 16px;
    line-height: 1.5;
  }
}

.comment {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  margin-bottom: -10px;
  margin-left: -20px;
}

.comment:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.comment>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 20px;
}

.comment-group .comment-group {
  padding-left: 20px;
}

.comment-group .comment-group .comment-group {
  padding-left: 10px;
}

.comment-main {
  width: 100%;
}

.comment-image {
  max-width: 100px;
  border-radius: 50%;
}

.comment-title {
  font: 500 16px/24px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  letter-spacing: 0.05em;
  color: #151515;
}

.comment-time {
  display: block;
  font-weight: 500;
  color: #151515;
}

.comment-list {
  transform: translate3d(0, -10px, 0);
  margin-bottom: -10px;
  margin-left: -10px;
  margin-right: -10px;
  font-size: 13px;
  font-weight: 500;
  color: #151515;
}

.comment-list>* {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-list>li {
  display: inline-block;
  vertical-align: middle;
}

.comment-list>li * {
  vertical-align: middle;
}

.comment-list>li *+* {
  margin-left: 8px;
}

.comment-list>li>a {
  transition: color 0.22s;
}

.comment-list>li>a:hover,
.comment-list>li>a:hover .icon {
  color: #52be71;
}

.comment-list .icon {
  margin-top: 1px;
  font-size: 16px;
  color: #9fa3a7;
  transition: color 0.22s;
}

.comment-list .mdi-comment-outline {
  margin-top: 2px;
}

.comment-link {
  letter-spacing: 0.1em;
  font-weight: 500;
  color: #151515;
}

.comment-group {
  position: relative;
  padding-bottom: 30px;
}

.comment-group::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #e0e0e0;
}

.comment-group .comment-group {
  padding-bottom: 0;
}

.comment-group .comment-group::before {
  display: none;
}

.comment-group .comment-group .comment img {
  max-width: 52px;
}

*+.comment-text {
  margin-top: 8px;
}

*+.comment-footer {
  margin-top: 14px;
}

*+.comment-group {
  margin-top: 30px;
}

.comment+* {
  margin-top: 25px;
}

.comment-group+.comment-group {
  margin-top: 30px;
}

@media (min-width: 576px) {
  .comment-header {
    flex-grow: 1;
  }

  .comment-header-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    transform: translate3d(0, -10px, 0);
    margin-bottom: -10px;
    margin-left: -10px;
    margin-right: -10px;
  }

  .comment-header-inner>* {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .comment {
    position: relative;
    flex-direction: column;
    padding-left: 95px;
    margin-bottom: -15px;
    margin-left: 0;
  }

  .comment:empty {
    margin-bottom: 0;
    margin-left: 0;
  }

  .comment>* {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 15px;
    margin-left: 0;
  }

  .comment .comment-aside {
    position: absolute;
    top: 0;
    left: 0;
  }

  .comment p {
    letter-spacing: 0.05em;
  }

  .comment-header {
    width: 100%;
  }

  .comment-group {
    max-width: 850px;
  }

  .comment-group::before {
    left: 100px;
  }

  .comment-group .comment-group {
    padding-left: 100px;
  }

  .comment-group .comment-group .comment {
    padding-left: 75px;
  }

  .comment-group .comment-group .comment .comment-aside {
    margin-top: 10px;
  }

  .comment-group .comment-group .comment-group {
    padding-left: 50px;
  }

  .comment-header {
    padding-right: 20px;
  }

  .comment-header>*+* {
    margin-left: 20px;
  }

  .comment-list {
    transform: translate3d(0, -10px, 0);
    margin-bottom: -10px;
    margin-left: -13px;
    margin-right: -13px;
  }

  .comment-list>* {
    margin-top: 10px;
    padding-left: 13px;
    padding-right: 13px;
  }
}

@media (min-width: 1200px) {
  .comment-group {
    max-width: 850px;
    padding-bottom: 40px;
  }

  .comment-group .comment-group {
    padding-left: 135px;
  }

  .comment-group .comment-group .comment-group {
    padding-left: 50px;
  }

  .comment-group+.comment-group {
    margin-top: 45px;
  }
}

.comment-box {
  max-width: 830px;
}

.comment-box .unit-body {
  flex-grow: 1;
}

.comment-box textarea.form-input {
  height: 145px;
}

.comment-box *+.rd-mailform {
  margin-top: 20px;
}

.comment-box-image {
  border-radius: 50%;
}

*+.comment-box {
  margin-top: 30px;
}

@media (min-width: 768px) {
  *+.comment-box {
    margin-top: 42px;
  }
}

/*
*
* Profiles
*/
.profile-minimal {
  padding: 16px !important;
  border: 1px solid #e0e0e0 !important;
}

.profile-minimal-inner {
  margin-bottom: -16px !important;
  margin-left: -16px !important;
}

.profile-minimal-inner:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.profile-minimal-inner>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 16px;
  margin-left: 16px;
}

.profile-minimal-name {
  margin-top: 15px;
}

.profile-minimal-main-outer {
  flex-grow: 1;
}

.profile-minimal-main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -16px;
  margin-left: -16px;
}

.profile-minimal-main:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.profile-minimal-main>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 16px;
  margin-left: 16px;
}

.profile-minimal-main p {
  letter-spacing: 0.025em;
}

.profile-minimal-image {
  max-width: 70px;
  flex-shrink: 0;
}

.profile-minimal-main-info>*+* {
  margin-top: 4px;
}

.profile-minimal-position {
  color: #52be71;
}

p.profile-minimal-position {
  letter-spacing: 0.05em;
}

.profile-minimal-meta {
  min-width: 30%;
  letter-spacing: 0.05em;
  white-space: nowrap;
}

.profile-minimal-meta>* {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.profile-minimal-meta>*+* {
  margin-left: 4px;
}

.profile-minimal-meta .icon {
  position: relative;
  top: 2px;
  font-size: 16px;
  color: #52be71;
}

.profile-minimal+.profile-minimal {
  border-top: 0;
  margin-top: 0;
}

*+.profile-minimal-position {
  margin-top: 0;
}

@media (min-width: 576px) {
  .profile-minimal-meta {
    max-width: 200px;
  }
}

@media (min-width: 576px) {
  .profile-minimal-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .profile-minimal-image {
    min-width: 101px;
  }
}

.profile-classic {
  display: block;
  max-width: 265px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
  background: #ffffff;
  backface-visibility: hidden;
  transition: 0.22s;
}

.profile-classic,
.profile-classic:active,
.profile-classic:focus,
.profile-classic:hover {
  color: #9a9a9a;
}

.profile-classic:hover {
  transform: translateY(-10px);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
}

.profile-classic-image {
  width: 100%;
}

.profile-classic-main {
  padding: 20px 15px 25px;
}

.profile-classic-list {
  white-space: nowrap;
  letter-spacing: 0.05em;
}

.profile-classic-list>li>* {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.profile-classic-list>li>*+* {
  margin-left: 3px;
}

.profile-classic-list .icon {
  position: relative;
  top: 3px;
  font-size: 16px;
  color: #52be71;
}

*+.profile-classic-list {
  margin-top: 12px;
}

@media (min-width: 992px) {
  .profile-classic-main {
    padding: 20px 20px 30px 20px;
  }

  .profile-classic-list .icon {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .profile-classic-main {
    padding: 25px 30px 35px;
  }
}

.profile-light {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: -15px;
  margin-left: -25px;
  text-align: left;
}

.profile-light:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.profile-light>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 25px;
}

.profile-light-image,
.profile-light-main {
  max-width: 170px;
}

.profile-light-image {
  width: 100%;
  flex-shrink: 0;
  border: 4px solid #ffffff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.24);
}

.profile-light-main {
  flex-grow: 1;
}

.profile-light-main a {
  color: white !important;
  background-color: #52be71;
  padding: 10px;

}

.profile-light-divider {
  border-bottom: 1px solid #e0e0e0;
}

.profile-light-list>li {
  white-space: nowrap;
}

.profile-light-list>li>* {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}

.profile-light-list {
  margin-left: -2rem !important;
}

.profile-light-list>li>*+* {
  margin-left: 5px;
}

.profile-light-list .icon {
  color: #52be71;
}

.profile-light-list>li+li {
  margin-top: 5px;
}

*+.profile-light-divider {
  margin-top: 18px;
}

*+.profile-light-list {
  margin-top: 18px;
}

.context-dark .profile-light-position,
.bg-gray-700 .profile-light-position,
.bg-blue-13 .profile-light-position,
.bg-blue-15 .profile-light-position,
.bg-primary .profile-light-position {
  color: #52be71;
}

.context-dark .profile-light-divider,
.bg-gray-700 .profile-light-divider,
.bg-blue-13 .profile-light-divider,
.bg-blue-15 .profile-light-divider,
.bg-primary .profile-light-divider {
  border-bottom-color: rgba(255, 255, 255, 0.16);
}

.context-dark .profile-light-list,
.bg-gray-700 .profile-light-list,
.bg-blue-13 .profile-light-list,
.bg-blue-15 .profile-light-list,
.bg-primary .profile-light-list {
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
  .profile-light {
    justify-content: flex-start;
    max-width: 400px;
  }

  .profile-light-image {
    max-width: 178px;
  }

  .profile-light-main {
    max-width: 210px;
  }
}

.profile-modern {
  position: relative;
  overflow: hidden;
  display: block;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  text-align: left;
}

.profile-modern,
.profile-modern:active,
.profile-modern:focus,
.profile-modern:hover {
  color: #9a9a9a;
}

.profile-modern:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
}

.profile-modern:hover .profile-modern-title {
  color: #52be71;
}

.profile-modern:hover .profile-modern-media {
  transform: scale3d(1.05, 1.05, 1.05);
}

.profile-modern p {
  letter-spacing: 0;
}

.profile-modern *+hr {
  margin-top: 14px;
}

.profile-modern-media {
  position: relative;
  display: block;
  transform-origin: 50% 0;
  transition: 0.33s;
}

.profile-modern-media::before {
  content: '';
  display: block;
  padding-top: 73.84196%;
}

.profile-modern-media img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
  max-width: none;
}

@supports (object-fit: cover) {
  .profile-modern-media img {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    object-fit: cover;
    object-position: center center;
  }
}

.profile-modern-main {
  position: relative;
  z-index: 1;
  padding: 20px;
  background: #ffffff;
}

.profile-modern-title {
  transition: 0.22s;
}

.profile-modern-meta {
  color: #151515;
  letter-spacing: 0.025em;
}

.profile-modern-meta>li {
  white-space: nowrap;
}

.profile-modern-meta>li>* {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.profile-modern-meta>li>*+* {
  margin-left: 5px;
}

.profile-modern-meta .icon {
  position: relative;
  top: 3px;
  min-width: 20px;
  color: #52be71;
}

.profile-modern-meta>li+li {
  margin-top: 6px;
}

*+.profile-modern-meta {
  margin-top: 14px;
}

@media (min-width: 768px) {
  .profile-modern p {
    letter-spacing: 0.05em;
  }

  .profile-modern *+hr {
    margin-top: 20px;
  }

  .profile-modern-main {
    padding: 35px 37px 40px;
  }
}

/*
*
* Companies
*/
.company-minimal {
  display: flex;
  align-items: center;
  margin-left: -20px;
  min-width: 240px;
}

.company-minimal>* {
  margin-left: 20px;
}

.company-minimal p {
  letter-spacing: 0.05em;
}

.company-minimal-figure {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 56px;
  height: 56px;
  background: #e2e4ee;
  flex-shrink: 0;
}

.company-minimal-image {
  max-width: 80%;
  max-height: 80%;
}

.company-minimal-main>*+* {
  margin-top: 3px;
}

.company-minimal-main p {
  line-height: 1.4;
}

@media (min-width: 768px) {
  .company-minimal-figure {
    width: 78px;
    height: 78px;
  }
}

.table-companies {
  width: 100%;
}

.table-companies tbody {
  width: 100%;
}

.table-companies tr {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  transition: 0.22s;
}

.table-companies tr:hover {
  background: #f3f4f9;
}

.table-companies td {
  padding: 16px 20px;
}

.table-companies-main {
  width: 54%;
}

.table-companies-meta {
  width: 23%;
  white-space: nowrap;
  letter-spacing: 0.05em;
}

.table-companies-meta>* {
  display: inline-block;
  vertical-align: middle;
}

.table-companies-meta>*+* {
  margin-left: 4px;
}

.table-companies-meta .icon {
  position: relative;
  top: -1px;
  font-size: 16px;
  color: #52be71;
}

.block-sort-options {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #151515;
  font-size: 0;
  line-height: 0;
}

.block-sort-options>* {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 33.2px;
  flex-shrink: 0;
  font: 600 12px/16px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  padding: 6px 8px;
  border: 1px solid #d6d6d6;
  text-align: center;
  margin: -1px 0 0 -1px;
  cursor: pointer;
  transition: 0.25s;
}

.block-sort-options>*:hover {
  z-index: 1;
  border-color: #52be71;
}

.block-sort-options>.selected {
  position: relative;
  z-index: 1;
  border-color: #52be71;
  color: #52be71;
}

@media (min-width: 768px) {
  .block-sort-options>* {
    flex-grow: 1;
  }
}

.company-light {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -24px;
  margin-left: -24px;
}

.company-light:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.company-light>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 24px;
  margin-left: 24px;
}

.company-light-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92px;
  height: 92px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  flex-shrink: 0;
}

.company-light-figure {
  flex-shrink: 0;
  min-width: 58px;
  text-align: center;
}

.company-light-logo-image {
  max-width: 80%;
  max-height: 80%;
}

.company-light-main {
  flex-grow: 1;
}

.company-light-main>*+* {
  margin-top: 3px;
}

*+.company-light-info {
  margin-top: 16px;
}

@media (max-width: 575px) {
  .company-light-info {
    max-width: 180px;
  }
}

@media (max-width: 767px) {
  .company-light_1 {
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .company-light .row {
    margin-left: -30px;
    margin-right: -30px;
  }

  .company-light .row>[class*='col'] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .company-light-logo {
    width: 125px;
    height: 120px;
  }
}

/*
*
* Messages
*/
.message-bubble {
  position: relative;
  color: #151515;
}

.message-bubble-inner {
  position: relative;
  display: flex;
  padding: 10px 15px 10px;
  border: 1px solid #e0e0e0;
  border-top: 0;
  margin-top: 12px;
  letter-spacing: 0.025em;
}

.message-bubble-inner>*+* {
  margin-left: 5px;
}

.message-bubble-inner::before {
  content: '';
  position: absolute;
  top: -12px;
  right: -1px;
  height: 12px;
  border-left: 1px solid #e0e0e0;
}

.message-bubble-inner::after {
  content: '';
  position: absolute;
  left: 15px;
  right: -1px;
  top: -12px;
  height: 12px;
  border: 1px solid #e0e0e0;
  border-left-width: 0;
  border-top-width: 0;
  transform: skewX(-55deg);
  transform-origin: 0 0;
}

.message-bubble-inner p+p {
  margin-top: 2px;
}

*+.message-bubble {
  margin-top: 4px;
}

.message-inline {
  padding: 10px 3px;
  text-align: center;
}

.message-inline p+p {
  margin-top: 0;
}

.message-inline>*+* {
  margin-top: 3px;
}

/*
*
* Jumbotron Custom
*/
.jumbotron-classic {
  position: relative;
  display: flex;
  overflow: hidden;
  text-align: center;
}

.jumbotron-classic-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
}

.jumbotron-classic-inner>.container {
  position: relative;
  z-index: 2;
}

.jumbotron-classic-image-left,
.jumbotron-classic-image-right {
  position: absolute;
  top: 30%;
  transform: translate3d(0, -30%, 0);
  max-height: 96%;
  max-width: 40%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  opacity: 0.5;
  backface-visibility: hidden;
  contain: paint;
}

.jumbotron-classic-image-left img,
.jumbotron-classic-image-right img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.jumbotron-classic-image-left {
  left: 0;
  align-items: flex-start;
}

.jumbotron-classic-image-right {
  right: 0;
  align-items: flex-end;
}

.jumbotron-classic-header {
  position: relative;
  padding: 25px 0;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.jumbotron-classic-header::before,
.jumbotron-classic-header::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 240px;
  border-bottom: 1px solid #e0e0e0;
}

.jumbotron-classic-header::before {
  top: 0;
}

.jumbotron-classic-header::after {
  bottom: 0;
}

.jumbotron-classic-main {
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
}

.jumbotron-classic-main form .form-input,
.jumbotron-classic-main form .select2-container,
.jumbotron-classic-main form .button {
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.18);
}

*+.jumbotron-classic-main {
  margin-top: 30px;
}

.context-dark .jumbotron-classic-header::before,
.bg-gray-700 .jumbotron-classic-header::before,
.bg-blue-13 .jumbotron-classic-header::before,
.bg-blue-15 .jumbotron-classic-header::before,
.bg-primary .jumbotron-classic-header::before,
.context-dark .jumbotron-classic-header::after,
.bg-gray-700 .jumbotron-classic-header::after,
.bg-blue-13 .jumbotron-classic-header::after,
.bg-blue-15 .jumbotron-classic-header::after,
.bg-primary .jumbotron-classic-header::after {
  border-bottom-color: rgba(255, 255, 255, 0.37);
}

@media (min-width: 768px) {
  .jumbotron-classic-inner {
    padding: 100px 0 120px;
    min-height: 36.45833vw;
  }

  .jumbotron-classic-image-left,
  .jumbotron-classic-image-right {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    opacity: 0.8;
  }

  .jumbotron-classic-header {
    max-width: 520px;
    padding: 35px 0;
  }

  *+.jumbotron-classic-main {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .jumbotron-classic-header {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .jumbotron-classic-inner {
    padding: 130px 0 150px;
  }

  .jumbotron-classic-image-left,
  .jumbotron-classic-image-right {
    opacity: 0.9;
  }

  *+.jumbotron-classic-main {
    margin-top: 90px;
  }
}

@media (min-width: 1600px) {

  .jumbotron-classic-image-left,
  .jumbotron-classic-image-right {
    opacity: 1;
  }
}

.jumbotron-modern {
  position: relative;
  display: flex;
  overflow: hidden;
  text-align: center;
}

.jumbotron-modern-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
}

.jumbotron-modern-inner>.container {
  position: relative;
  z-index: 2;
}

.jumbotron-modern-image-left,
.jumbotron-modern-image-right {
  position: absolute;
  top: 30%;
  transform: translate3d(0, -30%, 0);
  max-height: 96%;
  max-width: 40%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  opacity: 0.5;
  backface-visibility: hidden;
  contain: paint;
}

.jumbotron-modern-image-left img,
.jumbotron-modern-image-right img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.jumbotron-modern-image-left {
  left: 0;
  align-items: flex-start;
}

.jumbotron-modern-image-right {
  right: 0;
  align-items: flex-end;
}

@media (min-width: 768px) {
  .jumbotron-modern-inner {
    padding: 90px 0 100px;
    min-height: 30vw;
  }

  .jumbotron-modern-image-left,
  .jumbotron-modern-image-right {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    opacity: 0.8;
  }
}

@media (min-width: 1200px) {

  .jumbotron-modern-image-left,
  .jumbotron-modern-image-right {
    opacity: 0.9;
  }
}

@media (min-width: 1600px) {

  .jumbotron-modern-image-left,
  .jumbotron-modern-image-right {
    opacity: 1;
  }
}

.jumbotron-creative {
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(-274deg, #456391 0%, #547ebe 100%);
}

.jumbotron-creative hr {
  border-color: rgba(255, 255, 255, 0.12);
}

.jumbotron-creative p.big a {
  color: #69e08c;
}

.jumbotron-creative p.big a:hover {
  color: #e69564;
}

.jumbotron-creative .form-layout-search {
  margin-bottom: -8px;
  margin-left: -8px;
}

.jumbotron-creative .form-layout-search>* {
  margin-bottom: 8px;
  margin-left: 8px;
}

.jumbotron-creative *+.form-layout-search {
  margin-top: 35px;
}

.jumbotron-creative-slider.slick-slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
}

.jumbotron-creative-slider.slick-slider .slick-track {
  display: flex;
  height: 100%;
}

.jumbotron-creative-slider.slick-slider .slick-list {
  width: 100%;
}

.jumbotron-creative-slider.slick-slider .slick-slide {
  position: relative;
  height: auto;
  float: none;
}

.jumbotron-creative-slider.slick-slider .slick-slide-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-position: 75% center;
  background-size: cover;
}

.jumbotron-creative-slider.slick-slider .slick-dots {
  bottom: 30px;
}

.jumbotron-creative-inner {
  display: flex;
}

.jumbotron-creative-main-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.jumbotron-creative-main {
  padding: 40px 0 90px;
  text-align: center;
}

.jumbotron-creative-main .form-layout-search {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575px) {
  .jumbotron-creative-main-carousel h2 {
    font-size: 24px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .jumbotron-creative-main-carousel {
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .jumbotron-creative .form-layout-search .form-wrap {
    min-width: 240px;
  }

  .jumbotron-creative .form-layout-search .form-wrap-select {
    max-width: 240px;
  }

  .jumbotron-creative .form-layout-search .form-wrap-button {
    min-width: 75px;
  }

  .jumbotron-creative-slider.slick-slider .slick-slide-inner {
    background-position: 50% center;
    opacity: 0.85;
  }

  .jumbotron-creative-slider.slick-slider .slick-dots {
    position: absolute;
    right: 5%;
    top: 50%;
    bottom: auto;
    z-index: 2;
    width: auto;
    transform: translate3d(0, -50%, 0);
  }

  .jumbotron-creative-slider.slick-slider .slick-dots>li {
    display: block;
  }

  .jumbotron-creative-slider.slick-slider .slick-dots>li:not(:hover):not(.slick-active) {
    border-color: #ffffff;
  }

  .jumbotron-creative-slider.slick-slider .slick-dots>li+li {
    margin-top: 10px;
  }

  .jumbotron-creative-main-outer {
    min-height: 37.23958vw;
  }

  .jumbotron-creative-main {
    padding: 70px 0 140px;
    max-width: 520px;
    text-align: left;
  }

  .jumbotron-creative-main .form-layout-search {
    max-width: 370px;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .jumbotron-creative-slider.slick-slider .slide-slide-inner {
    opacity: 1;
  }

  .jumbotron-creative-main .form-layout-search {
    max-width: 460px;
  }
}

@media (min-width: 1200px) {
  .slick-slider.jumbotron-creative-slider .slick-slide-inner {
    background-position: 50% center;
  }

  .slick-slider.jumbotron-creative-slider .slick-dots {
    right: 10%;
  }

  .jumbotron-creative-main {
    max-width: 680px;
    padding: 70px 0 180px;
  }

  .jumbotron-creative-main .form-layout-search {
    max-width: 580px;
  }
}

/*
* Page layout
*/
.page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  opacity: 0;
}

.page.fadeIn {
  animation-timing-function: ease-out;
}

.page.fadeOut {
  animation-timing-function: ease-in;
}

[data-x-mode] .page {
  opacity: 1;
}

.page-header .rd-navbar {
  position: relative;
  z-index: 1030;
}

.ph-classic-form {
  position: relative;
  z-index: 101;
  padding: 25px 20px;
  background: #f3f4f9;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.17);
}

.ph-classic-form .select2-container--default .select2-selection--single,
.ph-classic-form .form-input {
  border-color: transparent;
}

.ph-classic-main {
  padding: 40px 0 50px;
}

.ph-classic-main .slick-slider-1 .carousel-parent {
  display: none;
}

*+.ph-classic-form-outer {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .ph-classic-main {
    padding: 60px 0 80px;
  }

  .ph-classic-main .slick-slider-1 .carousel-parent {
    display: block;
    margin: -20px 0 -30px 0;
  }

  .ph-classic-form {
    padding: 40px 30px;
  }

  .page-header-classic+.ph-classic-form-outer {
    margin-top: -80px;
  }

  *+.ph-classic-form-outer {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .ph-classic-form .form-layout-search .form-wrap-select {
    max-width: 240px;
    min-width: 240px;
  }
}

@media (min-width: 1200px) {
  .ph-classic-main {
    padding: 60px 0 220px;
  }

  .ph-classic-main .slick-slider-1 .carousel-parent {
    margin: -70px 0 -150px 0;
  }

  .ph-classic-form {
    padding: 70px 40px;
  }

  .page-header-classic+.ph-classic-form-outer {
    margin-top: -140px;
  }
}

html.boxed body {
  /* background: #f3f4f9 url(../images/bg-pattern-boxed.png) repeat fixed; */
}

@media (min-width: 1630px) {
  html.boxed .page {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 23px 0 rgba(1, 1, 1, 0.1);
  }

  html.boxed .rd-navbar-static {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }

  html.boxed .rd-navbar-static.rd-navbar--is-stuck {
    max-width: 1600px;
    width: 100%;
    left: calc(50% - 1600px);
    right: calc(50% - 1600px);
  }
}

/*
*
* Text styling
*/
.text-italic {
  font-style: italic;
}

.text-normal {
  font-style: normal;
}

.text-underline {
  text-decoration: underline;
}

.text-strike {
  text-decoration: line-through;
}

.font-weight-thin {
  font-weight: 100;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-sbold {
  font-weight: 600;
}

.font-weight-ubold {
  font-weight: 900;
}

.text-spacing-0 {
  letter-spacing: 0;
}

.text-spacing-05 {
  letter-spacing: 0.05em;
}

html .text-primary {
  color: #52be71;
}

html .text-secondary {
  color: #e69564;
}

html .text-tertiary {
  color: #6c9dca;
}

html .text-gray-3 {
  color: #e4e6ef;
}

html .text-gray-500 {
  color: #9a9a9a;
}

html .text-gray-800 {
  color: #151515;
}

html .text-blue-11 {
  color: #537586;
}

html .text-color-default {
  color: #9a9a9a;
}

/*
*
* Offsets
*/
*+p {
  margin-top: 16px;
}

.group+hr,
.button+hr {
  margin-top: 15px;
}

h2+p,
.heading-2+p {
  margin-top: 12px;
}

p+h2,
p+.heading-2 {
  margin-top: 15px;
}

h2+h3 {
  margin-top: 10px;
}

h4+hr,
.heading-4+hr {
  margin-top: 15px;
}

h3+p,
.heading-3+p {
  margin-top: 5px;
}

h3+.big,
.heading-3+.big {
  margin-top: 15px;
}

h2+h5,
h2+.heading-5,
.heading-2+h5,
.heading-2+.heading-5 {
  margin-top: 15px;
}

h3+h5,
h3+.heading-5,
.heading-3+h5,
.heading-3+.heading-5 {
  margin-top: 15px;
}

h2+h6,
h2+.heading-6,
.heading-2+h6,
.heading-2+.heading-6 {
  margin-top: 17px;
}

p+p {
  margin-top: 12px;
}

img+p {
  margin-top: 15px;
}

h3+img {
  margin-top: 42px;
}

*+.row {
  margin-top: 30px;
}

*+.big {
  margin-top: 20px;
}

*+.text-block {
  margin-top: 30px;
}

*+.button {
  margin-top: 30px;
}

*+.group {
  margin-top: 30px;
}

*+.list-inline-sm {
  margin-top: 18px;
}

*+.list-0 {
  margin-top: 10px;
}

*+.rd-mailform {
  margin-top: 30px;
}

*+.block-form {
  margin-top: 50px;
}

*+.heading-decorated_1 {
  margin-top: 25px;
}

*+.owl-carousel {
  margin-top: 30px;
}

.row+h3,
.row+.heading-3 {
  margin-top: 80px;
}

h2+.button-lg,
.heading-2+.button-lg {
  margin-top: 20px;
}

hr+.rd-form {
  margin-top: 35px;
}

.heading-8+hr {
  margin-top: 5px;
}

.heading-9+hr {
  margin-top: 10px;
}

hr+.list {
  margin-top: 17px;
}

hr+.form-wrap {
  margin-top: 18px;
}

hr+.text-style-1 {
  margin-top: 24px;
}

hr+.row {
  margin-top: 18px;
}

.container+.container {
  margin-top: 35px;
}

.row+.row {
  margin-top: 35px;
}

.form-wrap+.list {
  margin-top: 16px;
}

.big+.button-lg {
  margin-top: 35px;
}

html .page *+.offset-top-1 {
  margin-top: 50px;
}

html .page *+.offset-top-2 {
  margin-top: 35px;
}

html .page *+.offset-top-30px {
  margin-top: 30px;
}

@media (min-width: 768px) {
  p+p {
    margin-top: 24px;
  }

  h2+h5,
  h2+.heading-5,
  .heading-2+h5,
  .heading-2+.heading-5 {
    margin-top: 25px;
  }

  h3+.big,
  .heading-3+.big {
    margin-top: 30px;
  }

  .group+hr,
  .button+hr {
    margin-top: 30px;
  }

  hr+.group {
    margin-top: 30px;
  }

  hr+.button {
    margin-top: 50px;
  }

  *+.row {
    margin-top: 45px;
  }

  *+.group {
    margin-top: 40px;
  }

  *+.block-form {
    margin-top: 65px;
  }

  *+.heading-decorated_1 {
    margin-top: 35px;
  }

  *+.owl-carousel {
    margin-top: 40px;
  }

  html .page *+.offset-top-1 {
    margin-top: 70px;
  }

  html .page *+.offset-top-2 {
    margin-top: 45px;
  }
}

@media (min-width: 1200px) {
  h2+h3 {
    margin-top: 15px;
  }

  *+.rd-form {
    margin-top: 30px;
  }

  .container+.container {
    margin-top: 60px;
  }

  .row+.row {
    margin-top: 60px;
  }

  h2+.button-lg,
  .heading-2+.button-lg {
    margin-top: 45px;
  }
}

@media (min-width: 1600px) {
  html .page *+.offset-top-1 {
    margin-top: 90px;
  }
}

.row:empty {
  margin-bottom: 0;
}

.row-0 {
  margin-bottom: 0;
}

.row-0>* {
  margin-bottom: 0;
}

.row-15 {
  margin-bottom: -15px;
}

.row-15>* {
  margin-bottom: 15px;
}

.row-20 {
  margin-bottom: -20px;
}

.row-20>* {
  margin-bottom: 20px;
}

.row-30 {
  margin-bottom: -30px;
}

.row-30>* {
  margin-bottom: 30px;
}

.row-40 {
  margin-bottom: -40px;
}

.row-40>* {
  margin-bottom: 40px;
}

.row-50 {
  margin-bottom: -50px;
}

.row-50>* {
  margin-bottom: 50px;

}

@media (max-width: 750px) {
  .jobsOverflow {
    overflow: scroll;
  }
}

@media screen and (min-width : 992px) and (max-width: 1200px) {
  .jobsOverflow {
    overflow: scroll;
  }
}


@media (min-width: 992px) {
  .row-lg-50 {
    margin-bottom: -50px;
  }

  .row-lg-50>* {
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .row-xl-70 {
    margin-bottom: -70px;
  }

  .row-xl-70>* {
    margin-bottom: 70px;
  }
}

/*
*
* Sections
*/
.section-xs {
  padding: 25px 0;
}

.section-xs.section-first {
  padding-top: 40px;
}

.section-xs.section-last {
  padding-bottom: 40px;
}

.section-sm,
.section-md,
.section-lg,
.section-xl {
  padding: 50px 0;
}

.section-collapse+.section-collapse {
  padding-top: 0;
}

.section-collapse:last-child {
  padding-bottom: 0;
}

.section-2 {
  padding: 30px 0;
}

html [class*='section-'].section-bottom-0 {
  padding-bottom: 0;
}

@media (max-width: 991px) {

  section.section-sm:first-of-type,
  section.section-md:first-of-type,
  section.section-lg:first-of-type,
  section.section-xl:first-of-type,
  section.section-xxl:first-of-type {
    padding-top: 40px;
  }
}

@media (min-width: 768px) {
  .section-xs.section-first {
    padding-top: 60px;
  }

  .section-xs.section-last {
    padding-bottom: 60px;
  }

  .section-sm {
    padding: 60px 0;
  }

  .section-sm.section-first {
    padding-top: 80px;
  }

  .section-sm.section-last {
    padding-bottom: 80px;
  }

  .section-md {
    padding: 70px 0;
  }

  .section-lg {
    padding: 85px 0;
  }

  .section-2 {
    padding: 50px 0;
  }
}

@media (min-width: 992px) {
  .section-sm {
    padding: 56px 0 56px;
  }

  .section-md {
    padding: 90px 0 95px;
  }

  .section-lg {
    padding: 100px 0;
  }

  .section-1 {
    padding: 25px 0;
  }

  .section-2 {
    padding: 70px 0;
  }
}

@media (min-width: 1200px) {
  .section-sm.section-first {
    padding-top: 90px;
  }

  .section-sm.section-last {
    padding-bottom: 90px;
  }

  .section-lg {
    padding: 115px 0;
  }
}

.section-single {
  display: flex;
  text-align: center;
}

.section-single .rd-mailform-wrap {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.section-single *+.rights {
  margin-top: 35px;
}

.section-single *+.countdown-wrap {
  margin-top: 35px;
}

.section-single *+.rd-mailform-wrap {
  margin-top: 30px;
}

.section-single .countdown-wrap+* {
  margin-top: 20px;
}

@media (min-width: 992px) {
  .section-single *+.rights {
    margin-top: 60px;
  }

  .section-single *+.countdown-wrap {
    margin-top: 20px;
  }

  .section-single *+.rd-mailform-wrap {
    margin-top: 40px;
  }

  .section-single .countdown-wrap+* {
    margin-top: 25px;
  }
}

.section-single-header {
  padding: calc(1em + 3vh) 0 calc(1em + 2vh);
}

.section-single-main {
  padding: calc(1em + 4vh) 0;
}

.section-single-footer {
  padding: calc(1em + 2vh) 0 calc(1em + 3vh);
}

.section-single-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
}

.section-single-inner>* {
  width: 100%;
}

@supports (display: grid) {
  .section-single-inner {
    display: grid;
    justify-items: center;
    align-content: space-between;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1600px) {
  .section-single-header {
    padding-top: 100px;
    padding-bottom: 0;
  }

  .section-single-footer {
    padding-top: 0;
    padding-bottom: 60px;
  }
}

/*
*
* Grid modules
*/
.grid-demonstration {
  padding: 12px 8px;
  letter-spacing: 0;
  text-align: left;
  color: #151515;
}

.grid-demonstration .gd-title {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 20px;
  letter-spacing: 0.05em;
}

@media (max-width: 1199px) {
  .grid-demonstration .gd-title {
    font-size: 14px;
  }
}

@media (max-width: 1599px) {
  .grid-demonstration p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 991px) {
  .container-fluid.grid-demonstration .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.container-fluid.grid-demonstration [class^='col'] {
  padding: 5px;
}

@media (max-width: 991px) {
  .container-fluid.grid-demonstration [class^='col'] p {
    font-size: 14px;
  }

  .container-fluid.grid-demonstration [class^='col'] *+p {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .container-fluid.grid-demonstration [class^='col'] {
    padding: 15px 10px;
  }
}

@media (min-width: 992px) {
  .container-fluid.grid-demonstration [class^='col'] {
    padding: 25px 15px;
  }
}

@media (min-width: 1200px) {
  .container-fluid.grid-demonstration [class^='col'] {
    padding: 30px 15px 30px 30px;
  }
}

@media (min-width: 1600px) {
  .container-fluid.grid-demonstration [class^='col'] {
    padding: 50px 100px;
  }

  .container-fluid.grid-demonstration [class^='col'] *+p {
    margin-top: 24px;
  }
}

@media (min-width: 1600px) {
  .container-fluid.grid-demonstration .row {
    margin-left: -70px;
    margin-right: -70px;
  }
}

*+.grid-demonstration {
  margin-top: 30px;
}

.grid-system-bordered [class*='col'] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.grid-system-bordered .row {
  border-bottom: 1px solid #e0e0e0;
}

@media (min-width: 768px) {
  .grid-system-bordered [class*='col'] {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .grid-system-bordered [class*='col'] {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.grid-system-outline .row {
  border: 1px solid #e0e0e0;
}

.grid-system-outline .row:not(:last-child) {
  border-bottom-width: 0;
}

.grid-system-outline [class*='col']:not(:last-child) {
  border-right: 1px solid #e0e0e0;
  margin-right: -1px;
}

.grid-system-outline *+.row {
  margin-top: 45px;
}

.grid-system-outline .row+.row {
  margin-top: 0;
}

@media (min-width: 1600px) {
  .container-wide {
    max-width: 1800px;
  }
}

@media (min-width: 1200px) {
  .container-fullwidth {
    max-width: 100%;
  }
}

@media (min-width: 1600px) {
  .container-fullwidth {
    max-width: 1870px;
  }
}

.row-x-14 {
  margin-left: -7px;
  margin-right: -7px;
}

.row-x-14>[class*='col'] {
  padding-left: 7px;
  padding-right: 7px;
}

@media (min-width: 576px) {
  .row-bordered .col-sm-6:not(.col-md-4) {
    border-left: 1px solid #e0e0e0;
  }

  .row-bordered .col-sm-6:not(.col-md-4):nth-child(2n + 1) {
    border-left: 0;
  }
}

@media (min-width: 768px) {
  .row-bordered .col-md-4 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .row-bordered .col-md-4 {
    border-left: 1px solid #e0e0e0;
  }

  .row-bordered .col-md-4:nth-child(3n + 1) {
    border-left: 0;
  }
}

.row-bordered-1-outer {
  position: relative;
  overflow: hidden;
}

.row-bordered-1 {
  margin-top: -38px;
  margin-bottom: -38px;
}

.row-bordered-1>[class*='col'] {
  padding: 38px 20px;
}

@media (max-width: 575px) {
  .row-bordered-1>[class*='col']:nth-child(n + 2)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 30px;
    right: 30px;
    border-top: 1px solid #d6d6d6;
  }
}

@media (min-width: 576px) {
  .row-bordered-1 .col-sm-6 {
    position: relative;
  }

  .row-bordered-1 .col-sm-6:nth-child(n + 3)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 30px;
    right: 30px;
    border-top: 1px solid #d6d6d6;
  }

  .row-bordered-1 .col-sm-6:nth-child(2n)::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 1px solid #d6d6d6;
  }
}

.row-narrow {
  margin-left: -10px;
  margin-right: -10px;
}

.row-narrow>[class*='col'] {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1200px) {
  .row-x-xl-50 {
    margin-left: -25px;
    margin-right: -25px;
  }

  .row-x-xl-50>[class*='col'] {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/*
*
* Backgrounds
*/
.context-dark,
.bg-gray-700,
.bg-blue-13,
.bg-blue-15,
.bg-primary,
.context-dark h1,
.bg-gray-700 h1,
.bg-blue-13 h1,
.bg-blue-15 h1,
.bg-primary h1,
.context-dark h2,
.bg-gray-700 h2,
.bg-blue-13 h2,
.bg-blue-15 h2,
.bg-primary h2,
.context-dark h3,
.bg-gray-700 h3,
.bg-blue-13 h3,
.bg-blue-15 h3,
.bg-primary h3,
.context-dark h4,
.bg-gray-700 h4,
.bg-blue-13 h4,
.bg-blue-15 h4,
.bg-primary h4,
.context-dark h5,
.bg-gray-700 h5,
.bg-blue-13 h5,
.bg-blue-15 h5,
.bg-primary h5,
.context-dark h6,
.bg-gray-700 h6,
.bg-blue-13 h6,
.bg-blue-15 h6,
.bg-primary h6,
.context-dark [class*='heading-'],
.bg-gray-700 [class*='heading-'],
.bg-blue-13 [class*='heading-'],
.bg-blue-15 [class*='heading-'],
.bg-primary [class*='heading-'] {
  color: #ffffff;
}

.context-dark hr,
.bg-gray-700 hr,
.bg-blue-13 hr,
.bg-blue-15 hr,
.bg-primary hr {
  border-top-color: rgba(255, 255, 255, 0.13);
}

.context-dark .brand .brand-logo-dark,
.bg-gray-700 .brand .brand-logo-dark,
.bg-blue-13 .brand .brand-logo-dark,
.bg-blue-15 .brand .brand-logo-dark,
.bg-primary .brand .brand-logo-dark {
  display: none;
}

.context-dark .brand .brand-logo-light,
.bg-gray-700 .brand .brand-logo-light,
.bg-blue-13 .brand .brand-logo-light,
.bg-blue-15 .brand .brand-logo-light,
.bg-primary .brand .brand-logo-light {
  display: block;
}

.context-dark .rights a,
.bg-gray-700 .rights a,
.bg-blue-13 .rights a,
.bg-blue-15 .rights a,
.bg-primary .rights a {
  color: inherit;
}

.context-dark .big a:hover,
.bg-gray-700 .big a:hover,
.bg-blue-13 .big a:hover,
.bg-blue-15 .big a:hover,
.bg-primary .big a:hover {
  color: #69e08c;
}

/**
* Light Backgrounds
*/
.bg-default {
  background-color: #ffffff;
}

.bg-default+.bg-default {
  padding-top: 0;
}

.bg-gray-100 {
  background-color: #f3f4f9;
}

.bg-gray-100+.bg-gray-100 {
  padding-top: 0;
}

.bg-gray-200 {
  background-color: #e0e0e0;
}

.bg-gray-200+.bg-gray-200 {
  padding-top: 0;
}

/**
* Dark Backgrounds
*/
.bg-gray-700 {
  background-color: #2c343b;
}

.bg-gray-700+.bg-gray-700 {
  padding-top: 0;
}

.bg-blue-13 {
  background-color: #3b494c;
}

.bg-blue-13+.bg-blue-13 {
  padding-top: 0;
}

.bg-blue-15 {
  background-color: #45556c;
}

.bg-blue-15+.bg-blue-15 {
  padding-top: 0;
}

/** 
* Accent Backgrounds
*/
.bg-primary+.bg-primary {
  padding-top: 0;
}

/**
* Background Image
*/
[class*='bg-'] {
  background-size: cover;
  background-position: center center;
}

[class*='bg-overlay'] {
  position: relative;
}

[class*='bg-overlay']::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

[class*='bg-overlay']>* {
  position: relative;
  z-index: 1;
}

.bg-overlay-1::before {
  background: rgba(46, 67, 91, 0.54);
}

.bg-overlay-2::before {
  background: rgba(60, 74, 84, 0.93);
}

.bg-overlay-3::before {
  background: rgba(22, 47, 69, 0.43);
}

.bg-overlay-4::before {
  background: rgba(22, 47, 69, 0.84);
}

.bg-overlay-5::before {
  background: rgba(65, 78, 90, 0.37);
}

.bg-overlay-6::before {
  background: rgba(72, 91, 120, 0.93);
}

.bg-overlay-7::before {
  background: rgba(52, 73, 94, 0.75);
}

.bg-overlay-gradient-1::before {
  background: linear-gradient(to right, #3d485d 0%, transparent 100%);
  opacity: 0.522;
}

@media (min-width: 992px) {
  html:not(.tablet):not(.mobile) .bg-fixed {
    background-attachment: fixed;
  }
}

.bg-1 {
  /* background: #49555e url(../images/bg-blue-4-noise.jpg) repeat; */
  padding-bottom: 10px;
}

@media (min-width: 576px) {
  .bg-1 {
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .bg-1 {
    padding-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .bg-1 {
    padding-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .bg-1 {
    padding-bottom: 80px;
  }
}

/*
*
* Utilities custom
*/
.height-fill {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.height-fill>* {
  flex-grow: 1;
  flex-shrink: 0;
}

.object-inline {
  white-space: nowrap;
}

.object-inline>* {
  display: inline-block;
  min-width: 20px;
  vertical-align: top;
  white-space: normal;
}

.object-inline>*+* {
  margin-left: 5px;
}

.object-inline .icon-1,
.object-inline .icon-default {
  position: relative;
  top: 2px;
}

.object-inline a {
  color: inherit;
}

.object-inline a:hover {
  color: #52be71;
}

.object-inline_sm>*+* {
  margin-left: 2px;
}

.oh {
  position: relative;
  overflow: hidden;
}

.text-width-small {
  max-width: 320px;
}

.text-decoration-lines {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.text-decoration-lines-content {
  position: relative;
  display: inline-block;
  min-width: 170px;
  font-size: 13px;
  text-transform: uppercase;
}

.text-decoration-lines-content::before,
.text-decoration-lines-content::after {
  content: '';
  position: absolute;
  height: 1px;
  background: #e0e0e0;
  top: 50%;
  width: 100vw;
}

.text-decoration-lines-content::before {
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

.text-decoration-lines-content::after {
  right: 0;
  transform: translate3d(100%, 0, 0);
}

*+.text-decoration-lines {
  margin-top: 30px;
}

.text-decoration-lines+.rd-form {
  margin-top: 17px;
}

.rights a {
  text-decoration: underline;
}

.rights a:hover {
  text-decoration: none;
}

.text-block>* {
  margin-left: 0.125em;
  margin-right: 0.125em;
}

.tip-mark {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0.2em;
  width: 2em;
  height: 2em;
  padding-left: 2px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 2em;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #e0e0e0;
  color: #52be71;
  cursor: pointer;
  user-select: none;
}

.context-dark .tip-mark,
.bg-gray-700 .tip-mark,
.bg-blue-13 .tip-mark,
.bg-blue-15 .tip-mark,
.bg-primary .tip-mark {
  color: #ffffff;
}

.phone-frame-outer {
  position: relative;
  z-index: 1;
  height: 350px;
}

.phone-frame {
  position: relative;
  overflow: hidden;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

.phone-frame-reflection {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
}

.phone-frame-image-outer {
  position: absolute;
  top: 11.8%;
  right: 8.7%;
  bottom: 15.2%;
  left: 8.1%;
  background: #151515;
  background-position: center center;
  background-size: cover;
}

.phone-frame-logo {
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  max-width: 80%;
}

.placeholder-1 {
  height: 80px;
}

.inset-left-1 {
  padding-left: 20px;
}

@media (min-width: 768px) {
  .inset-left-1 {
    padding-left: 63px;
  }
}

.promo-search {
  position: relative;
  overflow: hidden;
  padding: 20px 10px 30px;
  background: #f3f4f9;
  text-align: center;
}

.promo-search-icon-outer {
  position: absolute;
}

.promo-search-icon {
  width: auto !important;
  height: auto !important;
  min-height: 100%;
  fill: #e0e1e9;
}

.promo-search-main {
  position: relative;
  z-index: 1;
  margin-bottom: -15px;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

.promo-search-main>* {
  margin-bottom: 15px;
}

.promo-search-main .button {
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: 575px) {
  .promo-search-icon-outer {
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

@media (min-width: 576px) {
  .promo-search-icon-outer {
    position: absolute;
    left: 0;
    top: -6px;
    bottom: -23px;
  }

  .promo-search-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    max-width: 100%;
    margin-right: 0;
    text-align: left;
    margin-left: -15px;
  }

  .promo-search-main>* {
    margin-left: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .promo-search-title {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .promo-search {
    padding: 20px;
  }

  .promo-search-main {
    padding-left: 90px;
  }
}

@media (min-width: 992px) {
  .promo-search-main {
    padding-left: 105px;
  }
}

@media (min-width: 1200px) {
  .promo-search {
    padding: 30px;
  }

  .promo-search-main {
    padding-left: 125px;
  }
}

/*
*
* Animate.css
*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 1;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

html:not(.lt-ie10) .not-animated {
  opacity: 0;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

/*
*
* Preloader
*/
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #404851;
  transition: 0.3s all ease;
}

.preloader.loaded {
  opacity: 0;
  visibility: hidden;
}

.preloader-body {
  text-align: center;
}

.preloader-item {
  display: block;
  margin-top: -4%;
}

.preloader-item .rect {
  fill: none;
  stroke-width: 8px;
  stroke: #ffffff;
  stroke-dasharray: 40;
  stroke-dashoffset: 50%;
  animation: movedash 1.2s ease-in-out forwards 0s infinite;
}

@keyframes movedash {
  to {
    stroke-dashoffset: 250%;
  }
}

/*
*
* ToTop
*/
.ui-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 100;
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 46px;
  color: #ffffff;
  background: #52be71;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  transition: 0.45s all ease-in-out;
  transform: translate3d(0, 100px, 0);
}

.ui-to-top:hover {
  color: #ffffff;
  background: #e69564;
  text-decoration: none;
}

.ui-to-top:focus {
  color: #ffffff;
}

.ui-to-top.active {
  transform: translate3d(0, 0, 0);
}

html.mobile .ui-to-top,
html.tablet .ui-to-top {
  display: none !important;
}

@media (min-width: 576px) {
  .ui-to-top {
    right: 40px;
    bottom: 40px;
  }
}

/*
*
* RD Navbar
*/
@keyframes rd-navbar-slide-down {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes rd-navbar-slide-up {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.rd-navbar-wrap,
.rd-navbar,
.rd-menu,
.rd-navbar-nav,
.rd-navbar-panel,
.rd-navbar-static .rd-menu,
.rd-navbar-fixed .rd-navbar-nav-wrap,
.rd-navbar-fixed .rd-navbar-submenu {
  transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
}

.rd-navbar--no-transition,
.rd-navbar--no-transition * {
  transition: none !important;
}

.rd-navbar,
.rd-navbar.rd-navbar--is-clone {
  display: none;
}

.rd-navbar.rd-navbar-fixed+.rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar+.rd-navbar.rd-navbar--is-clone {
  display: none;
}

.rd-navbar {
  background: #ffffff;
}

.rd-navbar-toggle {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  color: #2c343b;
  background-color: transparent;
  border: none;
  display: none;
}

.rd-navbar-toggle span {
  position: relative;
  display: block;
  margin: auto;
  transition: 0.3s all ease;
}

.rd-navbar-toggle span::after,
.rd-navbar-toggle span::before {
  content: '';
  position: absolute;
  left: 0;
  top: -6px;
  transition: 0.3s all ease;
}

.rd-navbar-toggle span::after {
  top: 6px;
}

.rd-navbar-toggle span::after,
.rd-navbar-toggle span::before,
.rd-navbar-toggle span {
  width: 24px;
  height: 2px;
  background-color: #2c343b;
  backface-visibility: hidden;
  border-radius: 0;
}

.rd-navbar-toggle span::before,
.rd-navbar-toggle span::after {
  transform-origin: 1px center;
}

.rd-navbar-toggle.active span::before,
.rd-navbar-toggle.active span::after {
  top: 0;
  width: 15px;
}

.rd-navbar-toggle.active span::before {
  transform: rotate(-40deg);
}

.rd-navbar-toggle.active span::after {
  transform: rotate(40deg);
}

.rd-navbar-collapse-toggle {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  color: #151515;
  display: none;
}

.rd-navbar-collapse-toggle span {
  top: 50%;
  margin-top: -2.5px;
}

.rd-navbar-collapse-toggle span,
.rd-navbar-collapse-toggle span::before,
.rd-navbar-collapse-toggle span::after {
  position: absolute;
  width: 5px;
  height: 5px;
  line-height: 5px;
  text-align: center;
  background: #151515;
  left: 50%;
  margin-left: -2.5px;
  border-radius: 50%;
  transition: 0.3s all ease;
}

.rd-navbar-collapse-toggle span::before,
.rd-navbar-collapse-toggle span::after {
  content: '';
}

.rd-navbar-collapse-toggle span::before {
  bottom: 100%;
  margin-bottom: 2.5px;
}

.rd-navbar-collapse-toggle span::after {
  top: 100%;
  margin-top: 2.5px;
}

.rd-navbar-collapse-toggle.active span {
  transform: scale(0.7);
}

.rd-navbar-collapse-toggle.active span::before {
  transform: translateY(15px);
}

.rd-navbar-collapse-toggle.active span::after {
  transform: translateY(-15px);
}

.rd-navbar-brand a {
  display: block;
}

.rd-nav-link {
  font: 500 13px/20px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.rd-navbar-dropdown {
  display: none;
}

.rd-megamenu-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.025em;
}

.rd-navbar-popup {
  position: absolute;
  right: 0;
  z-index: 1030;
  padding: 15px 25px 20px 20px;
  width: calc(100vw - 10px);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  overflow: hidden;
  border: 1px solid #e0e0e0;
}

.rd-navbar-popup>* {
  position: relative;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  transform-origin: 50% 0;
  transform: scale3d(1, 0.95, 1);
}

.rd-navbar-popup.active {
  opacity: 1;
  visibility: visible;
}

.rd-navbar-popup.active>* {
  opacity: 1;
  visibility: visible;
  transform: scale3d(1, 1, 1);
}

.rd-navbar-popup .rd-navbar-popup-title {
  color: #151515;
}

@media (min-width: 400px) {
  .rd-navbar-popup {
    width: 370px;
  }
}

@media (min-width: 768px) {
  .rd-navbar-popup {
    padding: 30px;
  }
}

/*
* @subsection  RD Navbar Static
*/
.rd-navbar-static {
  display: block;
}

.rd-navbar-static .rd-nav-item {
  display: inline-block;
}

.rd-navbar-static .rd-nav-item.focus .rd-nav-link,
.rd-navbar-static .rd-nav-item.opened .rd-nav-link {
  color: #52be71;
  background: transparent;
}

.rd-navbar-static .rd-nav-item.focus>.rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item.opened>.rd-navbar-submenu-toggle {
  color: #52be71;
}

.rd-navbar-static .rd-nav-item.active .rd-nav-link {
  color: #52be71;
  background: transparent;
}

.rd-navbar-static .rd-nav-item.active>.rd-navbar-submenu-toggle {
  color: #52be71;
}

.rd-navbar-static .rd-nav-item.focus>.rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item.opened>.rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item .rd-nav-link:hover+.rd-navbar-submenu-toggle {
  color: #52be71;
}

.rd-navbar-static .rd-nav-item.focus>.rd-navbar-submenu-toggle::before,
.rd-navbar-static .rd-nav-item.opened>.rd-navbar-submenu-toggle::before,
.rd-navbar-static .rd-nav-item .rd-nav-link:hover+.rd-navbar-submenu-toggle::before {
  transform: rotate(180deg);
}

.rd-navbar-static .rd-nav-item>.rd-navbar-submenu-toggle {
  font-family: 'Material Design Icons';
  font-size: 18px;
  cursor: pointer;
  color: #151515;
}

.rd-navbar-static .rd-nav-item>.rd-navbar-submenu-toggle::before {
  content: '\f236';
  position: relative;
  top: 2px;
  display: inline-block;
  transition: 0.22s;
}

.rd-navbar-static .rd-nav-item>.rd-navbar-submenu {
  margin-top: 20px;
}

.rd-navbar-static .rd-nav-item+.rd-nav-item {
  margin-left: 25px;
}

.rd-navbar-static .rd-nav-link {
  position: relative;
  display: inline-block;
  color: #151515;
  transition: 0.25s;
}

.rd-navbar-static .rd-nav-link:hover {
  color: #52be71;
}

.rd-navbar-static .rd-menu {
  z-index: 15;
  position: absolute;
  display: block;
  visibility: hidden;
  opacity: 0;
  text-align: left;
  box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.13);
  transform: translate3d(0, 10px, 0);
}

.rd-navbar-static .rd-nav-item>.rd-navbar-dropdown::before {
  content: '';
  position: absolute;
  top: -12px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 0 18px;
  border-color: transparent transparent transparent #ffffff;
}

.rd-navbar-static .rd-navbar-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  padding: 17px 30px 23px;
  background: #ffffff;
  z-index: 5;
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown {
  top: -1px;
  left: 100%;
  z-index: 2;
  margin-top: -15px;
  margin-left: 15px;
  transform: translate3d(10px, 0, 0);
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown.rd-navbar-open-left {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: 15px;
  transform: translate3d(-10px, 0, 0);
}

.rd-navbar-static .rd-dropdown-item.focus .rd-dropdown-link,
.rd-navbar-static .rd-dropdown-item.opened .rd-dropdown-link {
  color: #52be71;
}

.rd-navbar-static .rd-dropdown-link {
  color: #151515;
}

.rd-navbar-static .rd-dropdown-link:hover {
  color: #52be71;
}

.rd-navbar-static .rd-navbar-dropdown li>a,
.rd-navbar-static .rd-megamenu-list li>a {
  position: relative;
  display: flex;
  padding: 2px 0 2px 17px;
  text-align: left;
}

.rd-navbar-static .rd-navbar-dropdown li>a,
.rd-navbar-static .rd-navbar-dropdown li>a:focus,
.rd-navbar-static .rd-navbar-dropdown li>a:active,
.rd-navbar-static .rd-megamenu-list li>a,
.rd-navbar-static .rd-megamenu-list li>a:focus,
.rd-navbar-static .rd-megamenu-list li>a:active {
  color: #151515;
}

.rd-navbar-static .rd-navbar-dropdown li>a:hover,
.rd-navbar-static .rd-megamenu-list li>a:hover {
  color: #52be71;
}

.rd-navbar-static .rd-navbar-dropdown li>a,
.rd-navbar-static .rd-navbar-dropdown li>a::before,
.rd-navbar-static .rd-megamenu-list li>a,
.rd-navbar-static .rd-megamenu-list li>a::before {
  transition: 0.15s ease-in-out;
}

.rd-navbar-static .rd-navbar-dropdown li>a::before,
.rd-navbar-static .rd-megamenu-list li>a::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  display: inline-block;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-style: solid;
  border-width: 4px 0 4px 10px;
  border-color: transparent transparent transparent #b7b7b7;
}

.rd-navbar-static .rd-navbar-dropdown li.focus>a::before,
.rd-navbar-static .rd-navbar-dropdown li.opened>a::before,
.rd-navbar-static .rd-navbar-dropdown li>a:hover::before,
.rd-navbar-static .rd-megamenu-list li.focus>a::before,
.rd-navbar-static .rd-megamenu-list li.opened>a::before,
.rd-navbar-static .rd-megamenu-list li>a:hover::before {
  border-left-color: #52be71;
}

.rd-navbar-static .rd-navbar-dropdown li+li,
.rd-navbar-static .rd-megamenu-list li+li {
  margin-top: 8px;
}

.rd-navbar-static .rd-navbar-megamenu {
  display: flex;
  top: 100%;
  left: 0;
  width: 100%;
  max-width: 1170px;
  padding: 30px 40px;
  background: #ffffff;
}

.rd-navbar-static .rd-megamenu-item {
  flex-basis: 50%;
  flex-grow: 1;
}

.rd-navbar-static .rd-megamenu-item+.rd-megamenu-item {
  padding-left: 20px;
}

.rd-navbar-static .rd-megamenu-list-link {
  color: #000000;
  background: transparent;
}

.rd-navbar-static .rd-megamenu-list-link:hover {
  color: #52be71;
  background: transparent;
}

.rd-navbar-static *+.rd-megamenu-list {
  margin-top: 17px;
}

.rd-navbar-static .rd-navbar-submenu.focus>.rd-menu,
.rd-navbar-static .rd-navbar-submenu.opened>.rd-menu {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 1199px) {
  .rd-navbar-static .rd-nav-link {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .rd-navbar-static .rd-nav-item+.rd-nav-item {
    margin-left: 30px;
  }
}

@media (min-width: 1600px) {
  .rd-navbar-static .rd-navbar-megamenu {
    padding: 30px 52px;
  }

  .rd-navbar-static .rd-megamenu-item+.rd-megamenu-item {
    margin-left: 56px;
  }
}

.rd-navbar-static.rd-navbar--is-clone {
  display: block;
  transform: translate3d(0, -100%, 0);
}

.rd-navbar-static.rd-navbar--is-clone.rd-navbar--is-stuck {
  transform: translate3d(0, 0, 0);
}

.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-static.rd-navbar--is-clone {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1030;
  background: #ffffff;
}

.rd-navbar-static .rd-navbar--has-dropdown {
  position: relative;
}

.context-dark .rd-navbar-static.rd-navbar--is-stuck,
.bg-gray-700 .rd-navbar-static.rd-navbar--is-stuck,
.bg-blue-13 .rd-navbar-static.rd-navbar--is-stuck,
.bg-blue-15 .rd-navbar-static.rd-navbar--is-stuck,
.bg-primary .rd-navbar-static.rd-navbar--is-stuck {
  background: #2c343b;
}

/*
*
* RD Navbar Fixed
*/
.rd-navbar-fixed {
  display: block;
}

.rd-navbar-fixed .rd-navbar-toggle {
  display: inline-block;
}

.rd-navbar-fixed .rd-navbar-brand {
  position: relative;
  margin-left: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 22px;
  line-height: 46px;
}

.rd-navbar-fixed .rd-navbar-brand img {
  max-width: 160px;
  max-height: 58px;
  width: auto;
  height: auto;
}

@media (max-width: 374px) {
  .rd-navbar-fixed .rd-navbar-brand img {
    max-width: 140px;
  }
}

.rd-navbar-fixed .rd-navbar-panel {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1030;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 8px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
  position: fixed;
  left: 0;
  top: -56px;
  bottom: -56px;
  z-index: 1029;
  width: 270px;
  padding: 112px 0 81px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 14px;
  line-height: 34px;
  transform: translateX(-110%);
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar {
  width: 4px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-thumb {
  background: #9cdaae;
  border: none;
  border-radius: 0;
  opacity: 0.2;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-track {
  background: #52be71;
  border: none;
  border-radius: 0;
}

.rd-navbar-fixed .rd-navbar-nav-wrap.active {
  transform: translateX(0);
}

.rd-navbar-fixed .rd-navbar-nav {
  display: block;
  margin: 15px 0;
  height: auto;
  text-align: left;
}

.rd-navbar-fixed .rd-nav-item {
  text-align: left;
}

.rd-navbar-fixed .rd-nav-item+.rd-nav-item {
  margin-top: 4px;
}

.rd-navbar-fixed .rd-nav-link {
  display: block;
  padding: 14px 56px 14px 18px;
}

.rd-navbar-fixed li.opened>.rd-navbar-dropdown {
  padding: 4px 0 0;
}

.rd-navbar-fixed li.opened>.rd-navbar-megamenu {
  padding-top: 15px;
  padding-bottom: 15px;
}

.rd-navbar-fixed li.opened>.rd-menu {
  opacity: 1;
  height: auto;
}

.rd-navbar-fixed li.opened>.rd-navbar-submenu-toggle::after {
  top: -3px;
  transform: rotate(180deg);
}

.rd-navbar-fixed .rd-menu {
  display: none;
  transition: opacity 0.3s, height 0.4s ease;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.rd-navbar-fixed .rd-navbar-submenu {
  position: relative;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown .rd-navbar-submenu-toggle:after {
  height: 34px;
  line-height: 34px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown>li>a {
  padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown li li>a,
.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-megamenu ul li li>a {
  padding-left: 46px;
}

.rd-navbar-fixed .rd-navbar-submenu.opened>.rd-navbar-dropdown,
.rd-navbar-fixed .rd-navbar-submenu.opened>.rd-navbar-megamenu {
  display: block;
}

.rd-navbar-fixed .rd-megamenu-list>li>a,
.rd-navbar-fixed .rd-navbar-dropdown>li>a {
  display: block;
  padding: 9px 56px 9px 16px;
  font-size: 12px;
  line-height: 1.5;
}

.rd-navbar-fixed .rd-megamenu-list>li+li,
.rd-navbar-fixed .rd-navbar-dropdown>li+li {
  margin-top: 3px;
}

.rd-navbar-fixed .rd-megamenu-list>li>a {
  padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title {
  position: relative;
  display: block;
  padding: 0 18px 7px;
  font-size: 15px;
}

.rd-navbar-fixed .rd-navbar-megamenu *+.rd-megamenu-list {
  margin-top: 11px;
}

.rd-navbar-fixed .rd-navbar-megamenu *+.rd-megamenu-title {
  margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-megamenu>li+li {
  margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
  cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle::after {
  content: '\f107';
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: 52px;
  font: 400 14px 'FontAwesome';
  line-height: 48px;
  text-align: center;
  transition: 0.4s all ease;
  z-index: 2;
  cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-collapse-toggle {
  display: block;
  top: 8px;
  z-index: 1031;
}

.rd-navbar-fixed .rd-navbar-collapse {
  position: fixed;
  right: 0;
  top: 56px;
  z-index: 1029;
  transform: translate3d(0, -10px, 0);
  padding: 15px;
  width: auto;
  border-radius: 0;
  text-align: left;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.rd-navbar-fixed .rd-navbar-collapse.active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

.rd-navbar-fixed .rd-navbar-collapse .list-inline>li {
  display: block;
}

.rd-navbar-fixed .rd-navbar-collapse .list-inline>li+li {
  margin-top: 10px;
}

.rd-navbar-fixed .rd-navbar-main-element {
  position: absolute;
  float: left;
}

.rd-navbar-fixed .rd-navbar-search .rd-search {
  position: fixed;
  right: 0;
  left: 0;
  top: 56px;
  z-index: 1029;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: translate3d(0, -10px, 0);
}

.rd-navbar-fixed .rd-navbar-search .rd-search-results-live {
  display: none;
}

.rd-navbar-fixed .rd-navbar-search .form-input {
  padding-right: 50px;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

.rd-navbar-fixed .rd-navbar-search.active .rd-search {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
  display: inline-flex;
}

.rd-navbar-fixed .rd-navbar-search-toggle span {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.rd-navbar-fixed .rd-navbar-search-toggle span,
.rd-navbar-fixed .rd-navbar-search-toggle span::before,
.rd-navbar-fixed .rd-navbar-search-toggle span::after {
  transition: 0.3s all ease-in-out;
}

.rd-navbar-fixed .rd-navbar-search-toggle span::before,
.rd-navbar-fixed .rd-navbar-search-toggle span::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rd-navbar-fixed .rd-navbar-search-toggle span::before {
  content: '';
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
  font-family: 'Material Design Icons';
}

.rd-navbar-fixed .rd-navbar-search-toggle span::after {
  content: '';
  transform: rotate(-90deg) scale(0.4);
  opacity: 0;
  visibility: hidden;
  font-family: 'Material Design Icons';
}

.rd-navbar-fixed .rd-navbar-search-toggle.active span::before {
  opacity: 0;
  visibility: hidden;
  transform: rotate(90deg) scale(0.4);
}

.rd-navbar-fixed .rd-navbar-search-toggle.active span::after {
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
}

.rd-navbar-fixed [class*='rd-navbar-fixed-element'] {
  position: fixed;
  top: 8px;
  z-index: 1032;
}

.rd-navbar-fixed .rd-navbar-fixed-element-1 {
  right: 0;
}

.rd-navbar-fixed .rd-navbar-fixed-element-2 {
  right: 46px;
}

.rd-navbar-fixed.rd-navbar--is-clone {
  display: none;
}

.rd-navbar-fixed .rd-navbar-fixed--visible {
  display: block;
}

.rd-navbar-fixed .rd-navbar-fixed--hidden {
  display: none;
}

.rd-navbar-fixed .rd-navbar-panel {
  color: #151515;
  box-shadow: none;
  background: #ffffff;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
  color: #ffffff;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: none;
}

.rd-navbar-fixed .rd-nav-item:hover .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.focus .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.active .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.opened .rd-nav-link {
  color: #ffffff;
  background: #52be71;
}

.rd-navbar-fixed .rd-nav-item:hover>.rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.focus>.rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.active>.rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.opened>.rd-navbar-submenu-toggle {
  color: #ffffff;
}

.rd-navbar-fixed .rd-nav-link {
  color: #151515;
}

.rd-navbar-fixed .rd-megamenu-list>li>a,
.rd-navbar-fixed .rd-navbar-dropdown>li>a {
  color: #151515;
}

.rd-navbar-fixed .rd-megamenu-list>li:hover>a,
.rd-navbar-fixed .rd-megamenu-list>li.focus>a,
.rd-navbar-fixed .rd-megamenu-list>li.active>a,
.rd-navbar-fixed .rd-megamenu-list>li.opened>a,
.rd-navbar-fixed .rd-navbar-dropdown>li:hover>a,
.rd-navbar-fixed .rd-navbar-dropdown>li.focus>a,
.rd-navbar-fixed .rd-navbar-dropdown>li.active>a,
.rd-navbar-fixed .rd-navbar-dropdown>li.opened>a {
  color: #ffffff;
  background: #52be71;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title {
  color: #151515;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a,
.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:focus,
.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:active {
  color: inherit;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:hover {
  color: #52be71;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
  color: #151515;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
  color: #d6d6d6;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit:hover {
  color: #52be71;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
  color: #151515;
}

.rd-navbar-fixed .rd-navbar-search-toggle:hover {
  color: #52be71;
}

.rd-navbar-fixed .rd-navbar-collapse {
  background-color: #ffffff;
  box-shadow: 0 0 22px -4px rgba(0, 0, 0, 0.17);
}

.rd-navbar-fixed .rd-navbar-collapse,
.rd-navbar-fixed .rd-navbar-collapse a {
  color: #151515;
}

.context-dark .rd-navbar-fixed .rd-navbar-panel,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-panel,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-panel,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-panel,
.bg-primary .rd-navbar-fixed .rd-navbar-panel {
  background: #2c343b;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  color: #ffffff;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span {
  position: relative;
  display: block;
  margin: auto;
  transition: 0.3s all ease;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::before {
  content: '';
  position: absolute;
  left: 0;
  top: -6px;
  transition: 0.3s all ease;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after {
  top: 6px;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::before,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span {
  width: 24px;
  height: 2px;
  background-color: #ffffff;
  backface-visibility: hidden;
  border-radius: 0;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::before,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after {
  transform-origin: 1px center;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::after {
  top: 0;
  width: 15px;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::before {
  transform: rotate(-40deg);
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::after {
  transform: rotate(40deg);
}

html.rd-navbar-fixed-linked .page {
  padding-top: 56px;
}

/*
*
* RD Navbar Sidebar
*/
.rd-navbar-sidebar {
  display: block;
  background: #ffffff;
}

.rd-navbar-sidebar .rd-navbar-main-outer {
  padding-left: 15px;
  padding-right: 15px;
}

.rd-navbar-sidebar .rd-navbar-main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 60px 22px 0;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.rd-navbar-sidebar .rd-navbar-main-element {
  display: flex;
  align-items: center;
}

.rd-navbar-sidebar .rd-navbar-main-element>*+* {
  margin-left: 20px;
}

.rd-navbar-sidebar .rd-navbar-toggle {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  z-index: 1033;
}

.rd-navbar-sidebar .rd-navbar-panel {
  min-width: 200px;
  text-align: center;
}

.rd-navbar-sidebar .rd-navbar-brand img {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
}

.rd-navbar-sidebar .rd-navbar-nav-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1032;
  width: 450px;
  padding: 94px 0 0 0;
  background: #ffffff;
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateX(100%);
  box-shadow: 0 0 13px 0 rgba(214, 214, 214, 0.16);
}

.rd-navbar-sidebar .rd-navbar-nav-wrap.active {
  transition-delay: 0.1s;
  transform: translateX(0);
}

.rd-navbar-sidebar .rd-navbar-nav {
  height: calc(100vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid #e0e0e0;
}

.rd-navbar-sidebar .rd-nav-item {
  position: relative;
  display: block;
  padding: 19px 30px 19px 40px;
}

.rd-navbar-sidebar .rd-nav-item.active .rd-nav-link {
  color: #52be71;
}

.rd-navbar-sidebar .rd-nav-item .rd-nav-link,
.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle {
  font-size: 18px;
}

.rd-navbar-sidebar .rd-nav-item+.rd-nav-item {
  border-top: 1px solid #e0e0e0;
}

.rd-navbar-sidebar .rd-nav-item:last-child {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 30px;
}

.rd-navbar-sidebar .rd-nav-link {
  max-width: calc(100% - 30px);
  word-break: break-all;
  font-size: 18px;
  line-height: 1.4;
  text-transform: none;
  color: #d6d6d6;
}

.rd-navbar-sidebar .rd-nav-link:hover {
  color: #52be71;
}

.rd-navbar-sidebar .rd-navbar-submenu .opened>.rd-navbar-submenu-toggle::after {
  transform: rotate(180deg);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-megamenu {
  display: none;
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
  transition: opacity 0.2s;
}

.rd-navbar-sidebar .rd-navbar-submenu.opened>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu.opened>.rd-navbar-megamenu {
  display: block;
  opacity: 1;
  visibility: visible;
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu>.rd-navbar-megamenu {
  transform: translateY(30px);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu.opened>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu.opened>.rd-navbar-megamenu {
  transform: translateY(0);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu .rd-navbar-submenu>.rd-navbar-dropdown {
  transform: translateX(-20px);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu .rd-navbar-submenu.opened>.rd-navbar-dropdown {
  transform: translateX(0);
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: inherit;
  padding-right: inherit;
  padding-left: 10px;
  margin-bottom: inherit;
  display: inline-block;
  width: 30px;
  margin-left: 5px;
  text-align: center;
  cursor: pointer;
  color: #151515;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle:hover,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle:hover {
  color: #52be71;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle::after,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle::after {
  content: '\f107';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-size: inherit;
  line-height: inherit;
  text-align: center;
  vertical-align: middle;
  transition: 0.4s all ease;
  z-index: 2;
  will-change: transform;
}

.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle {
  font-size: 14px;
  color: #d6d6d6;
  margin-left: 7px;
}

.rd-navbar-sidebar .rd-menu {
  margin-top: 31px;
}

.rd-navbar-sidebar .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-megamenu-list {
  font-size: 14px;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a,
.rd-navbar-sidebar .rd-megamenu-list li>a {
  position: relative;
  display: flex;
  padding: 2px 0 2px 17px;
  text-align: left;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a,
.rd-navbar-sidebar .rd-navbar-dropdown li>a:focus,
.rd-navbar-sidebar .rd-navbar-dropdown li>a:active,
.rd-navbar-sidebar .rd-megamenu-list li>a,
.rd-navbar-sidebar .rd-megamenu-list li>a:focus,
.rd-navbar-sidebar .rd-megamenu-list li>a:active {
  color: #151515;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a:hover,
.rd-navbar-sidebar .rd-megamenu-list li>a:hover {
  color: #52be71;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a,
.rd-navbar-sidebar .rd-navbar-dropdown li>a::before,
.rd-navbar-sidebar .rd-megamenu-list li>a,
.rd-navbar-sidebar .rd-megamenu-list li>a::before {
  transition: 0.15s ease-in-out;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a::before,
.rd-navbar-sidebar .rd-megamenu-list li>a::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  display: inline-block;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-style: solid;
  border-width: 4px 0 4px 10px;
  border-color: transparent transparent transparent #b7b7b7;
}

.rd-navbar-sidebar .rd-navbar-dropdown li.focus>a::before,
.rd-navbar-sidebar .rd-navbar-dropdown li.opened>a::before,
.rd-navbar-sidebar .rd-navbar-dropdown li>a:hover::before,
.rd-navbar-sidebar .rd-megamenu-list li.focus>a::before,
.rd-navbar-sidebar .rd-megamenu-list li.opened>a::before,
.rd-navbar-sidebar .rd-megamenu-list li>a:hover::before {
  border-left-color: #52be71;
}

.rd-navbar-sidebar .rd-navbar-dropdown li+li,
.rd-navbar-sidebar .rd-megamenu-list li+li {
  margin-top: 8px;
}

.rd-navbar-sidebar .rd-navbar-megamenu {
  max-width: 450px;
  margin-bottom: -30px;
}

.rd-navbar-sidebar .rd-navbar-megamenu>* {
  margin-bottom: 30px;
}

.rd-navbar-sidebar .rd-navbar-megamenu>li {
  display: inline-block;
  vertical-align: top;
  width: 45%;
}

.rd-navbar-sidebar .rd-megamenu-title {
  display: none;
}

.rd-navbar-sidebar .rd-megamenu-list {
  margin-top: 0;
}

.rd-navbar-sidebar .rd-megamenu-list>li+li {
  margin-top: 10px;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown>a {
  padding-right: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown>a::before {
  display: none;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown>a:hover {
  padding-left: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.focus>a {
  padding-left: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.opened>.rd-navbar-submenu-toggle {
  color: #52be71;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.opened>.rd-navbar-submenu-toggle::after {
  top: 1px;
}

.rd-navbar-sidebar .rd-navbar-dropdown>li+li {
  margin-top: 10px;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-dropdown {
  margin-top: 25px;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-dropdown .rd-navbar-dropdown {
  margin-top: 10px;
  padding-left: 15px;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-dropdown .rd-navbar-dropdown>li>a {
  font-size: 12px;
}

@media (min-width: 1200px) {
  .rd-navbar-sidebar .rd-navbar-megamenu {
    margin-bottom: -30px;
  }

  .rd-navbar-sidebar .rd-navbar-megamenu>* {
    margin-bottom: 30px;
  }

  .rd-navbar-sidebar .rd-navbar-dropdown>li+li,
  .rd-navbar-sidebar .rd-megamenu-list>li+li {
    margin-top: 18px;
  }
}

@media (min-width: 1200px) {
  .rd-navbar-sidebar .rd-navbar-nav-wrap {
    width: auto;
    left: calc(50% + 600px - 80px);
  }
}

.rd-navbar-sidebar.rd-navbar--is-clone {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transform: translateY(-101%);
}

.rd-navbar-sidebar.rd-navbar--is-clone.rd-navbar--is-stuck {
  transform: translateY(0);
}

.rd-navbar-sidebar.rd-navbar--is-stuck {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
}

.rd-navbar-sidebar.rd-navbar--is-stuck .rd-navbar-main {
  padding-top: 11px;
  padding-bottom: 11px;
}

/**
*
* RD Navbar Classic
*/
.rd-navbar-classic .rd-navbar-aside {
  white-space: nowrap;
}

.rd-navbar-classic .rd-navbar-aside>* {
  margin-top: 0;
}

.rd-navbar-classic .rd-navbar-aside>*+* {
  margin-left: 5px;
}

.rd-navbar-classic .rd-navbar-aside-item {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}

.rd-navbar-classic.rd-navbar-static {
  transition: none;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main-outer {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  transform: translate3d(0, 0, 0);
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-panel {
  flex-grow: 1;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 930px;
  min-height: 90px;
  padding: 16px 0;
  margin-left: auto;
  margin-right: auto;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-aside-item {
  position: relative;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-popup {
  margin-top: 35px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-brand {
  max-width: 150px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-brand img {
  width: auto;
  height: auto;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main-element {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.rd-navbar-classic.rd-navbar-static .rd-nav-item>.rd-navbar-dropdown {
  margin-top: 28px;
}

@media (min-width: 1200px) {
  .rd-navbar-classic.rd-navbar-static .rd-navbar-main {
    min-height: 110px;
    max-width: 1170px;
  }

  .rd-navbar-classic.rd-navbar-static .rd-navbar-main-element {
    padding-left: 50px;
    padding-right: 50px;
  }

  .rd-navbar-classic.rd-navbar-static .rd-navbar-brand {
    max-width: 200px;
  }
}

@media (min-width: 1600px) {
  .rd-navbar-classic.rd-navbar-static .rd-navbar-popup {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    right: auto;
  }
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-main,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-main {
  min-height: 75px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-nav-item>.rd-navbar-dropdown,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-nav-item>.rd-navbar-dropdown {
  margin-top: 18px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-popup,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-popup {
  margin-top: 17px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-nav-item>.rd-navbar-submenu,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-nav-item>.rd-navbar-submenu {
  margin-top: 17px;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-panel {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-brand {
  max-height: 52px;
  max-width: 160px;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside {
  position: fixed;
  right: 8px;
  top: 0;
  display: flex;
  align-items: center;
  height: 56px;
  z-index: 1030;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside .button-icon {
  padding: 5px;
  font-size: 0;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside .button-icon .icon {
  padding: 0;
  font-size: 20px;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-popup {
  position: absolute;
  margin-top: 11px;
  width: 100vw;
  right: -8px;
  overflow-y: auto;
  max-height: calc(100vh - 56px - 10px);
}

@media (min-width: 576px) {
  .rd-navbar-classic.rd-navbar-fixed .rd-navbar-popup {
    width: 360px;
  }
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) {
  background: #5c6367;
  border-color: #5c6367;
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon {
  color: #52be71;
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) {
  color: #ffffff;
  border-color: #5c6367;
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon {
  color: #52be71;
}

.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle {
  color: #ffffff;
}

.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle {
  color: #52be71;
}

.rd-navbar-classic-light .rd-navbar-aside-item>.button-primary:not(:hover),
.rd-navbar-classic-light .rd-navbar-aside-item>.button-primary-outline:not(:hover) {
  color: #151515;
  background: #f3f4f9;
  border-color: #f3f4f9;
}

.rd-navbar-classic-light .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.rd-navbar-classic-light .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon {
  color: #6b9965;
}

.jumbotron-creative .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.2);
}

.jumbotron-creative .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) {
  color: #ffffff;
  background: #3d5e8e;
  border-color: #3d5e8e;
}

.jumbotron-creative .rd-navbar-classic.rd-navbar--is-stuck,
.jumbotron-creative .rd-navbar-fixed .rd-navbar-panel {
  background: #34495e;
}

/*
*
* RD Navbar Fullwidth
*/
.rd-navbar-fullwidth {
  display: block;
}

.rd-navbar-fullwidth .rd-navbar-nav>li+li {
  margin-left: 20px;
}

.rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-main,
.rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-main {
  padding: 10px 0;
}

.context-dark .rd-navbar,
.bg-gray-700 .rd-navbar,
.bg-blue-13 .rd-navbar,
.bg-blue-15 .rd-navbar,
.bg-primary .rd-navbar {
  background-color: transparent;
}

.rd-navbar-wrap[style*='height: 0px;'] {
  margin-top: -1px;
}

/*
*
* Swiper
*/
.swiper-container {
  display: flex;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical>.swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  width: 100%;
  height: auto;
  min-height: inherit;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container {
  flex-shrink: 0;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow>.swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-free-mode>.swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  touch-action: pan-x;
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

.swiper-slide>.vide__body,
.swiper-slide>.parallax_cnt {
  height: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  font-size: 26px;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease-in;
  will-change: transform;
  z-index: 10;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #52be71;
}

@media (min-width: 768px) {

  .swiper-button-prev,
  .swiper-button-next {
    display: block;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev {
  left: 30px;
}

.swiper-button-prev:before {
  font-family: 'FontAwesome';
  content: '\f060';
}

.swiper-button-next {
  right: 30px;
}

.swiper-button-next:before {
  font-family: 'FontAwesome';
  content: '\f061';
}

.swiper-slider.swiper-container-rtl .swiper-button-prev::before {
  content: '\f061';
}

.swiper-slider.swiper-container-rtl .swiper-button-next::before {
  content: '\f060';
}

.swiper-pagination {
  position: absolute;
  display: block;
  text-align: center;
  transition: 0.3s;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.6);
  transition: 0.2s;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination .swiper-pagination-bullet:hover {
  background: white;
}

.swiper-pagination.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-black .swiper-pagination-bullet {
  background: rgba(0, 0, 0, 0.6);
}

.swiper-pagination-black .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: black;
}

.swiper-container-vertical>.swiper-pagination {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical>.swiper-pagination .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal>.swiper-pagination {
  bottom: 20px;
  left: 0;
  width: 100%;
}

.swiper-container-horizontal>.swiper-pagination .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.swiper-slide-caption {
  width: 100%;
  white-space: normal;
}

.swiper-slider-1 {
  min-height: 40.10417vw;
}

/*
*
* Search Results
*/
.rd-search-results-live {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  max-height: 600px;
  overflow-y: auto;
  margin: -3px 0 0;
  text-align: left;
  z-index: 998;
}

.rd-search-results-live #search-results {
  position: relative;
  padding: 16px 0 0;
  color: #151515;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  transform-origin: 50% 0;
  transform: scale(1, 0.9);
  transition: 0.3s all ease;
}

.rd-search-results-live #search-results::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
  border: 1px solid #e0e0e0;
  border-top: 0;
}

.rd-search-results-live #search-results.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1, 1);
}

.rd-search-results-live .search-quick-result {
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  color: #151515;
}

.rd-search-results-live .search-list {
  margin-top: 11px;
}

.rd-search-results-live .search-list li:only-child {
  padding: 0 15px 15px;
}

.rd-search-results-live .search-link {
  color: #d6d6d6;
}

.rd-search-results-live .search-link:hover {
  color: #52be71;
}

.rd-search-results-live .search-error {
  font-size: 14px;
  line-height: 1.6;
}

.rd-search-results-live .search-title {
  position: relative;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #151515;
}

.rd-search-results-live .search-title a:hover {
  color: #52be71;
}

.rd-search-results-live .search-list-item-all {
  margin-top: 18px;
  width: 100%;
}

.rd-search-results-live .search-submit {
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: block;
  padding: 8px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  background: #52be71;
}

.rd-search-results-live .search-submit:hover {
  color: #ffffff;
  background: #d6d6d6;
}

.rd-search-results-live .match {
  display: none;
}

.not-empty~.rd-search-results-live {
  visibility: visible;
  opacity: 1;
}

.rd-search-results-live p {
  font-size: 11px;
}

.rd-search-results-live p * {
  margin: 0;
}

.rd-search-results-live .search-list-item {
  padding: 0 15px;
}

.rd-search-results-live *+p {
  margin-top: 5px;
}

.rd-search-results-live .search-list-item+.search-list-item {
  margin-top: 17px;
}

.rd-search-results .search-list {
  counter-reset: li;
  text-align: left;
  padding-left: 0;
  font-size: 18px;
  list-style-type: none;
  overflow: hidden;
}

.rd-search-results .search-list li div {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}

.rd-search-results .search-list li:only-child::before {
  display: none;
}

.rd-search-results .search-list-item {
  position: relative;
  padding-left: 40px;
  font-size: 14px;
  color: #9a9a9a;
}

.rd-search-results .search-list-item::before {
  content: counter(li, decimal-leading-zero) '.';
  counter-increment: li;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  line-height: inherit;
  font-weight: 400;
  color: #000000;
}

.rd-search-results .search-list-item+.search-list-item {
  margin-top: 40px;
}

.rd-search-results .search-title {
  font-size: 16px;
  color: #000000;
}

.rd-search-results .search {
  color: #ffffff;
  padding: 0 0.25em;
  background: #52be71;
}

.rd-search-results .match {
  padding: 5px;
  font-size: 12px;
  line-height: 1.7;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}

.rd-search-results .match em {
  margin: 0;
  font-style: normal;
}

.rd-search-results p * {
  margin: 0;
}

.rd-search-results *+.match {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .rd-search-results .search-title {
    font-size: 18px;
  }

  .rd-search-results .search-list-item::before {
    top: -1px;
    font-size: 16px;
  }

  .rd-search-results *+p {
    margin-top: 12px;
  }
}

@media (min-width: 992px) {
  .rd-search-results .search-list-item {
    padding-left: 40px;
  }
}

*+.rd-search-results {
  margin-top: 40px;
}

@media (min-width: 768px) {
  *+.rd-search-results {
    margin-top: 55px;
  }
}

.rd-search-classic {
  position: relative;
}

.rd-search-classic .form-input {
  color: #9a9a9a;
  padding-right: 60px;
}

.rd-search-classic .form-input,
.rd-search-classic .form-label {
  letter-spacing: 0;
}

.rd-search-classic .rd-search-submit {
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  /* -webkit-appearance: none; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  padding-top: 3px;
  padding-right: 2px;
  margin: 0;
  text-align: center;
  color: #151515;
  font-size: 0;
  line-height: 0;
}

.rd-search-classic .rd-search-submit::-moz-focus-inner {
  border: none;
  padding: 0;
}

.rd-search-classic .rd-search-submit:before {
  position: relative;
  top: -1px;
  content: '\e01c';
  font: 400 20px 'fl-budicons-launch';
  line-height: 1;
  transition: 0.33s all ease;
}

.rd-search-classic .rd-search-submit:hover {
  color: #52be71;
}

.rd-search-classic.form-lg .form-input {
  padding-right: 70px;
}

.rd-search-classic.form-lg .rd-search-submit {
  width: 60px;
}

.rd-search.form-inline {
  position: relative;
}

.rd-search.form-inline .form-input {
  padding-right: 50px;
}

.rd-search.form-inline .button-link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  right: 0;
  margin: 0;
  font-size: 21px;
  color: #d6d6d6;
  transition: 0.33s;
}

.rd-search.form-inline .button-link::before {
  display: block;
  margin: auto;
}

.rd-search.form-inline .button-link:hover {
  color: #52be71;
}

.rd-search.form-inline.form-sm .form-input {
  padding-right: 40px;
}

.rd-search.form-inline.form-sm .button-link {
  width: 40px;
  font-size: 18px;
}

.rd-search.form-inline.form-lg .form-input {
  padding-right: 60px;
}

.rd-search.form-inline.form-lg .button-link {
  width: 60px;
}

/*
*
* Isotope
*/
@keyframes sprite-animation {
  from {
    background-position: 0 0;
  }
}

[data-isotope-layout] {
  z-index: 0;
  display: block;
  overflow: hidden;
  padding-top: 35px;
  min-height: 160px;
  margin-left: -15px;
  margin-right: -15px;
  transition: 0.4s all ease;
  margin-bottom: -35px;
  transform: translateY(-35px);
}

[data-isotope-layout]:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  margin-top: 15px;
  /* background-image: url("../images/isotope-loader.png"); */
  background-position: -1152px 0;
  animation: 0.7s sprite-animation steps(18) infinite;
  transition: 0.4s all ease;
  transform: translate3d(-50%, -50%, 0);
}

[data-isotope-layout] [class*='col-'] {
  display: block;
  opacity: 0;
  will-change: transform;
  backface-visibility: hidden;
  transition: 0.1s opacity ease-in;
}

[data-isotope-layout].isotope--loaded [class*='col-'] {
  opacity: 1;
}

[data-isotope-layout].isotope--loaded:after {
  opacity: 0;
  visibility: hidden;
}

.isotope.isotope-condensed {
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
}

.isotope.isotope-condensed [class*='col'] {
  padding: 0;
  margin: 0 0 30px 0;
}

@media (min-width: 576px) {
  .isotope.isotope-condensed [class*='col'] {
    margin: 0 -1px -1px 0;
  }
}

.isotope-filters-list {
  position: relative;
  font-size: 16px;
  margin-bottom: -10px;
}

.isotope-filters-list li {
  display: inline-block;
  margin-bottom: 10px;
}

.isotope-filters-list a {
  position: relative;
  display: block;
  z-index: 1;
  padding: 0 15px;
  font: 600 13px/20px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #b7b7b7;
}

.isotope-filters-list li:not(:last-child) a {
  border-right: 1px solid #e0e0e0;
}

.isotope-filters-list a:hover,
.isotope-filters-list a.active {
  color: #52be71;
}

@media (min-width: 992px) {
  .isotope-filters-list a {
    font-size: 14px;
    padding: 0 20px;
  }
}

@media (min-width: 1200px) {
  .isotope-filters-list a {
    padding: 0 40px;
  }
}

@media (max-width: 767px) {
  .isotope-responsive {
    margin-left: -7px;
    margin-right: -7px;
    margin-bottom: -14px;
  }

  .isotope-responsive>* {
    margin-bottom: 14px;
  }

  .isotope-responsive [class*='col'] {
    padding-left: 7px;
    padding-right: 7px;
  }
}

@media (min-width: 768px) {
  .isotope-responsive {
    margin-bottom: -30px;
  }

  .isotope-responsive>* {
    margin-bottom: 30px;
  }
}

*+.isotope-filters-modern {
  margin-top: 30px;
}

@media (min-width: 1200px) {
  *+.isotope-filters-modern {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .isotope-filters-modern {
    position: relative;
    z-index: 10;
    text-align: center;
  }

  .isotope-filters-toggle {
    display: block;
    padding: 10px 20px;
    margin: 0 auto;
    color: #ffffff;
    background: #52be71;
    box-shadow: none;
    border: 0;
  }

  .isotope-filters-toggle .caret {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    font-family: 'FontAwesome';
    font-size: 14px;
    transition: 0.22s;
    will-change: transform;
  }

  .isotope-filters-toggle .caret::before {
    content: '\f107';
  }

  .isotope-filters-toggle.active .caret {
    transform: scale(1, -1);
  }

  .isotope-filters-list {
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 10;
    width: 240px;
    margin: 5px 0 0 -120px;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    transition: 0.3s ease-in;
    text-align: left;
  }

  .isotope-filters-list.active {
    opacity: 1;
    visibility: visible;
  }

  .isotope-filters-list li {
    display: block;
    width: 100%;
    margin-left: 0;
  }

  .isotope-filters-list li:nth-child(n) a {
    border: 0;
  }

  .isotope-filters-list a {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .isotope-filters-modern .isotope-filters-toggle {
    display: none;
  }
}

*+.isotope {
  margin-top: 30px;
}

@media (min-width: 768px) {
  *+.isotope {
    margin-top: 47px;
  }
}

.tabs-custom {
  text-align: left;
}

.tabs-custom .nav-tabs {
  display: block;
  font-size: 0;
  line-height: 0;
  word-spacing: 0;
  border: 0;
}

.tabs-custom .nav-tabs:before,
.tabs-custom .nav-tabs:after {
  display: none;
}

.tabs-custom .nav-item {
  float: none;
  border: 0;
  cursor: pointer;
  transition: 0.33s all ease;
}

.tabs-custom .nav-link {
  margin: 0;
  border-radius: 0;
  border: 0;
}

.tabs-custom .nav-link.active {
  cursor: default;
  border: 0;
}

.tab-content>.tab-pane {
  display: block;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.tab-content>.active {
  visibility: visible;
  height: auto;
  overflow: visible;
}

*+.tabs-horizontal.tabs-minimal {
  margin-top: 35px;
}

*+.tabs-vertical.tabs-minimal {
  margin-top: 40px;
}

@media (min-width: 768px) {
  *+.tabs-vertical.tabs-minimal {
    margin-top: 60px;
  }
}

@media (min-width: 1200px) {
  *+.tabs-vertical.tabs-minimal {
    margin-top: 80px;
  }
}

.tabs-minimal .nav-tabs {
  margin-bottom: -15px;
  text-align: center;
}

.tabs-minimal .nav-tabs>* {
  margin-bottom: 15px;
}

.tabs-minimal .nav-item {
  display: inline-block;
  padding: 0 7px;
}

.tabs-minimal .nav-item:not(:last-child) {
  border-right: 1px solid #f3f4f9;
}

.tabs-minimal .nav-link {
  padding: 0 8px;
  font: 600 14px/20px 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #868e96;
  vertical-align: middle;
}

.tabs-minimal .nav-link:hover,
.tabs-minimal .nav-link.active {
  color: #52be71;
}

.tabs-minimal .tab-content {
  padding: 33px 0 0;
}

@media (min-width: 768px) {
  .tabs-horizontal.tabs-minimal .nav-item {
    padding: 0 18px;
  }

  .tabs-vertical {
    display: flex;
    align-items: flex-start;
  }

  .tabs-vertical .nav-tabs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-shrink: 0;
    max-width: 50%;
  }

  .tabs-vertical .nav-item {
    border: 0;
    width: 100%;
    text-align: left;
  }

  .tabs-vertical .tab-content {
    flex-grow: 1;
  }

  .tabs-vertical.tabs-minimal .nav-tabs {
    width: auto;
    min-width: 180px;
    border: 0;
  }

  .tabs-vertical.tabs-minimal .nav-item {
    margin: 0;
    border: 0;
  }

  .tabs-vertical.tabs-minimal .nav-item+.nav-item {
    border-top: 1px solid #f3f4f9;
  }

  .tabs-vertical.tabs-minimal .nav-link {
    position: relative;
    padding: 15px 0;
    overflow: hidden;
    text-align: left;
  }

  .tabs-vertical.tabs-minimal .tab-content {
    padding: 0 0 0 30px;
  }
}

@media (min-width: 992px) {
  .tabs-vertical.tabs-minimal .nav-tabs {
    min-width: 200px;
  }

  .tabs-vertical.tabs-minimal .tab-content {
    padding: 0 0 0 70px;
  }
}

@media (min-width: 1200px) {
  .tabs-vertical.tabs-minimal .tab-content {
    padding: 0 0 0 105px;
  }
}

.card-group-custom {
  margin-bottom: 0;
}

.card-group-custom .card+.card {
  margin-top: 0;
}

.card-group-custom.card-group-corporate .card+.card {
  margin-top: 30px;
}

.card-custom {
  display: block;
  margin: 0;
  background: inherit;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  text-align: left;
}

.card-custom a {
  display: block;
}

.card-custom .card-header {
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-custom .card-body {
  padding: 0;
  border: 0;
}

.card-title {
  margin-bottom: 0;
}

*+.card-group-custom {
  margin-top: 35px;
}

@media (min-width: 768px) {
  *+.card-group-custom {
    margin-top: 50px;
  }
}

.card-corporate {
  text-align: left;
  border: 1px solid #e0e0e0;
}

.card-corporate .card-title a,
.card-corporate .card-body {
  background: #ffffff;
}

.card-corporate .card-title a {
  position: relative;
  z-index: 1;
  padding: 21px 73px 21px 32px;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #2c343b;
  transition: 1.3s all ease;
}

.card-corporate .card-title a .card-arrow:after {
  opacity: 0;
  visibility: hidden;
}

.card-corporate .card-title a.collapsed .card-arrow:after {
  opacity: 1;
  visibility: visible;
}

.card-corporate .card-arrow {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  height: 28px;
  width: 28px;
  background: #52be71;
  border-radius: 0;
  transition: 0.33s all ease;
  text-align: center;
}

.card-corporate .card-arrow::before,
.card-corporate .card-arrow::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  color: #ffffff;
  font-family: 'Material Design Icons';
  font-size: 24px;
  transition: 0.15s;
}

.card-corporate .card-arrow::before {
  content: '\f465';
}

.card-corporate .card-arrow::after {
  content: '\f504';
}

.card-corporate .collapse {
  position: relative;
  z-index: 1;
  color: #9a9a9a;
  border-radius: 0 0 0 0;
}

.card-corporate .card-body {
  border-top: 1px solid #e0e0e0;
  padding: 18px 44px 25px 32px;
}

@media (max-width: 991px) {

  .card-corporate .card-title a,
  .card-corporate .card-body {
    padding-left: 25px;
  }
}

@media (min-width: 768px) {
  .card-corporate .card-title a {
    font-size: 18px;
  }
}

/*
*
* Tooltop Custom
*/
.tooltip {
  font-size: 14px;
  line-height: 1.2;
}

.tooltip-inner {
  background-color: #52be71;
}

.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #52be71;
}

.tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #52be71;
}

.tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #52be71;
}

.tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #52be71;
}

/*
*
* Counter
*/
.counter-classic {
  text-align: center;
}

.counter-classic-main {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 40px;
  line-height: 1;
  font-weight: 300;
}

.counter-classic-main>* {
  display: inline-block;
  font: inherit;
  color: inherit;
}

.counter-classic-divider {
  font-size: 0;
  line-height: 0;
}

.counter-classic-divider::before {
  content: '';
  display: inline-block;
  width: 35px;
  height: 4px;
  background: #52be71;
}

*+.counter-classic-divider {
  margin-top: 10px;
}

*+.counter-classic-title {
  margin-top: 12px;
}

@media (min-width: 1200px) {
  .counter-classic-main {
    font-size: 70px;
  }
}

.context-dark .counter-classic-main,
.bg-gray-700 .counter-classic-main,
.bg-blue-13 .counter-classic-main,
.bg-blue-15 .counter-classic-main,
.bg-primary .counter-classic-main,
.context-dark .counter-classic-title,
.bg-gray-700 .counter-classic-title,
.bg-blue-13 .counter-classic-title,
.bg-blue-15 .counter-classic-title,
.bg-primary .counter-classic-title {
  color: #ffffff;
}

.counter-modern {
  display: flex;
  align-items: flex-start;
  margin-bottom: -15px;
  margin-left: -15px;
  text-align: left;
}

.counter-modern:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.counter-modern>* {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 15px;
}

.counter-modern-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid #e0e0e0;
  font-size: 24px;
  line-height: 1;
  color: #52be71;
}

.counter-modern-main {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 40px;
  line-height: 1;
  font-weight: 300;
  white-space: nowrap;
}

.counter-modern-main>* {
  display: inline-block;
  font: inherit;
  color: inherit;
}

.context-dark .counter-modern-icon,
.bg-gray-700 .counter-modern-icon,
.bg-blue-13 .counter-modern-icon,
.bg-blue-15 .counter-modern-icon,
.bg-primary .counter-modern-icon {
  border-color: #ffffff;
}

*+.counter-modern-title {
  margin-top: 8px;
}

@media (max-width: 767px) {
  .counter-modern {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .counter-modern-main {
    font-size: 70px;
  }
}

/*
*
* Progress Bars
*/
.progress-linear {
  position: relative;
  text-align: left;
}

.progress-linear .progress-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translate3d(0, -10px, 0);
  margin-bottom: -10px;
  margin-left: -5px;
  margin-right: -5px;
}

.progress-linear .progress-header>* {
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.progress-linear .progress-title {
  color: #0d0d0d;
}

.progress-linear .progress-bar-linear-wrap {
  height: 3px;
  background: #e0e0e0;
}

.progress-linear .progress-bar-linear {
  position: relative;
  width: 0;
  height: inherit;
  background: #52be71;
  transition: 0.5s all ease-in-out;
}

.progress-linear .progress-bar-linear::after {
  content: '';
  position: absolute;
  display: block;
  right: -8px;
  top: -3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: inherit;
}

.progress-linear .progress-value {
  display: none;
  font-weight: 700;
  color: #0d0d0d;
}

.progress-linear .progress-value::after {
  content: '%';
}

*+.progress-bar-linear-wrap {
  margin-top: 5px;
}

.progress-linear+.progress-linear {
  margin-top: 15px;
}

.progress-bar-red .progress-bar-linear {
  background: #eb6a6a;
}

.progress-bar-secondary .progress-bar-linear {
  background: #e69564;
}

.progress-bar-tertiary .progress-bar-linear {
  background: #6c9dca;
}

@media (min-width: 992px) {
  .progress-linear+.progress-linear {
    margin-top: 25px;
  }
}

/*
*
* Circle Progress Bars
*/
.progress-bar-circle {
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;
}

.progress-bar-circle canvas {
  vertical-align: middle;
}

.progress-bar-circle span {
  position: absolute;
  top: 50%;
  left: 51%;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 40px;
  line-height: 1;
  font-weight: 300;
  transform: translate(-50%, -50%);
  color: #151515;
}

.progress-bar-circle span::after {
  content: '%';
}

.progress-bar-circle-title {
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

*+.progress-bar-circle-title {
  margin-top: 14px;
}

/*
*
* Time Circles
*/
.time_circles {
  position: relative;
  width: 100%;
}

.time_circles>div {
  position: absolute;
  top: 0 !important;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (min-width: 1200px) {
  #DateCountdown {
    width: 100%;
  }
}

.time_circles>div>h4 {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 14px !important;
  line-height: 1.2 !important;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #9a9a9a;
}

.time_circles>div>h4+* {
  margin-top: 0;
  margin-bottom: 5px;
}

.context-dark .time_circles>div>h4,
.bg-gray-700 .time_circles>div>h4,
.bg-blue-13 .time_circles>div>h4,
.bg-blue-15 .time_circles>div>h4,
.bg-primary .time_circles>div>h4 {
  color: rgba(255, 255, 255, 0.8);
}

.time_circles>div>span {
  display: block;
  text-align: center;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 24px !important;
  line-height: 1 !important;
  letter-spacing: 0.05em;
  color: #151515;
}

@media (min-width: 768px) {
  .time_circles>div>span {
    font-size: 28px !important;
  }
}

@media (min-width: 768px) {
  .time_circles>div>span {
    font-size: 34px !important;
  }
}

@media (min-width: 992px) {
  .time_circles>div>span {
    font-size: 48px !important;
  }
}

.context-dark .time_circles>div>span,
.bg-gray-700 .time_circles>div>span,
.bg-blue-13 .time_circles>div>span,
.bg-blue-15 .time_circles>div>span,
.bg-primary .time_circles>div>span {
  color: #ffffff;
}

*+.DateCountdown {
  margin-top: 30px;
}

.countdown-wrap {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}

*+.countdown-wrap {
  margin-top: 35px;
}

.countdown-wrap+.button {
  margin-top: 40px;
}

/*
* jQuery mousewheel plugin
*/
/*
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */
}

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto;
}

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr;
}

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto;
}

/*
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside>.mCSB_container {
  margin-right: 30px;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
}

/* non-visible scrollbar */
.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}

.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
}

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
}

.mCSB_outside+.mCSB_scrollTools {
  right: -26px;
}

/* scrollbar position: outside */
.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_scrollTools,
.mCS-dir-rtl-custom>.mCSB_outside+.mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0;
}

.mCS-dir-rtl-custom>.mCSB_outside+.mCSB_scrollTools {
  left: -26px;
}

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.mCSB_scrollTools a+.mCSB_draggerContainer {
  margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 16px;
  height: 100%;
  margin: 0 auto;
  border-radius: 0;
}

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 16px;
  height: 100%;
  margin: 0 auto;
  border-radius: 0;
  text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside>.mCSB_container {
  margin-right: 0;
  margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside>.mCSB_container {
  min-height: 100%;
}

.mCSB_horizontal>.mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}

.mCustomScrollBox+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a+.mCSB_draggerContainer {
  margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS
yx-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}

.mCSB_container_wrapper>.mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCSB_vertical_horizontal>.mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}

.mCSB_vertical_horizontal>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden+.mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl-custom>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl-custom>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl-custom>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}

.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden>.mCSB_container {
  padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden>.mCSB_container {
  padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0;
}

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s,
    margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s,
    margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s,
    margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s,
    margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s,
    margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
    opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s,
    margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
    opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

/*
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
------------------------------------------------------------------------------------------------------------------------
*/
/*
----------------------------------------
6.1 THEMES
----------------------------------------
*/
/* default theme ("light") */
.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #f2f3f8;
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #d1d4e6;
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  /* background-image: url(../images/mCSB_buttons.png); */
  /* css sprites */
  background-repeat: no-repeat;
  opacity: 0.4;
}

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
}

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
}

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
}

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
}

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
}

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

/*
*
* Owl Carousel
*/
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:before {
  content: '\f144';
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */
.owl-nav.disabled {
  display: none !important;
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font: 400 20px/20px 'fl-budicons-free';
  color: #cbcbcb;
  cursor: pointer;
  transition: 0.33s;
}

.owl-prev:hover,
.owl-next:hover {
  color: #52be71;
}

.owl-prev {
  left: 0;
}

.owl-prev::before {
  content: '\e016';
}

.owl-next {
  right: 0;
}

.owl-next::before {
  content: '\e025';
}

/*
 * Owl Pagination
 */
.owl-dots {
  text-align: center;
  margin-top: 30px;
}

.owl-dot {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #b7b7b7;
  border-radius: 50%;
  margin: 0 5px;
  text-align: center;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: 0.22s;
}

.owl-dot::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: #b7b7b7;
  width: 6px;
  height: 6px;
  border-radius: inherit;
  transition: 0.22s;
  opacity: 0;
}

.owl-dot:only-child {
  display: none;
}

.owl-dot:hover,
.owl-dot.active {
  border-color: #52be71;
}

.owl-dot.active::before {
  opacity: 1;
  background: #52be71;
}

.context-dark .owl-dot:not(:hover):not(.active),
.bg-gray-700 .owl-dot:not(:hover):not(.active),
.bg-blue-13 .owl-dot:not(:hover):not(.active),
.bg-blue-15 .owl-dot:not(:hover):not(.active),
.bg-primary .owl-dot:not(:hover):not(.active) {
  border-color: #ffffff;
}

.owl-carousel-stretch .owl-stage,
.owl-carousel-stretch .owl-item {
  display: flex;
}

.owl-carousel-stretch .owl-item>* {
  width: 100%;
}

*+.owl-carousel-stretch {
  margin-top: 40px;
}

.owl-carousel-quote-classic .owl-stage-outer {
  padding: 10px 21px 45px;
  margin: -10px -21px -45px;
}

@media (min-width: 1600px) {
  .owl-carousel-quote-classic {
    max-width: 1720px;
    margin-left: auto;
    margin-right: auto;
  }
}

*+.owl-carousel-quote-classic {
  margin-top: 40px;
}

.owl-outer-nav-1 {
  margin-left: -11px;
  margin-right: -11px;
  white-space: nowrap;
}

.owl-outer-nav-1>* {
  padding-left: 11px;
  padding-right: 11px;
}

.owl-outer-nav-1 .owl-prev,
.owl-outer-nav-1 .owl-next {
  position: static;
  transform: none;
}

.owl-outer-nav-1>* {
  display: inline-block;
  vertical-align: middle;
}

.owl-outer-nav-1>*+* {
  border-left: 1px solid #cbcbcb;
}

.owl-carousel-profile .owl-stage-outer {
  padding: 21px;
  margin: -21px;
  border: 1px solid transparent;
}

*+.owl-carousel-profile {
  margin-top: 40px;
}

.owl-carousel-wrap {
  position: relative;
}

.owl-nav-modern .owl-prev,
.owl-nav-modern .owl-next {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 40px;
  padding: 5px;
  overflow: hidden;
  color: #151515;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.12);
}

.owl-nav-modern .owl-prev::before,
.owl-nav-modern .owl-next::before {
  z-index: 3;
  font-family: 'Material Design Icons';
  font-size: 20px;
}

.owl-nav-modern .owl-prev:hover,
.owl-nav-modern .owl-next:hover {
  color: #ffffff;
  background: #52be71;
  width: 70px;
}

.owl-nav-modern .owl-prev {
  left: 0;
}

.owl-nav-modern .owl-prev:before {
  content: '\f14a';
}

.owl-nav-modern .owl-next {
  right: 0;
}

.owl-nav-modern .owl-next:before {
  content: '\f14f';
}

.owl-carousel_style-1 .owl-nav {
  display: none;
}

.owl-carousel_style-1 .owl-dots {
  margin-top: 10px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .owl-carousel_style-1 .owl-dots {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .owl-carousel_style-1-outer-nav {
    display: none;
  }
}

.owl-carousel_style-1-outer-nav .owl-prev,
.owl-carousel_style-1-outer-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.owl-carousel_style-1-outer-nav .owl-prev {
  left: 0;
}

.owl-carousel_style-1-outer-nav .owl-next {
  right: 0;
}

.owl-carousel-indigo {
  position: relative;
  overflow: hidden;
  max-width: 100vw;
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
}

.owl-carousel-indigo,
.owl-carousel-indigo * {
  backface-visibility: hidden;
}

.owl-carousel-indigo .owl-stage-outer {
  padding: 23px;
  margin: -23px;
}

.owl-carousel-indigo .owl-item.active {
  pointer-events: auto;
}

.owl-carousel-indigo .owl-dots {
  pointer-events: auto;
}

.owl-carousel-indigo *+.owl-dots {
  margin-top: 25px;
}

@media (max-width: 575px) {
  .owl-carousel-indigo .owl-dot {
    width: 11px;
    height: 11px;
    margin: 0 6px;
  }
}

@media (min-width: 768px) {
  .owl-carousel-indigo {
    padding: 0 0;
  }

  .owl-carousel-indigo *+.owl-dots {
    margin-top: 60px;
  }
}

.owl-carousel-dots-space .owl-dots {
  margin-bottom: 25px;
}

.lg-sub-html,
.lg-toolbar {
  background-color: rgba(0, 0, 0, 0.45);
}

/* @font-face {
	font-family: lg;
	src: url(../fonts/lg.eot?n1z373);
	src: url(../fonts/lg.eot?#iefixn1z373) format("embedded-opentype"), url(../fonts/lg.woff?n1z373) format("woff"), url(../fonts/lg.ttf?n1z373) format("truetype"), url(../fonts/lg.svg?n1z373#lg) format("svg");
	font-weight: 400;
	font-style: normal;
} */

.lg-icon {
  font-family: lg;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  border: none;
  outline: 0;
}

.lg-actions .lg-next.disabled,
.lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  color: #ffffff;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: '\e095';
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: '\e094';
}

@-webkit-keyframes lg-right-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: -30px;
  }
}

@-moz-keyframes lg-right-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: -30px;
  }
}

@-ms-keyframes lg-right-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: -30px;
  }
}

@keyframes lg-right-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: -30px;
  }
}

@-webkit-keyframes lg-left-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

@-moz-keyframes lg-left-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

@-ms-keyframes lg-left-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

@keyframes lg-left-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.lg-toolbar .lg-icon {
  color: #999999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: 0;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.lg-toolbar .lg-icon:hover {
  color: #ffffff;
}

.lg-toolbar .lg-close:after {
  content: '\e070';
}

.lg-toolbar .lg-download:after {
  content: '\e0f2';
}

.lg-sub-html {
  bottom: 0;
  color: #eeeeee;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-next,
.lg-prev,
.lg-toolbar {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
  margin-left: auto;
  margin-right: auto;
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #ffffff;
  border-radius: 4px;
  margin-bottom: 5px;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toogle-thumb {
  background-color: #0d0a0a;
  border-radius: 2px 2px 0 0;
  color: #999999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
}

.lg-outer .lg-toogle-thumb:hover,
.lg-outer.lg-dropdown-active #lg-share {
  color: #ffffff;
}

.lg-outer .lg-toogle-thumb:after {
  content: '\e1ff';
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.lg-outer .lg-has-html5 .lg-video-play {
  /* background: url(../images/video-play.png) no-repeat; */
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object,
.lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 80ms ease 0s;
  -moz-transition: opacity 80ms ease 0s;
  -o-transition: opacity 80ms ease 0s;
  transition: opacity 80ms ease 0s;
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: '\e01d';
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: '\e01a';
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: '\e311';
}

#lg-actual-size {
  font-size: 20px;
}

#lg-actual-size:after {
  content: '\e033';
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: '\e312';
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px #ffffff inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #ffffff;
  color: #ffffff;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:focus,
.lg-outer .lg-pager:hover {
  box-shadow: 0 0 0 8px #ffffff inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: '\e20c';
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: '\e20d';
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active #lg-dropdown-overlay,
.lg-outer.lg-dropdown-active .lg-dropdown {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer .lg-dropdown {
  background-color: #ffffff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #ffffff;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown>li:last-child {
  margin-bottom: 0;
}

.lg-outer .lg-dropdown>li:hover .lg-icon,
.lg-outer .lg-dropdown>li:hover a {
  color: #333333;
}

.lg-outer .lg-dropdown a {
  color: #333333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer,
.lg-outer .lg,
.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
}

.lg-outer #lg-share {
  position: relative;
}

.lg-outer #lg-share:after {
  content: '\e80d';
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: '\e901';
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: '\e904';
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: '\e902';
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: '\e903';
}

.lg-group:after {
  content: '';
  display: table;
  clear: both;
}

.lg-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-current,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-prev-slide {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

.lg-outer .lg-inner {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.lg-outer .lg-item {
  /* background: url(../images/loading.gif) center center no-repeat; */
  display: none !important;
}

.lg-outer.lg-css .lg-current,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide,
.lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important;
}

.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-img-wrap:before,
.lg-outer .lg-item:before {
  content: '';
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html,
.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-current,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-prev-slide {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item,
.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-current,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-prev-slide {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-sub-html,
.lg-toolbar {
  background-color: rgba(0, 0, 0, 0.45);
}

/* @font-face {
	font-family: lg;
	src: url(../fonts/lg.eot?n1z373);
	src: url(../fonts/lg.eot?#iefixn1z373) format("embedded-opentype"), url(../fonts/lg.woff?n1z373) format("woff"), url(../fonts/lg.ttf?n1z373) format("truetype"), url(../fonts/lg.svg?n1z373#lg) format("svg");
	font-weight: 400;
	font-style: normal;
} */

.lg-icon {
  font-family: lg;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  border: none;
  outline: 0;
}

.lg-actions .lg-next.disabled,
.lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  color: #ffffff;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: '\e095';
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: '\e094';
}

@-webkit-keyframes lg-right-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: -30px;
  }
}

@-moz-keyframes lg-right-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: -30px;
  }
}

@-ms-keyframes lg-right-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: -30px;
  }
}

@keyframes lg-right-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: -30px;
  }
}

@-webkit-keyframes lg-left-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

@-moz-keyframes lg-left-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

@-ms-keyframes lg-left-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

@keyframes lg-left-end {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.lg-toolbar .lg-icon {
  color: #999999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: 0;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.lg-toolbar .lg-icon:hover {
  color: #ffffff;
}

.lg-toolbar .lg-close:after {
  content: '\e070';
}

.lg-toolbar .lg-download:after {
  content: '\e0f2';
}

.lg-sub-html {
  bottom: 0;
  color: #eeeeee;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-next,
.lg-prev,
.lg-toolbar {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #ffffff;
  border-radius: 4px;
  margin-bottom: 5px;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toogle-thumb {
  background-color: #0d0a0a;
  border-radius: 2px 2px 0 0;
  color: #999999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
}

.lg-outer .lg-toogle-thumb:hover,
.lg-outer.lg-dropdown-active #lg-share {
  color: #ffffff;
}

.lg-outer .lg-toogle-thumb:after {
  content: '\e1ff';
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.lg-outer .lg-has-html5 .lg-video-play {
  /* background: url(../images/video-play.png) no-repeat; */
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object,
.lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 80ms ease 0s;
  -moz-transition: opacity 80ms ease 0s;
  -o-transition: opacity 80ms ease 0s;
  transition: opacity 80ms ease 0s;
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: '\e01d';
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: '\e01a';
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: '\e311';
}

#lg-actual-size {
  font-size: 20px;
}

#lg-actual-size:after {
  content: '\e033';
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: '\e312';
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px #ffffff inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #ffffff;
  color: #ffffff;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:focus,
.lg-outer .lg-pager:hover {
  box-shadow: 0 0 0 8px #ffffff inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: '\e20c';
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: '\e20d';
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active #lg-dropdown-overlay,
.lg-outer.lg-dropdown-active .lg-dropdown {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer .lg-dropdown {
  background-color: #ffffff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #ffffff;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown>li:last-child {
  margin-bottom: 0;
}

.lg-outer .lg-dropdown>li:hover .lg-icon,
.lg-outer .lg-dropdown>li:hover a {
  color: #333333;
}

.lg-outer .lg-dropdown a {
  color: #333333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer,
.lg-outer .lg,
.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
}

.lg-outer #lg-share {
  position: relative;
}

.lg-outer #lg-share:after {
  content: '\e80d';
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: '\e901';
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: '\e904';
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: '\e902';
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: '\e903';
}

.lg-group:after {
  content: '';
  display: table;
  clear: both;
}

.lg-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-current,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-prev-slide {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

.lg-outer .lg-inner {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.lg-outer .lg-item {
  /* background: url(../images/loading.gif) center center no-repeat; */
  display: none !important;
}

.lg-outer.lg-css .lg-current,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide,
.lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important;
}

.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-img-wrap:before,
.lg-outer .lg-item:before {
  content: '';
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html,
.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000000;
  opacity: 0;
  transition: opacity 0.15s ease 0s;
}

.lg-backdrop.in {
  opacity: 0.8;
}

.lg-css3.lg-no-trans .lg-current,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-prev-slide {
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item,
.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-current,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-prev-slide {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

/*
*
* Material Parallax
*/
.parallax-content {
  position: relative;
  z-index: 1;
}

.parallax-content,
.parallax-content * {
  backface-visibility: hidden;
}

.parallax-container {
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}

.material-parallax {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
  z-index: 0;
  background-position: center center;
}

.ipad .parallax-container,
.iphone .parallax-container {
  background-attachment: scroll !important;
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 101%;
  min-height: 101%;
  max-width: none;
  transform: translate3d(-50%, 0, 0);
  will-change: transform;
}

/*
*
* Select 2 v4
*/
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  text-align: left;
}

.select2-container .select2-selection--single {
  display: block;
  cursor: pointer;
  user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir='rtl'] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  display: block;
  cursor: pointer;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  /* -webkit-appearance: none; */
}

.select2-dropdown {
  position: absolute;
  left: -100000px;
  z-index: 1000;
  display: block;
  overflow: hidden;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  background-color: #ffffff;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 3px 6px 3px 26px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  /* -webkit-appearance: none; */
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-dropdown-borderless.select2-dropdown {
  color: #2c343b;
  border-color: transparent;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #ffffff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default .select2-selection--single {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  display: block;
  padding: 12px 36px 12px 26px;
  color: #151515;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  min-height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: 700;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #151515;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  right: 14px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  pointer-events: none;
  color: #151515;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b::before {
  position: relative;
  display: inline-block;
  content: '';
  font-family: 'Material Design Icons';
  font-size: 18px;
  transform: rotate(0deg);
  transition: 0.1s;
}

.select2-container--default[dir='rtl'] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir='rtl'] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #f3f4f9;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::before {
  transform: rotate(180deg);
}

.select2-container--default .select2-selection--multiple {
  background-color: #ffffff;
  border: 1px solid #aaa;
  border-radius: 0.3rem;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown {
  border-top: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #e0e0e0;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  /* -webkit-appearance: textfield; */
}

.select2-container--default .select2-results>.select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role='group'] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled='true'] {
  color: #9a9a9a;
}

.select2-container--default .select2-results__option[aria-selected='true'] {
  color: #ffffff;
  background-color: #77cc8f;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #ffffff;
  background-color: #52be71;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

/*
*
* Modal Custom
*/
.modal-tip {
  padding: 30px 15px 7vh;
  visibility: hidden;
}

.modal-tip .close {
  cursor: pointer;
  color: #b7b7b7;
  opacity: 0;
}

.modal-tip .close span {
  transition: 0.22s;
}

.modal-tip .close span::before {
  content: '\f24c';
  font-family: 'Material Design Icons';
}

.modal-tip .close:hover {
  color: #2c343b;
}

.modal-tip.show {
  display: flex !important;
  visibility: visible;
}

.modal-tip.show .close {
  opacity: 1;
}

.modal-tip.fade .modal-dialog {
  transform: translate3d(0, -30px, 0);
}

.modal-tip.show .modal-dialog {
  transform: translate3d(0, 0, 0);
}

.modal-tip .modal-dialog {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
}

.modal-tip .modal-content {
  height: 100%;
  border-radius: 0;
}

.modal-tip .modal-body {
  height: 100%;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .modal-tip {
    align-items: center;
  }

  .modal-tip .modal-content,
  .modal-tip .model-body {
    height: auto;
  }
}

@media (min-width: 768px) {
  .modal-tip .modal-dialog {
    max-width: 700px;
  }

  .modal-tip .modal-header,
  .modal-tip .modal-body {
    padding-left: 30px;
    padding-right: 30px;
  }

  .modal-tip .modal-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .modal-tip .modal-body {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

/*
*
* Google Maps
*/
.google-map-markers {
  display: none;
}

.google-map-container {
  width: 100%;
}

.google-map {
  width: 100%;
  height: 200px;
}

@media (min-width: 576px) {
  .google-map {
    height: 280px;
  }
}

@media (min-width: 992px) {
  .google-map {
    height: 360px;
  }
}

@media (min-width: 1200px) {
  .google-map {
    height: 440px;
  }
}

.gm-style-iw {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  color: #151515;
}

.gm-wrapper>div>div:nth-child(1) {
  background: yellow !important;
}

.gm-wrapper>div>div>div:nth-child(2) {
  background-color: transparent;
  border-radius: 0 !important;
  box-shadow: none !important;
  display: none !important;
}

.gm-wrapper>div>div>div:nth-child(1) {
  display: none;
}

.gm-wrapper>div>div>div:nth-child(3) {
  z-index: 12;
}

.gm-wrapper>div>div>div:nth-child(3)>div {
  height: 20px !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div:nth-child(1)>div {
  transform: skewX(30.6deg) !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div:nth-child(2)>div {
  transform: skewX(-30.6deg) !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div>div {
  height: 16px !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div>div {
  background: #ffffff !important;
  box-shadow: none !important;
}

.gm-wrapper>div>div>div:nth-child(4) {
  background: #ffffff !important;
  border: 1px solid #e0e0e0;
}

@media (min-width: 768px) and (max-width: 991px) {
  .google-map_1 {
    height: 385px;
  }
}

/*
*
* Slick Carousel
*/
/*rtl:begin:ignore*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track::before,
.slick-track::after {
  content: '';
  display: table;
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  color: transparent;
  background-color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 9;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev::before,
.slick-next::before {
  font-family: 'fl-bigmug-line';
  font-size: 24px;
  line-height: 1;
  color: #52be71;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.22s;
}

.slick-prev:hover::before,
.slick-next:hover::before {
  color: #52be71;
}

.slick-prev {
  left: 0;
}

[dir='rtl'] .slick-prev {
  left: auto;
  right: 0;
}

.slick-prev:before {
  content: '';
}

[dir='rtl'] .slick-prev:before {
  content: '';
}

.slick-next {
  right: 0;
}

[dir='rtl'] .slick-next {
  left: 0;
  right: auto;
}

.slick-next:before {
  content: '';
}

[dir='rtl'] .slick-next:before {
  content: '';
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #b7b7b7;
  border-radius: 50%;
  margin: 0 5px;
  text-align: center;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: 0.22s;
}

.slick-dots li button {
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  /* -webkit-appearance: none; */
  font-size: 0;
}

.slick-dots li button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.slick-dots li::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: #b7b7b7;
  width: 6px;
  height: 6px;
  border-radius: inherit;
  transition: 0.22s;
  opacity: 0;
}

.slick-dots li:only-child {
  display: none;
}

.slick-dots li:hover {
  border-color: #52be71;
}

.slick-dots li.slick-active {
  border-color: #52be71;
}

.slick-dots li.slick-active::before {
  opacity: 1;
  background: #52be71;
}

/*rtl:end:ignore*/
.slick-slider-1 {
  width: 100%;
}

.slick-slider-1 .carousel-parent .slick-track {
  display: flex;
  align-items: flex-end;
}

.slick-slider-1 .carousel-parent .item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.slick-slider-1 .carousel-parent .slick-slide>* {
  opacity: 0;
  transition: 0.22s;
}

.slick-slider-1 .carousel-parent .slick-active>* {
  opacity: 1;
}

.slick-slider-1 .carousel-child {
  position: relative;
  color: #151515;
  letter-spacing: 0.025em;
  hyphens: auto;
  padding: 40px 30px 80px 30px;
  border: 2px solid #e0e0e0;
}

.slick-slider-1 .carousel-child .slick-dots {
  position: absolute;
  left: 26px;
  bottom: 25px;
  display: inline-block;
  width: auto;
  margin: 0;
}

@media (max-width: 767px) {
  .slick-slider-1 .carousel-child {
    padding-top: 20px;
  }
}

@media (min-width: 768px) {
  .slick-slider-1 .carousel-child-outer {
    position: relative;
    overflow: hidden;
    padding-left: 18px;
  }

  .slick-slider-1 .carousel-child {
    border-left-width: 0;
  }

  .slick-slider-1 .carousel-child::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -17px;
    width: 17px;
    border-bottom: 2px solid #e0e0e0;
  }

  .slick-slider-1 .carousel-child::after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: -16px;
    top: 16px;
    width: 18px;
    border: 2px solid #e0e0e0;
    border-left-width: 0;
    border-bottom-width: 0;
    border-top-width: 4px;
    transform: skewY(55deg);
    transform-origin: 75% 0;
  }
}

@media (min-width: 768px) {
  .slick-slider-1 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .slick-slider-1 .carousel-parent {
    width: 54%;
  }

  .slick-slider-1 .carousel-child-outer {
    width: 45%;
    min-width: 300px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .slick-slider-1 .carousel-parent {
    width: 60%;
  }

  .slick-slider-1 .carousel-child-outer {
    max-width: 54%;
  }
}

@media (min-width: 1200px) {
  .slick-slider-1 .carousel-child {
    padding: 60px 25px 100px 45px;
  }

  .slick-slider-1 .carousel-child .slick-dots {
    left: 43px;
    bottom: 50px;
  }
}

.context-dark .slick-slider-1 .carousel-child,
.bg-gray-700 .slick-slider-1 .carousel-child,
.bg-blue-13 .slick-slider-1 .carousel-child,
.bg-blue-15 .slick-slider-1 .carousel-child,
.bg-primary .slick-slider-1 .carousel-child,
.context-dark .slick-slider-1 .carousel-child::before,
.bg-gray-700 .slick-slider-1 .carousel-child::before,
.bg-blue-13 .slick-slider-1 .carousel-child::before,
.bg-blue-15 .slick-slider-1 .carousel-child::before,
.bg-primary .slick-slider-1 .carousel-child::before,
.context-dark .slick-slider-1 .carousel-child::after,
.bg-gray-700 .slick-slider-1 .carousel-child::after,
.bg-blue-13 .slick-slider-1 .carousel-child::after,
.bg-blue-15 .slick-slider-1 .carousel-child::after,
.bg-primary .slick-slider-1 .carousel-child::after {
  border-color: #6b7579;
}

.slick-slider-2 {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  background: #ffffff;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.09);
}

.slick-slider-2 .carousel-parent {
  padding: 0 10px 20px;
}

.slick-slider-2 .carousel-parent .slick-track {
  display: flex;
}

.slick-slider-2 .carousel-parent .slick-slide {
  display: flex;
  float: none;
  height: auto;
}

.slick-slider-2 .carousel-parent .slick-slide>* {
  min-width: 100%;
}

.slick-slider-2 .slick-2-item-figure {
  display: none;
}

.slick-slider-2 .carousel-child {
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
}

.slick-slider-2 .carousel-child .slick-list {
  max-width: 440px;
}

.slick-slider-2 .carousel-child .slick-slide-inner {
  position: relative;
  font-size: 0;
  line-height: 0;
  padding: 23px 5px 0;
  width: 100%;
  max-width: 70px;
  margin-left: auto;
  margin-right: auto;
}

.slick-slider-2 .carousel-child .slick-slide-inner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 11px 7px;
  border-color: transparent transparent #52be71 transparent;
  opacity: 0;
  transform: translateY(5px);
  transition: 0.22s;
}

.slick-slider-2 .carousel-child .slick-current .slick-slide-inner::before {
  opacity: 1;
  transform: translateY(0);
}

.slick-slider-2 .carousel-child .slick-slide-figure {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  cursor: pointer;
}

.slick-slider-2 .carousel-child .slick-slide-figure::before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.slick-slider-2 .carousel-child .slick-slide-figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
  max-width: none;
  border-radius: 50%;
}

@supports (object-fit: cover) {
  .slick-slider-2 .carousel-child .slick-slide-figure img {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    object-fit: cover;
    object-position: center center;
  }
}

.slick-slider-2 .slick-arrow::before {
  font-family: 'Material Design Icons';
  font-size: 20px;
  opacity: 1;
}

.slick-slider-2 .slick-prev {
  left: -15px;
}

.slick-slider-2 .slick-prev::before {
  content: '\f14a';
}

.slick-slider-2 .slick-next {
  right: -15px;
}

.slick-slider-2 .slick-next::before {
  content: '\f14f';
}

@media (min-width: 576px) {
  .slick-slider-2 {
    padding: 30px 40px;
    margin-right: 30px;
  }

  .slick-slider-2 .slick-arrow {
    position: absolute;
    right: -90px;
    left: auto;
    transform: none;
    display: flex;
    justify-content: center;
    width: 32px;
    height: 48px;
  }

  .slick-slider-2 .slick-arrow::before {
    font-size: 25px;
  }

  .slick-slider-2 .slick-arrow:hover::before {
    color: #151515;
  }

  .slick-slider-2 .slick-prev {
    top: 50%;
    transform: translateY(-100%);
    align-items: flex-start;
  }

  .slick-slider-2 .slick-prev::before {
    content: '\f156';
  }

  .slick-slider-2 .slick-next {
    top: auto;
    bottom: 50%;
    transform: translateY(100%);
    align-items: flex-end;
    border-top: 1px solid #d6d6d6;
  }

  .slick-slider-2 .slick-next::before {
    content: '\f144';
  }
}

@media (min-width: 768px) {
  .slick-slider-2 {
    padding: 0 20px;
    margin-right: 50px;
  }

  .slick-slider-2 .carousel-parent {
    padding: 20px 0;
  }

  .slick-slider-2 .slick-2-item {
    display: flex;
    align-items: flex-start;
    margin-left: -40px;
  }

  .slick-slider-2 .slick-2-item>* {
    margin-left: 40px;
  }

  .slick-slider-2 .slick-2-item-main {
    padding: 25px 0 35px;
    flex-grow: 1;
  }

  .slick-slider-2 .slick-2-item-figure {
    position: relative;
    overflow: hidden;
    align-self: stretch;
    display: block;
    flex-shrink: 0;
    background: black;
    width: 100%;
    max-width: 34.5%;
  }

  .slick-slider-2 .slick-2-item-figure img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
    max-width: none;
  }

  @supports (object-fit: cover) {
    .slick-slider-2 .slick-2-item-figure img {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: none;
      object-fit: cover;
      object-position: center center;
    }
  }

  .slick-slider-2 .carousel-child {
    padding: 15px 0 25px;
  }

  .slick-slider-2 .carousel-child .slick-list {
    max-width: 100%;
  }

  .slick-slider-2 .slick-arrow {
    right: -70px;
  }
}

@media (min-width: 992px) {
  .slick-slider-2 {
    margin-right: 70px;
    padding-left: 40px;
  }

  .slick-slider-2 .carousel-parent {
    width: calc(100% + 40px);
  }

  .slick-slider-2 .slick-2-item {
    width: calc(100% + 80px);
  }

  .slick-slider-2 .slick-2-item-main {
    min-height: 180px;
    margin-bottom: 100px;
  }

  .slick-slider-2 .carousel-child {
    padding: 22px 0 20px;
    margin-top: -120px;
    margin-right: 370px;
  }

  .slick-slider-2 .carousel-child .slick-list {
    max-width: 520px;
  }

  .slick-slider-2 .carousel-child .slick-slide-figure {
    margin-left: 0;
    margin-right: 0;
  }

  .slick-slider-2 .slick-arrow {
    right: -45px;
  }
}

@media (min-width: 1200px) {
  .slick-slider-2 {
    padding: 0 20px 20px 70px;
    margin-right: 115px;
  }

  .slick-slider-2 .slick-2-item {
    margin-left: -80px;
  }

  .slick-slider-2 .slick-2-item>* {
    margin-left: 80px;
  }

  .slick-slider-2 .slick-2-item-main {
    margin-bottom: 140px;
  }

  .slick-slider-2 .carousel-child {
    margin-top: -160px;
    padding-bottom: 25px;
  }

  .slick-slider-2 .carousel-child .slick-slide-inner {
    max-width: 85px;
    margin-left: 0;
  }

  .slick-slider-2 .slick-arrow {
    right: -70px;
  }
}

*+.slick-slider-2 {
  margin-top: 40px;
}

.slick-slider-3 .carousel-child {
  max-width: 340px;
  padding: 0 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.slick-slider-3 .carousel-child .slick-track {
  margin-left: auto;
  margin-right: auto;
}

.slick-slider-3 .carousel-child .slick-slide {
  width: 100%;
}

.slick-slider-3 .carousel-child .item-inner {
  position: relative;
  font-size: 0;
  line-height: 0;
  padding: 17px 5px 5px;
  width: 100%;
  max-width: 80px;
  margin-left: auto;
  margin-right: auto;
}

.slick-slider-3 .carousel-child .item-inner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 10px 6px;
  border-color: transparent transparent #52be71 transparent;
  opacity: 0;
  transform: translateY(5px);
  transition: 0.22s;
}

.slick-slider-3 .carousel-child .slick-slide-inner {
  border-radius: 50%;
  border: 6px solid transparent;
  transition: 0.1s ease;
}

.slick-slider-3 .carousel-child .slick-current .item-inner::before {
  opacity: 1;
  transform: translateY(0);
}

.slick-slider-3 .carousel-child .slick-current .slick-slide-inner {
  border-color: #ffffff;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.21);
}

.slick-slider-3 .carousel-child .slick-slide-figure {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  margin: -1px;
}

.slick-slider-3 .carousel-child .slick-slide-figure::before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.slick-slider-3 .carousel-child .slick-slide-figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
  max-width: none;
}

@supports (object-fit: cover) {
  .slick-slider-3 .carousel-child .slick-slide-figure img {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    object-fit: cover;
    object-position: center center;
  }
}

.slick-slider-3 *+.carousel-child {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .slick-slider-3 .carousel-child {
    max-width: 460px;
  }

  .slick-slider-3 .carousel-child .item-inner {
    max-width: 95px;
  }
}

*+.slick-slider-3 {
  margin-top: 35px;
}


.login-signup-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 8rem;
}

.login-signup-svg img {
  height: auto;
  max-width: 100%;
  position: relative;
  top: 3rem;


}


@media (max-width : 768px) {
  .login-signup-wrapper {
    flex-direction: column;
  }

}

@media (max-width : 768px) {

  .sm-Table-overflow {
    overflow: scroll;
  }
}

.file-preview {
  border: 1px solid #2d394b;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  font-weight: 600;
  color: #2d394b;
  border-radius: 5px;
  /* background-color: #f1c40f; */
  margin-top: 2rem;
  margin-bottom: -1rem;
  text-transform: capitalize;
  padding: 5px 25px;
}

.loader {
  background-color: "red"
}

.row-15 {
  margin-bottom: 0;
}




input:disabled {
  color: #afabab;
}

.selectMenu:disabled {
  color: #afabab;
}

/* ============= */

@media (min-width : 992px) {

  .navbar-expand-lg {
    border-bottom: 1px solid darkgray;
  }
}

/*======================================== Convert Crousel Inticators round  =========================================== */

.carousel-indicators {
  position: absolute !important;

  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  list-style: none;
  gap: 5px;
  right: 2rem !important;
  top: 50% !important;
  transform: rotate(90deg);
  left: auto !important;
  bottom: auto !important;
}

.carousel-indicators button {
  border: 2px solid white !important;
  border-radius: 10px !important;
  background-color: transparent !important;
  height: 13px !important;
  width: 13px !important;

}

.carousel-indicators .active {
  border-top: unset !important;
  border-bottom: unset !important;
  border: 1px solid transparent !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-radius: 10px !important;
  background-color: #52be71 !important;
  border: 2px solid #52be71 !important;
}

@media (max-width : 768px) {
  .carousel-indicators {
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    top: auto !important;
    z-index: 2 !important;
    display: flex !important;
    justify-content: center !important;
    padding: 0 !important;
    margin-right: 15% !important;
    margin-bottom: 1rem !important;
    margin-left: 15% !important;
    list-style: none !important;
    transform: none !important;


  }

  .carousel-indicators button {
    border: 2px solid white !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    height: 11px !important;
    width: 11px !important;

  }
}


@media (min-width : 752px) {

  .heightEqual {
    height: 350px !important;
    display: flex;
    line-height: 28px;
    justify-content: center;
    position: relative;

  }
}

@media (max-width:751px) {
  .heightEqual {
    height: auto !important;
    display: flex;
    justify-content: center;
    position: relative;
    

  }

}



.button2 {
  border: 2px solid;
  padding: 8px 30px;
  position: absolute;
  bottom: 1.5rem;
  text-transform: uppercase;
  font-weight: 500d;
}

@media (max-width : 768px) {

  .button2 {

    position: relative;
    bottom: 0 !important;
    /* width: 150px; */
    max-width: 150px;
    margin: 1rem auto;
  }

  .heightEqual {
    flex-direction: column;
  }

}

/* .button2:hover {
  background-color: #00aced;
  color: white;
} */


/* ================BLOG PAGE======================== */

#Blogbg {
  background-image: url("../images/blogTopImg.jpg");
  background-size: cover;
  background-position: center center;
}

.cardBtnlink {
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  color: inherit !important;
  cursor: pointer;

}

.cardBtnlink:hover {
  color: #52be71 !important;
  transition: 0.4s;

}

.cardDate {
  font-size: 12px;
  font-weight: 700;
  line-height: 2;
  letter-spacing: .1em;
  color: #52be71;
}

.card {
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  transition: .22s ease-in;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;

}

.card:hover {
  transform: translate3d(0, -12px, 0);
  transition: 0.4s;

}

.blog_wrapper {
  padding: 70px 0;
  margin-bottom: 20px;
}


@media (min-width : 768px) {

  .categorySlider ul {
    display: flex !important;
    justify-content: space-around;
    /* border: 2px solid red; */

  }

  .categorySlider .InfiniteCarouselSlide {

    width: 227.333px !important;
  }
}


/* @media (min-width: 1100px){
.navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
    border: 2px solid red;
  }
} */



#Privacybg {
  background-image: url("../images/privacybg.jpg");
  background-size: cover;
  background-position: center center;
}

.termsOfServiceslist {
  list-style-type: disc;
  display: revert;
  padding-top: 1rem;
}